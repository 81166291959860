import { Component } from 'react';
import DataTable from 'react-data-table-component';


import { createNewHeaders, updateHeaders, executeGet, executePost } from '../../functions/Kubuku';
import { UPGRADE_PENGGUNA, CHECK_EMAIL,LIST_ADMIN, LIST_OUTLET } from '../../api';
// import Datepicker from '@themesberg/tailwind-datepicker/Datepicker';

import DatepickerCustom from '../../components/DatepickerCustom';
import ModalLoading from "../../components/ModalLoading";
import ModalPopup from "../../components/ModalPopup";

class UpgradePengguna extends Component {

  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      nama: '',
      phone: '',
      data: '',
      dataemail:'',
      tglLahir: '',
      email:'',
      beranda:'',
      alamat: '',
      showModal: false,
      typeModal: '',
      msg: '',
      pberanda: [{ id: "", nama: "Pilih Outlet" }],
    }
  }

  componentDidMount() {
     this.getListAdmin();
     this.listBeranda();
  }

  scriptLoaded() {
    console.log("script load");
    // window.A.sort();
  }

  
  checkEmail = async() => {
    try {
        let formData = new FormData();
        formData.append("email", this.state.email);
        
        const json = await executePost(CHECK_EMAIL, formData);
        console.log("update_profile = " + JSON.stringify(json));
        console.log(json.data[0].nama);
        if (json.code == 200) {
          this.setState({
                beranda: json.data[0].admin_beranda,
                nama: json.data[0].nama 
            });
          
        }
      } catch (error) {
        //console.log(error);
        this.setState({ isError: true, errorMessage: "Network Error!" });
      }
  }

  getListAdmin = async () => {
        //this.setState({ isLoading: true });
        const flagOpen = localStorage.getItem('flagOpen');
        console.log("flagOpen = " + flagOpen);
        if(flagOpen != "1") {
            createNewHeaders('', ''); 
        } else {
            updateHeaders('', ''); 
        }
        try {

            const json = await executePost(LIST_ADMIN);
            console.log("Laporan = " + JSON.stringify(json));
            if (json.code == 200) {
                this.setState({ dataemail: json.data });
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
    }

    listBeranda = async () => {
        try {
            let beranda;
            
            const json = await executeGet(LIST_OUTLET);
            console.log("list Outlet = " + JSON.stringify(json.data));
            if (json.code == 200) {
                beranda = json.data;
                let aberanda = [];
                aberanda.push({ id: "", nama: "Semua Outlet" });
                beranda.map((item, i) => {
                    aberanda.push({ id: item.kode, nama: item.judul });
                }, this);
                this.setState({ pberanda: aberanda });
            } else if (json.code == 500 || json.code == 505) {
                return "";
            }
        } catch (error) {
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
    }

  simpanProfile = async () => {
    
      try {
        let formData = new FormData();
        formData.append("email", this.state.email);
        formData.append("kode_beranda", this.state.beranda);
        console.log("BERANDA: " + this.state.beranda);
        const json = await executePost(UPGRADE_PENGGUNA, formData);
        if (json.code == 200) {
          this.setState({ msg: json.msg, typeModal: 'success', showModal:true });
          this.getListAdmin();
        }
      } catch (error) {
        //console.log(error);
        this.setState({ isError: true, errorMessage: "Network Error!" });
      }
    
    
  }

  

  render() {
    //this.listBeranda();
    const columns = [

        {
            name: 'Email',
            selector: row => row.email,
        },
        {
            name: 'Nama',
            selector: row => row.nama,
            maxWidth: '240px'
        },
        {
            name: 'OUTLET',
            selector: row => row.admin_beranda,
            maxWidth: '200px',
            right: false
        }
    ];
    return (
      <div>
        {
          this.state.showModal &&
          <ModalPopup
            messages={this.state.msg}
            press={() => {
              if (this.state.typeModal == "success") {
                // window.location.href = window.location.origin + "/login";
                this.setState({ 
                    showModal: false,
                    beranda: '',
                    nama: '',
                    email:''
                })
                
              } else {
                this.setState({ showModal: false })
              }

            }}
            type={this.state.typeModal}
          />
        }
        <div className="dark:bg-white border rounded-2xl p-[30px] mb-24">
          <div className="text-4xl font-semibold dark:text-black pb-6">Upgrade Pengguna</div>
          <small>Upgrade pengguna menjadi admin Outlet. Email pengguna harus sudah terdaftar di toko tersebut.</small>
          <form>
            <div className="mb-6">
              
              <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Email Akun</label>
              <div className='flex'>
              <input type="email" id="email" value={this.state.email} onChange={event => this.setState({ email: event.target.value })}  className="mt-1 block w-7/12 p-3 mr-2 bg-gray-50 border border-gray-300 text-gray-900 rounded-full text-sm shadow-sm focus:outline-none focus:border-greenWalang focus:ring-1 focus:ring-greenWalang" placeholder="Masukkan Email" required />
              <button onClick={()=>this.checkEmail()} type="button" className="w-4/12 bg-greenWalang font-medium rounded-full text-sm px-5 py-3 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                <div className="text-[#ffffff]">
                  Check
                </div>
              </button>
              </div>
            </div>
            <div className="mb-6">
              <label for="fullname" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Nama Lengkap</label>
              <input type="text" id="fullname" value={this.state.nama} readOnly className="mt-1 block w-full p-3 bg-gray-50 border border-gray-300 text-gray-900 rounded-full text-sm shadow-sm focus:outline-none focus:border-greenWalang focus:ring-1 focus:ring-greenWalang" placeholder="Masukkan Nama Lengkap" required />
            </div>

            <div className="mb-6">
                <label for="beranda" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Outlet</label>

                <select value={this.state.beranda} onChange={event => this.setState({ beranda: event.target.value })} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light pr-10" placeholder="Single" required>
                    {
                        this.state.pberanda.map((item, i) => {
                            return (
                                <option value={item.id}>{item.nama}</option>
                            )
                        }, this)
                    }
                </select>
            </div>
            <div className="flex justify-end">
              <button onClick={()=>this.simpanProfile()} type="button" className="w-4/12 bg-greenWalang font-medium rounded-full text-sm px-5 py-3 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                <div className="text-[#ffffff]">
                  Upgrade
                </div>
              </button>
            </div>

          </form>

        </div>
        <DataTable
            columns={columns}
            data={this.state.dataemail}
            pagination
            highlightOnHover={true}
            pointerOnHover={true}

        />
      </div>
    );

  }
}

export default UpgradePengguna;
