import { Component } from 'react';
import BookCover from '../assets/book_cover_full.svg';
import IconCart from '../assets/ic_cart.svg';

import { createNewHeaders, updateHeaders, clientSettings, executeGet, executePost } from '../functions/Kubuku';
import { KATEGORI } from '../api';

import SectionBook from '../components/SectionBook';
import CardCheckboxKategori from '../components/CardCheckboxKategori';
import CardKategori from '../components/CardKategori';

import Pagination from '../components/Pagination';

import {
    useParams,
    useNavigate
} from "react-router-dom";

let PageSize = 24;

function WithNavigate(props) {
    let navigate = useNavigate();
    return <CategorieDetail {...props} navigate={navigate} params={useParams()} />
}

class CategorieDetail extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            param: this.props.params,
            data: [],
            showMain: false,
            contentData: [],
            title: ""
        }
        console.log(JSON.stringify(this.props.params));
    }

    componentDidMount = () => {
        // const localStr = localStorage.getItem('dataKategori');
        // console.log("localStr data = " + localStr);
        // const localStrParse = JSON.parse(localStr);
        // this.setState({ contentData: localStrParse.data })
        // console.log("localStr dataKategori = " + JSON.stringify(this.state.contentData));
        this.getDataCategories();
    }

    // componentDidUpdate = () => {
    //     const localStr = localStorage.getItem('dataKategori');
    //     console.log("localStr data = " + localStr);
    //     const localStrParse = JSON.parse(localStr);
    //     this.setState({ contentData : localStrParse.data})
    //     console.log("localStr dataKategori = " + JSON.stringify(this.state.contentData));
    // }

    getDataCategories = async () => {
        const flagOpen = localStorage.getItem('flagOpen');
        if (flagOpen != "1") {
            createNewHeaders('', '');
        } else {
            updateHeaders('', '');
        }
        console.log("dataKategori = " + this.state.param.id);
        try {
            const json = await executeGet(KATEGORI + "/" + this.props.params.id);
            console.log("kategori = " + JSON.stringify(json));
            if (json.code == 200) {
                this.setState({
                    showMain: true,
                    contentData: json.data,
                    title: json.data[0].kategori,
                    flagData: true
                }, () => {
                    // console.log(this.state.dataTop2.Judul); 
                });

            } else if (json.code == 500 || json.code == 505) {
                window.location.href = window.location.origin + "/login";
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }

    }

    currentTableData = (contentData) => {
        const firstPageIndex = (this.state.currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return contentData.slice(firstPageIndex, lastPageIndex);
    };

    render() {
        return (
            <div>
                <div className="hidden lg:block">
                    <div className="pt-[78px] pb-2 px-8 xl:px-28 dark:bg-white">
                        <div>
                            {/* Home / Kategori */}
                            <nav className="flex" aria-label="Breadcrumb">
                                <ol className="inline-flex items-center space-x-1 lg:space-x-3">
                                    <li className="inline-flex items-center">
                                        <a href="/" className="inline-flex items-center text-sm font-medium text-greenWalang hover:text-greenWalang hover:opacity-30 dark:text-greenWalang dark:hover:text-greenWalang">
                                            Home
                                    </a>
                                    </li>
                                    <li aria-current="page">
                                        <div className="flex items-center">
                                            <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                            <span className="ml-1 text-sm font-medium text-gray-400 lg:ml-2 dark:text-gray-500">Kategori</span>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="py-3 px-8 xl:px-28 bg-greenWalang bg-opacity-30 text-2xl text-greenWalang">
                        {this.state.title}
                    </div>
                    
                    <div className="pt-8 px-28 dark:bg-white">
                        <div className="flex pb-[50px] space-x-7">
                            
                            <div className="w-full">
                                {
                                    this.state.contentData.map(item => {
                                        return (
                                            <div className="pt-4 dark:bg-white">
                                                {/* Buku Populer */}
                                                {/* <div className="flex">
                                                    <div className="w-full border-b-2 pb-3 font-semibold text-4xl dark:text-black">
                                                        {item.kategori}
                                                    </div>
                                                </div> */}

                                                {
                                                    item.data.map(item1 => {
                                                        return (
                                                            <div>
                                                                <div>
                                                                    <div className="w-full border-b py-1 font-semibold text-lg dark:text-black">
                                                                        {item1.sub_kategori}
                                                                    </div>
                                                                </div>

                                                                <div className="grid grid-cols-3 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 mb-1 py-[30px]">
                                                                    {this.currentTableData(item1.data).map(item => {
                                                                        return (
                                                                            <CardKategori
                                                                                beranda={localStorage.getItem('beranda')}
                                                                                section = {""}
                                                                                kategori={this.props.params.id}
                                                                                data={item}
                                                                            />
                                                                        )
                                                                    })}
                                                                </div>
                                                                <div className="flex justify-center">
                                                                    <Pagination
                                                                        className="pagination-bar"
                                                                        currentPage={this.state.currentPage}
                                                                        totalCount={item1.data.length}
                                                                        pageSize={PageSize}
                                                                        onPageChange={
                                                                            page => this.setState({ currentPage: page })
                                                                        }
                                                                    />
                                                                </div>
                                                                
                                                            </div>
                                                        )

                                                    })
                                                }

                                            </div>

                                        )
                                    })
                                }
                            </div>
                        </div>


                    </div>
                </div>

                <div className="block lg:hidden">
                    
                    <div className="pt-[52px] w-full">
                        {/**
                    <div className="pt-4 pb-4 px-4 bg-greenWalang bg-opacity-30 text-2xl text-greenWalang">
                        {this.state.title}
                    </div>
                     */}
                    <div className="pt-2 dark:bg-white">
                        <div className="flex pb-[50px] space-x-7">
                            <div className="w-full">
                                {
                                    this.state.contentData.map(item => {
                                        return (
                                            <div className="pt-4 dark:bg-white">
                                                
                                                {
                                                    item.data.map(item1 => {
                                                        return (
                                                            <div>
                                                                <div>
                                                                    <div className="w-full ml-4 border-b py-1 font-semibold text-lg dark:text-black">
                                                                        {item1.sub_kategori}
                                                                    </div>
                                                                </div>

                                                                <div className="pl-2 grid grid-cols-3 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 mb-1 py-[30px]">
                                                                    {this.currentTableData(item1.data).map(item => {
                                                                        return (
                                                                            <CardKategori
                                                                                data={item}
                                                                            />
                                                                        )
                                                                    })}
                                                                </div>
                                                                <div className="flex justify-center">
                                                                    <Pagination
                                                                        className="pagination-bar"
                                                                        currentPage={this.state.currentPage}
                                                                        totalCount={item1.data.length}
                                                                        pageSize={PageSize}
                                                                        onPageChange={
                                                                            page => this.setState({ currentPage: page })
                                                                        }
                                                                    />
                                                                </div>
                                                                
                                                            </div>
                                                        )

                                                    })
                                                }

                                            </div>

                                        )
                                    })
                                }
                            </div>
                        </div>

                    </div>
                    </div>
                </div>

            </div>
        );

    }
}

export default WithNavigate;
