import React, { Component } from 'react';
import {
    Platform, View, StyleSheet, TextInput,
    Text, Image, StatusBar, BackHandler, Button
} from 'react-native';
import { useNavigate } from 'react-router-dom';

import ModalSettings from '../components/ModalSettings';
import ModalLoading from "../components/ModalLoading";
import ModalPopup from "../components/ModalPopup";
import ReactStars from "react-rating-stars-component";

import { clientSettings, executeGet, executePost } from '../functions/Kubuku';
import { SET_RATING } from '../api';

class CardDetailTransaksi extends Component {
    constructor(props) {
        super(props)
        this.state = {
            shareKode: false,
            isModal: false,
            data: this.props.data,
            order_id: this.props.order_id,
            status: this.props.status,
            rating_value: Number(this.props.data.rating)
        }
    }

    componentDidMount() {

    }

    ratingChanged = async (newRating) => {
        console.log(newRating);
        console.log(this.state.data.kode_konten);
        this.setState({ isLoading: true });
        try {
            let formData = new FormData();
            formData.append("kode_konten", this.state.data.kode_konten);
            formData.append("rate", newRating);

            const json = await executePost(SET_RATING, formData);
            console.log("set_rating = " + JSON.stringify(json));
            if (json.code == 200) {
                // navigate to cart
                // this.props.navigate('/wishlist');
                this.setState({ isLoading: false, isModal: true, msg: "Terima Kasih.. Anda telah memberikan rating pada buku ini" });
            } else if (json.code == 500 || json.code == 505) {
                window.location.href = window.location.origin + "/login";
            } else {
                this.setState({ isLoading: false, isError: true, msg: json.msg });

            }
        } catch (error) {
            //console.log(error);
            this.setState({ isLoading: false, isError: true, msg: "Network Error!" });
        }
    };

    render() {
        console.log("DDd");
        console.log(this.state.status);
        return (
            <div>
                {
                    this.state.shareKode &&
                    <ModalSettings
                        tipe="share_kode"
                        close={() => { this.setState({ shareKode: false }) }}
                        kode_konten={this.props.data.kode_konten}
                        order_id={this.state.order_id}
                    />
                }
                {
                    this.state.isModal &&
                    <ModalPopup
                        type="success"
                        messages={this.state.msg}
                        press={() => { this.setState({ isModal: false }) }}
                    />
                }
                {
                    this.state.isError &&
                    <ModalPopup
                        type="error"
                        messages={this.state.msg}
                        press={() => { this.setState({ isError: false }) }}
                    />
                }
                <div className="">
                    <div className="hidden md:block">
                        <li className="flex py-10 border-b">
                            <div className="w-full ">
                                <div className="px-1 flex">
                                    <div className="w-[20%]">
                                        <div className="w-[166px] h-[233px] max-w-xs overflow-hidden rounded-[6px] shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                                            <img className='w-full rounded-[6px]' src={this.state.data.cover} alt="" />
                                        </div>
                                    </div>
                                    <div className="w-[80%]">
                                        <div className="ml-6">
                                            <div className="text-base font-semibold mt-4 dark:text-black line-clamp-2">{this.state.data.judul}</div>
                                            <div className="text-xs font-semibold mt-1 text-[#999999] line-clamp-1">{this.state.data.penulis}</div>
                                            <div className="flex items-center">
                                                <div className="text-lg font-semibold mt-1 text-greenWalang">Rp {this.state.data.formatted_total}</div>
                                            </div>
                                            { this.state.status=='sukses' && 
                                            <>
                                            <div className="text-xs font-semibold mt-3 text-[#999999] line-clamp-1">Kode Klaim :</div>
                                            <div className="flex">
                                                <div className="text-base font-semibold dark:text-black line-clamp-1">{this.state.data.kode_klaim}</div>
                                            </div>
                                            </>
                                            }
                                            {
                                                // aktif
                                                this.state.data.status_kode_klaim == "aktif"
                                                    ?
                                                    <div>
                                                        {
                                                            this.state.data.pengiriman == "1"
                                                                ?

                                                                <div>
                                                                    <div className="bg-greenWalang bg-opacity-30 font-medium rounded-xl text-sm text-greenWalang border-greenWalang border w-5/12 px-4 py-2 text-center items-center dark:focus:ring-[#3b5998]/55 justify-center mt-2">
                                                                        <div className="text-left text-md">Diberikan ke :</div>
                                                                        <div className="text-xs text-left">{this.state.data.email_penerima}</div>
                                                                    </div>
                                                                    <ReactStars
                                                                        count={5}
                                                                        edit={false}
                                                                        size={24}
                                                                        value={parseInt(this.state.rating_value)}
                                                                        activeColor="#ffd700"
                                                                    />
                                                                </div>
                                                                :
                                                                <div className="flex items-center mt-2 space-x-3">
                                                                    <div>Belum di klaim</div>
                                                                    <button onClick={() => this.setState({ shareKode: true })} type="button" className="bg-greenWalang font-medium rounded-full text-sm px-1 py-1 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path fillRule="evenodd" clipRule="evenodd" d="M18.25 9.5C18.25 7.70507 19.7051 6.25 21.5 6.25C23.2949 6.25 24.75 7.70507 24.75 9.5C24.75 11.2949 23.2949 12.75 21.5 12.75C20.5404 12.75 19.6779 12.3341 19.083 11.6727L16.3657 13.1549L13.3251 14.8923C13.5955 15.3664 13.75 15.9152 13.75 16.5C13.75 16.7963 13.7103 17.0834 13.636 17.3562L19.083 20.3273C19.6779 19.6659 20.5404 19.25 21.5 19.25C23.2949 19.25 24.75 20.7051 24.75 22.5C24.75 24.2949 23.2949 25.75 21.5 25.75C19.7051 25.75 18.25 24.2949 18.25 22.5C18.25 22.2036 18.2897 21.9166 18.364 21.6438L12.917 18.6727C12.3221 19.3341 11.4596 19.75 10.5 19.75C8.70507 19.75 7.25 18.2949 7.25 16.5C7.25 14.7051 8.70507 13.25 10.5 13.25C11.1507 13.25 11.7567 13.4412 12.2649 13.7705L15.6343 11.8451L18.364 10.3562C18.2897 10.0834 18.25 9.79635 18.25 9.5ZM21.5 7.75C20.5335 7.75 19.75 8.5335 19.75 9.5C19.75 10.4665 20.5335 11.25 21.5 11.25C22.4665 11.25 23.25 10.4665 23.25 9.5C23.25 8.5335 22.4665 7.75 21.5 7.75ZM10.5 14.75C9.5335 14.75 8.75 15.5335 8.75 16.5C8.75 17.4665 9.5335 18.25 10.5 18.25C11.4665 18.25 12.25 17.4665 12.25 16.5C12.25 15.5335 11.4665 14.75 10.5 14.75ZM19.75 22.5C19.75 21.5335 20.5335 20.75 21.5 20.75C22.4665 20.75 23.25 21.5335 23.25 22.5C23.25 23.4665 22.4665 24.25 21.5 24.25C20.5335 24.25 19.75 23.4665 19.75 22.5Z" fill="#FFFFFF" />
                                                                        </svg>
                                                                        <div className="text-[#ffffff] pr-1">
                                                                            Bagikan
                                                                </div>
                                                                    </button>
                                                                </div>
                                                        }
                                                    </div>

                                                    :
                                                    <div>
                                                        { this.state.status=='sukses' &&
                                                        <div>
                                                            <div className="bg-greenWalang bg-opacity-30 font-medium rounded-xl text-sm text-greenWalang border-greenWalang border w-3/12 px-4 py-2 text-center items-center dark:focus:ring-[#3b5998]/55 justify-center mt-2">
                                                                <div className="text-right text-md">Sudah di klaim</div>
                                                                <div className="text-xs text-right">{this.state.data.tanggal_klaim}</div>
                                                            </div>
                                                            {
                                                                this.state.rating_value != ""
                                                                    ?
                                                                    <div>
                                                                        <ReactStars
                                                                            count={5}
                                                                            onChange={this.ratingChanged}
                                                                            size={24}
                                                                            value={parseInt(this.state.rating_value)}
                                                                            activeColor="#ffd700"
                                                                            edit={false}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <ReactStars
                                                                            count={5}
                                                                            onChange={this.ratingChanged}
                                                                            size={24}
                                                                            value={parseInt(this.state.rating_value)}
                                                                            activeColor="#ffd700"
                                                                        />
                                                                    </div>
                                                            }

                                                        </div>
                                                        }
                                                    </div>
                                            }
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </li>
                    </div>

                    {/* mobile */}
                    <li className="py-3 border-b block md:hidden">
                        <div className="w-full">
                            <div className="px-1 flex">
                                <div className="w-[30%]">
                                    <div className="w-[99px] h-[135px] overflow-hidden rounded-[6px] shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                                        <img className='w-full' src={this.state.data.cover} alt="" />
                                    </div>
                                </div>
                                <div className="w-[70%]">
                                    <div className="ml-6">
                                        <div className="text-[14px] font-semibold mt-1 dark:text-black line-clamp-2">{this.state.data.judul}</div>
                                        <div className="text-[10px] font-semibold mt-1 text-[#999999] line-clamp-1">{this.state.data.penulis}</div>
                                        <div className="flex text-center items-center">
                                            <div className="text-[11px] font-semibold mt-1 text-greenWalang">Rp {this.state.data.formatted_total}</div>
                                        </div>
                                        <div className="text-xs font-semibold mt-3 text-[#999999] line-clamp-1">Kode Klaim :</div>
                                        <div className="flex">
                                            <div className="text-base font-semibold dark:text-black line-clamp-1">{this.state.data.kode_klaim}</div>
                                            
                                        </div>
                                        {
                                                // aktif
                                                this.state.data.status_kode_klaim == "aktif"
                                                    ?
                                                    <div>
                                                        {
                                                            this.state.data.pengiriman == "1"
                                                                ?

                                                                <div>
                                                                    <div className="bg-greenWalang bg-opacity-30 font-medium rounded-xl text-sm text-greenWalang border-greenWalang border w-5/12 px-4 py-2 text-center items-center dark:focus:ring-[#3b5998]/55 justify-center mt-2">
                                                                        <div className="text-left text-md">Diberikan ke :</div>
                                                                        <div className="text-xs text-left">{this.state.data.email_penerima}</div>
                                                                    </div>
                                                                    <ReactStars
                                                                        count={5}
                                                                        edit={false}
                                                                        size={24}
                                                                        value={parseInt(this.state.rating_value)}
                                                                        activeColor="#ffd700"
                                                                    />
                                                                </div>
                                                                :
                                                                <div className="flex items-center mt-2 space-x-3">
                                                                    <div>Belum di klaim</div>
                                                                    <button onClick={() => this.setState({ shareKode: true })} type="button" className="bg-greenWalang font-medium rounded-full text-sm px-1 py-1 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path fillRule="evenodd" clipRule="evenodd" d="M18.25 9.5C18.25 7.70507 19.7051 6.25 21.5 6.25C23.2949 6.25 24.75 7.70507 24.75 9.5C24.75 11.2949 23.2949 12.75 21.5 12.75C20.5404 12.75 19.6779 12.3341 19.083 11.6727L16.3657 13.1549L13.3251 14.8923C13.5955 15.3664 13.75 15.9152 13.75 16.5C13.75 16.7963 13.7103 17.0834 13.636 17.3562L19.083 20.3273C19.6779 19.6659 20.5404 19.25 21.5 19.25C23.2949 19.25 24.75 20.7051 24.75 22.5C24.75 24.2949 23.2949 25.75 21.5 25.75C19.7051 25.75 18.25 24.2949 18.25 22.5C18.25 22.2036 18.2897 21.9166 18.364 21.6438L12.917 18.6727C12.3221 19.3341 11.4596 19.75 10.5 19.75C8.70507 19.75 7.25 18.2949 7.25 16.5C7.25 14.7051 8.70507 13.25 10.5 13.25C11.1507 13.25 11.7567 13.4412 12.2649 13.7705L15.6343 11.8451L18.364 10.3562C18.2897 10.0834 18.25 9.79635 18.25 9.5ZM21.5 7.75C20.5335 7.75 19.75 8.5335 19.75 9.5C19.75 10.4665 20.5335 11.25 21.5 11.25C22.4665 11.25 23.25 10.4665 23.25 9.5C23.25 8.5335 22.4665 7.75 21.5 7.75ZM10.5 14.75C9.5335 14.75 8.75 15.5335 8.75 16.5C8.75 17.4665 9.5335 18.25 10.5 18.25C11.4665 18.25 12.25 17.4665 12.25 16.5C12.25 15.5335 11.4665 14.75 10.5 14.75ZM19.75 22.5C19.75 21.5335 20.5335 20.75 21.5 20.75C22.4665 20.75 23.25 21.5335 23.25 22.5C23.25 23.4665 22.4665 24.25 21.5 24.25C20.5335 24.25 19.75 23.4665 19.75 22.5Z" fill="#FFFFFF" />
                                                                        </svg>
                                                                        <div className="text-[#ffffff] pr-1">
                                                                            Bagikan
                                                                </div>
                                                                    </button>
                                                                </div>
                                                        }
                                                    </div>

                                                    :
                                                    <div>
                                                        { this.state.status=='sukses' &&
                                                        <div>
                                                            <div className="bg-greenWalang bg-opacity-30 font-medium rounded-xl text-sm text-greenWalang border-greenWalang border w-4/12 px-4 py-2 text-center items-center dark:focus:ring-[#3b5998]/55 justify-center mt-2">
                                                                <div className="text-right text-md">Sudah di klaim</div>
                                                                <div className="text-xs text-right">{this.state.data.tanggal_klaim}</div>
                                                            </div>
                                                            {
                                                                this.state.rating_value != ""
                                                                    ?
                                                                    <div>
                                                                        <ReactStars
                                                                            count={5}
                                                                            onChange={this.ratingChanged}
                                                                            size={24}
                                                                            value={parseInt(this.state.rating_value)}
                                                                            activeColor="#ffd700"
                                                                            edit={false}
                                                                        />
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <ReactStars
                                                                            count={5}
                                                                            onChange={this.ratingChanged}
                                                                            size={24}
                                                                            value={parseInt(this.state.rating_value)}
                                                                            activeColor="#ffd700"
                                                                        />
                                                                    </div>
                                                            }

                                                        </div>
                                                        }
                                                    </div>
                                            }
                                    </div>

                                </div>

                            </div>
                        </div>

                    </li>

                </div>
            </div>
        )
    }
}

// export default CardKategori;
export default CardDetailTransaksi;