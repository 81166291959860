import { Component } from 'react';
import ModalPopup from "../components/ModalPopup";

import { clientSettings, executeGet, executePost } from '../functions/Kubuku';
import { GET_WISHLIST, ADD_CART } from '../api';

import {
    useNavigate
} from "react-router-dom";

import Pagination from '../components/Pagination';
let PageSize = 10;
let page = 1;

class Help extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            data: '',
            isModal: false,
            msg: '',
            showMain: true
        }

    }

    render() {
        return (
            <div>
                {
                    this.state.isModal &&
                    <ModalPopup
                        type="success"
                        messages={this.state.msg}
                        press={() => { this.setState({ isModal: false }) }}
                    />
                }
                { this.state.showMain &&
                    <div>
                        <div className="hidden lg:block">
                            <div className="pt-24 px-28 dark:bg-white">
                                <div className="py-7">
                                    {/* Home / Wishlist */}
                                    <nav className="flex" aria-label="Breadcrumb">
                                        <ol className="inline-flex items-center space-x-1 lg:space-x-3">
                                            <li className="inline-flex items-center">
                                                <a href="/" className="inline-flex items-center text-sm font-medium text-greenWalang hover:text-greenWalang hover:opacity-30 dark:text-greenWalang dark:hover:text-greenWalang">
                                                    Home
                                            </a>
                                            </li>
                                            <li aria-current="page">
                                                <div className="flex items-center">
                                                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                                    <span className="ml-1 text-sm font-medium text-gray-400 lg:ml-2 dark:text-gray-500">Bantuan</span>
                                                </div>
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="py-4 px-36 bg-greenWalang bg-opacity-30 text-2xl text-greenWalang">
                                Bantuan
                            </div>
                            <div className="py-10 px-28 dark:bg-white">
                                <div className="relative overflow-x-auto shadow-md sm:rounded-lg p-10">
                                    <div className="font-semibold text-xl mb-5">
                                        Bantuan
                                    </div>
                                    <form>
                                        <div className="mb-6">
                                            <label for="fullname" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Nama Lengkap</label>
                                            <input type="text" id="fullname" value={this.state.nama} onChange={event => this.setState({ nama: event.target.value })} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Masukkan Nama Lengkap" required />
                                        </div>
                                        <div className="mb-6">
                                            <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Email Akun</label>
                                            <input type="email" id="email" value={this.state.data.email} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Masukkan Email" required />
                                        </div>
                                        <div className="mb-6">
                                            <label for="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">No. Handphone</label>
                                            <input type="text" id="phone" value={this.state.phone} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Masukkan Phone Number" required onChange={event => this.setState({ phone: event.target.value })} />
                                        </div>
                                        <div className="mb-6">
                                            <label for="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Pesan</label>
                                            <textarea id="message" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Masukkan Pesan"></textarea>
                                        </div>
                                        
                                        <div className="flex justify-end">
                                            <button onClick={this.simpanProfile} type="button" className="w-4/12 bg-greenWalang font-medium rounded-full text-sm px-5 py-3 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                                <div className="text-[#ffffff]">
                                                    Simpan
                                                </div>
                                            </button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="block lg:hidden">
                            <div className="pt-32 pb-10 px-4 dark:bg-white">
                                <div className="relative overflow-x-auto shadow-md sm:rounded-lg p-10">
                                    <div className="font-semibold text-xl mb-5">
                                        Tentang Kami
                                    </div>
                                    <div>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec hendrerit sed felis vitae vehicula. Pellentesque ut sodales enim, in mollis libero. Aenean nulla risus, scelerisque vel augue sollicitudin, gravida dapibus tortor. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur interdum consequat eros sit amet ultricies. Aenean vestibulum, augue et vulputate finibus, sapien tellus volutpat tellus, sed fermentum tortor dui ac turpis. Praesent maximus finibus lorem, id tristique mi ultrices a.
                                        <br /><br />
                                        Duis scelerisque consectetur tortor, nec faucibus leo fringilla sit amet. Curabitur felis velit, ultrices ac aliquam a, dictum a risus. Nulla malesuada odio eget egestas tincidunt. Aenean aliquam dui tristique, semper lectus ac, rhoncus elit. Donec ac scelerisque nunc. Nam id congue enim. In hac habitasse platea dictumst. In efficitur vitae risus sed placerat. Praesent vel dui imperdiet, ultrices massa ut, facilisis magna. Aliquam facilisis vehicula varius. Pellentesque vitae auctor nulla. Sed sagittis metus sit amet tristique porta. Mauris aliquam mattis vulputate.
                                        <br /><br />
                                        Nulla luctus efficitur est. Etiam iaculis augue non libero sollicitudin iaculis. Morbi facilisis iaculis pharetra. Etiam ut dui tincidunt, sagittis ex quis, dignissim risus. Aliquam eget porttitor odio, a sodales leo. Vivamus vitae mauris a urna vestibulum ullamcorper. Nunc finibus sapien tortor, in ullamcorper nulla sagittis finibus. Maecenas feugiat ipsum et accumsan pretium. Vestibulum sit amet egestas metus. Nulla sodales ligula neque, eu auctor nunc consectetur nec.
                                        <br /><br />
                                        Nulla luctus efficitur est. Etiam iaculis augue non libero sollicitudin iaculis. Morbi facilisis iaculis pharetra. Etiam ut dui tincidunt, sagittis ex quis, dignissim risus. Aliquam eget porttitor odio, a sodales leo. Vivamus vitae mauris a urna vestibulum ullamcorper. Nunc finibus sapien tortor, in ullamcorper nulla sagittis finibus. Maecenas feugiat ipsum et accumsan pretium. Vestibulum sit amet egestas metus. Nulla sodales ligula neque, eu auctor nunc consectetur nec.
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                }

            </div>
        );

    }
}

export default Help;
