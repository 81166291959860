import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'flowbite';
import Loader from './components/Loader';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import reducers from '../src/redux/reducers'
import { clientSettings, createNewHeaders, executeGet, updateHeaders } from './functions/Kubuku';
import { KONFIGURASI } from './api';
const store = createStore(reducers);
let domainName = window.location.hostname;
    // domainName = "tokobukusagungseto.com";
    // domainName = "tokobukupintar.com";
    //domainName = "tokobukuinfolib.com";
    // domainName = "artibumiintarandigital.com";
window.state = {
  isLoading: true
}
const flagOpen = localStorage.getItem('flagOpen');

if (flagOpen != "1") {
  createNewHeaders('', '');
} else {
  updateHeaders('', '');
}

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

let postData = {
  method: 'get',
  headers: window.customHeaders
}

fetch(KONFIGURASI + "/" + domainName, postData)
  .then(function (response) {
    return response.json();
  }).then(function (data) {
    let json = data;
    
    if(json.code == 200) {
      let warna = json.data.color_primary;
      warna = hexToRgb(warna);
      
      //localStorage.setItem('konfigurasi', data);
      localStorage.setItem('namaToko', json.data.nama);
      localStorage.setItem('domain', json.data.domain);
      localStorage.setItem('logo', json.data.logo);
      localStorage.setItem('logo_putih', json.data.logo_putih);
      localStorage.setItem('kodeApp', json.data.kode);
      
      localStorage.setItem('alamat', json.data.alamat);
      localStorage.setItem('email', json.data.email);
      localStorage.setItem('footer', json.data.keterangan_footer);
      localStorage.setItem('poster', json.data.poster_favorite);
      localStorage.setItem('nomorTelepon', json.data.no_ponsel);
      localStorage.setItem('promoGabungan', json.data.promo_gabungan);
      localStorage.setItem('colorPrimary', warna.r +' '+ warna.g+' '+ warna.b);
      localStorage.setItem('payLogo', json.data.pay_logo);
      localStorage.setItem('icon', json.data.icon);
      createNewHeaders('', '');
      //clientSettings.clientId = json.data.kode;
      document.title = json.data.nama
      window.state.isLoading = false;
       
      const root = ReactDOM.createRoot(document.getElementById('root'));
      root.render(
        <React.StrictMode>
          <Provider store={store}>
            {window.state.isLoading ? <Loader /> : 
              <App />
            }
          </Provider>
        </React.StrictMode>
      );
    }
  });


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
