import { Component } from 'react';
import ListTransaction from '../components/ListTransaction';

import { createNewHeaders, updateHeaders, executePost } from '../functions/Kubuku';
import { DAFTAR_TRANSAKSI } from '../api';

class Notification extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            flagData: false,
            data: '',
        }
    }

    componentDidMount() {
        this.getNotif();
    }

    getNotif = async () => {
        try {
            const flagOpen = localStorage.getItem('flagOpen');
            if (flagOpen != "1") {
                createNewHeaders('', '');
            } else {
                updateHeaders('', '');
            }

            let formData = new FormData(); 
            formData.append("page", "1");
            formData.append("row", "10");
      
            const json = await executePost(DAFTAR_TRANSAKSI, formData);
            console.log("notif = " + JSON.stringify(json));
            if (json.code == 200) {
                // this.getProfile();
                // open modal
                // this.props.navigate('/cart');
                this.setState({flagData: true, data: json.data});
            } else if (json.code == 500 || json.code == 505) {
                // window.location.href = window.location.origin +"/login";
            }
          } catch (error) {
              //console.log(error);
              this.setState({ isError: true, errorMessage: "Network Error!" });
          }
      }

    render() {
        return (
            <div>
                <div className="dark:bg-white border rounded-2xl p-[30px]">
                <div className="text-4xl font-semibold dark:text-black pb-6">Notification</div>
                {/* {this.state.flagData && this.state.data.map((row) => {
                    return (
                        <ListTransaction
                            key={row.order_id}
                            data={row}
                        />
                    );
                })} */}
                </div>
            </div>
        );

    }
}

export default Notification;
