import React from 'react';
import { Helmet } from 'react-helmet-async';

// You can have more props. In my case, these are enough.
function MetaTags ({ title = '', description = '', image = '', name = '', keywords = '' }) {
  const icon = localStorage.getItem('icon')
  return (
        <Helmet>
            { /* Standard metadata tags */ }
            <title>{title}</title>
            <link rel='canonical' href={ window.location.href } />
            <link rel="icon" type="image/png" href={icon} sizes="16x16" />
            <meta name='description' content={description} />
            <meta name='keywords' content={keywords} />
            { /* Open Graph tags (OG) */ }
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:keywords" content={keywords} />
            {/* OG image tags */}
            <meta property="og:image" content={image} />
            <meta property="og:image:secure_url" content={image} />
            <meta property="og:image:type" content="image/jpeg" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="628" />
            <meta property="og:image:alt" content={`Image of ${title} site`} />
            <meta property="og:locale" content="id_ID" />
            <meta property="og:site_name" content={name} />
            { /* Twitter tags */ }
            <meta name="twitter:creator" content={name} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:keywords" content={keywords} />
        </Helmet>
  );
}

export default MetaTags;