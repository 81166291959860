import { Component } from 'react';
import ModalPopup from "../components/ModalPopup";

import { createNewHeaders, updateHeaders, executeGet } from '../functions/Kubuku';
import { LIST_PUBLICVOUCHER } from '../api';
import ModalLoading from "../components/ModalLoading";

import {
    useNavigate
} from "react-router-dom";

import Pagination from '../components/Pagination';
let PageSize = 12;
let page = 1;

class PublicVoucher extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            data: '',
            isModal: false,
            msg: ''
        }

        //this.getWishlist();
        this.getVoucher();
    }



    getVoucher = async () => {
        try {
            const flagOpen = localStorage.getItem('flagOpen');
            if (flagOpen != "1") {
                createNewHeaders('', '');
            } else {
                updateHeaders('', '');
            }
            const json = await executeGet(LIST_PUBLICVOUCHER);
            console.log("Vocher = " + JSON.stringify(json));
            if (json.code == 200) {
                //this.setState({ data: json.promosi_aktif, showMain: true });
                this.setState({ showMain: true, data: json.promosi_aktif });
            } else if (json.code == 404) {
                //masukkan tgl lahir
                // this.setState({ flagData: false, msg: json.msg });
            } else if (json.code == 500 || json.code == 505) {
                // window.location.href = window.location.origin + "/login";
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
    }

    currentTableData = () => {
        const firstPageIndex = (this.state.currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return this.state.data.slice(firstPageIndex, lastPageIndex);
    };

    formatDate(value) {
        let date = new Date(value);
        const day = date.toLocaleString('default', { day: '2-digit' });
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.toLocaleString('default', { year: 'numeric' });
        return day + ' ' + month + ' ' + year;
    }

    currencyFormat(num) {
        return 'Rp. ' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    render() {
        return (
            <div>
                {
                    this.state.isLoading &&
                    <ModalLoading />
                }
                {
                    this.state.isModal &&
                    <ModalPopup
                        type="success"
                        messages={this.state.msg}
                        press={() => { this.setState({ isModal: false }) }}
                    />
                }
                { this.state.showMain &&
                    <div>
                        <div className="hidden lg:block">
                            <div className="pt-[78px] pb-2 px-8 xl:px-28  dark:bg-white">
                                <div>
                                    {/* Home / Wishlist */}
                                    <nav className="flex" aria-label="Breadcrumb">
                                        <ol className="inline-flex items-center space-x-1 lg:space-x-3">
                                            <li className="inline-flex items-center">
                                                <a href="/" className="inline-flex items-center text-sm font-medium text-greenWalang hover:text-greenWalang hover:opacity-30 dark:text-greenWalang dark:hover:text-greenWalang">
                                                    Home
                                            </a>
                                            </li>
                                            <li aria-current="page">
                                                <div className="flex items-center">
                                                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                                    <span className="ml-1 text-sm font-medium text-gray-400 lg:ml-2 dark:text-gray-500">Voucher Promo</span>
                                                </div>
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="py-3 px-8 xl:px-28 bg-greenWalang bg-opacity-30 text-2xl text-greenWalang">
                                Voucher Promo
                            </div>
                            <div className="py-10 px-28 dark:bg-white">
                                <div className="mt-3">
                                    <div className='grid grid-cols-2 gap-x-1 gap-y-1  lg:gap-x-2 lg:gap-y-2 lg:grid-cols-4 lg:mx-2 px-4 lg:px-0 mt-1 space-x-2'>
                                        {this.currentTableData().map(item => {
                                            return (
                                                <div className="p-0.5" id="profile" role="tabpanel" aria-labelledby="aktif-tab" onClick={() => { this.props.navigate('/profile/setting-voucher-detail/view/' + item.id) }}>
                                                    <div className="w-full p-2 bg-white shadow-md rounded-lg cursor-pointer hover:shadow-lg">
                                                        <div className="flex mb-3 p-3 border-2 border-[#2BC239] border-dashed rounded-lg bg-[#2BC239] bg-opacity-20">
                                                            <div className="w-full ml-2 p-2 border-l-2 border-[#2BC239] border-dashed text-[#2BC239] overflow-hidden">
                                                                <div className="flex pb-1">
                                                                    <h3 className="font-semibold truncate pr-1">{item.kode_voucher}</h3>
                                                                    <div onClick={() => { 
                                                                        navigator.clipboard.writeText(item.kode_voucher)
                                                                        this.setState({isModal: true, msg: 'Berhasil menyalin kode voucher'} )
                                                                    }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                                                                        </svg>
                                                                    </div>
                                                                    
                                                                </div>
                                                                
                                                                <div className="text-sm truncate">{item.judul}</div>
                                                                <div className="text-sm truncate">{item.deskripsi}</div>
                                                            </div>
                                                        </div>
                                                        {/* <h3 className="mb-1 font-semibold">Back to School</h3> */}
                                                        <div className="mb-1 text-gray-400 text-[12px]">Diskon {Number(item.diskon_persen)}% s/d {this.currencyFormat(item.diskon_maksimal)}</div>
                                                        <div className="text-[14px]">{this.formatDate(item.tgl_mulai)} - {this.formatDate(item.tgl_akhir)}</div>
                                                    </div>
                                                </div>

                                            )
                                        })}

                                    </div>
                                    <div className="flex justify-center pt-8">
                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={this.state.currentPage}
                                            totalCount={this.state.data.length}
                                            pageSize={PageSize}
                                            onPageChange={
                                                page => this.setState({ currentPage: page })
                                            }
                                        />
                                    </div>


                                </div>
                            </div>
                        </div>

                        <div className="block lg:hidden">
                            
                            <div className="pt-24 px-6 dark:bg-white mb-20">
                                <div className="py-4 text-xl text-greenWalang">
                                    Voucher Promo
                                </div>
                                <div>
                                    <div className='grid grid-cols-1 gap-x-1 gap-y-1 md:gap-x-2 md:gap-y-2 md:grid-cols-4 md:mx-2 md:px-4 md:px-0 mt-1 md:space-x-2 space-y-2'>
                                        {this.currentTableData().map(item => {
                                            return (
                                                <div className="p-0.5" id="profile" role="tabpanel" aria-labelledby="aktif-tab" onClick={() => { this.props.navigate('/profile/setting-voucher-detail/view/' + item.id) }}>
                                                    <div className="w-full p-2 bg-white shadow-md rounded-lg cursor-pointer hover:shadow-lg">
                                                        <div className="flex mb-3 p-3 border-2 border-[#2BC239] border-dashed rounded-lg bg-[#2BC239] bg-opacity-20">
                                                            <div className="w-full ml-2 p-2 border-l-2 border-[#2BC239] border-dashed text-[#2BC239] overflow-hidden">
                                                                <div className="flex pb-1">
                                                                    <h3 className="font-semibold truncate pr-1">{item.kode_voucher}</h3>
                                                                    <div onClick={() => { 
                                                                        navigator.clipboard.writeText(item.kode_voucher)
                                                                        this.setState({isModal: true, msg: 'Berhasil menyalin kode voucher'} )
                                                                    }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                                                                        </svg>
                                                                    </div>
                                                                    
                                                                </div>
                                                                
                                                                <div className="text-sm truncate">{item.judul}</div>
                                                                <div className="text-sm truncate">{item.deskripsi}</div>
                                                            </div>
                                                        </div>
                                                        {/* <h3 className="mb-1 font-semibold">Back to School</h3> */}
                                                        <div className="mb-1 text-gray-400 text-[12px]">Diskon {Number(item.diskon_persen)}% s/d {this.currencyFormat(item.diskon_maksimal)}</div>
                                                        <div className="text-[14px]">{this.formatDate(item.tgl_mulai)} - {this.formatDate(item.tgl_akhir)}</div>
                                                    </div>
                                                </div>

                                            )
                                        })}

                                    </div>
                                    <div className="flex justify-center pt-8">
                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={this.state.currentPage}
                                            totalCount={this.state.data.length}
                                            pageSize={PageSize}
                                            onPageChange={
                                                page => this.setState({ currentPage: page })
                                            }
                                        />
                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>

                }

            </div>
        );

    }
}

export default PublicVoucher;
