import React, { Component } from 'react';
import {
    Platform, View, StyleSheet, TextInput,
    Text, Image, StatusBar, BackHandler, Button
} from 'react-native';
import { useNavigate } from 'react-router-dom';
import '../styles/animation-product.css';
import ReactStars from "react-rating-stars-component";

class SectionBookVertical extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: this.props.data
        }
        // console.log("Section = " + this.props.data);
    }

    goToDetail = async () => {
        console.log("goToDetail" + this.props.data.id);
        //this.props.navigate('/etalaseDetails/' + this.props.data.id);
        this.props.navigate('/' + this.props.beranda + '/' + this.props.section + '/' + this.props.data.id);

    }

    render() {
        return (
            <div>
                <div className="hidden lg:block">
                    <div className="px-3 flex pt-2">
                        <div className="w-5/12">
                            <div className="rounded-full border-2 flex p-3 absolute bg-grayWalang border-white">
                                <div className="absolute top-0 left-2">
                                    {this.props.index + 1}
                                </div>
                            </div>
                            <div className="w-[80px] h-[115px] max-w-xs overflow-hidden rounded-[4px] shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out ml-2 mt-2">
                                <img className='w-full cursor-pointer' src={this.props.data.cover} alt="" onClick={this.goToDetail} />
                            </div>
                        </div>
                        <div className="w-7/12">
                            <div className="mt-0">
                                <div className="text-sm font-semibold mt-1 dark:text-black line-clamp-1">{this.props.data.judul}</div>
                                <div className="text-[10px] font-semibold mt-1 text-[#999999] line-clamp-1">{this.props.data.isbn}</div>
                                <div className="text-[10px] mt-1 text-[#999999] line-clamp-1">{this.props.data.penulis}</div>
                                <div className="text-[10px] mt-1 text-[#999999] line-clamp-1">{this.props.data.penerbit}</div>
                                {(() => {
                                if (this.state.data.formatted_harga_dasar == this.state.data.formatted_harga_akhir) {
                                    return (
                                        <div>
                                            <div className="ml-2 text-sm font-semibold text-greenWalang">Rp {this.state.data.formatted_harga_akhir}</div>
                                            <div className="ml-2 line-through text-[10px] font-semibold text-[#999999]">&nbsp;</div>
                                        </div>
                                    )
                                } else {
                                    return (
                                    <div>
                                        <div className="ml-2 text-s font-semibold text-greenWalang">Rp {this.state.data.formatted_harga_akhir}</div>
                                        <div className="ml-2 line-through text-xs font-semibold text-[#999999]">Rp {this.state.data.formatted_harga_dasar}</div>

                                    </div>
                                    )
                                }
                                })()}
                                {this.state.data.rating>0 ? 
                                <>
                                <div className='flex flex-row'>
                                    <div>
                                    <ReactStars
                                    count={1}
                                    size={11}
                                    value={parseInt(this.state.data.rating)}
                                    edit={false}
                                    activeColor="#ffd700"
                                    />
                                    </div>
                                    <div className='text-[11px]'>
                                    &nbsp;({this.state.data.rating})
                                    </div>
                                </div>
                                </>:<div></div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block lg:hidden">
                <div className="inline-block px-0 flex product-wrap w-[240px]">
                        <div className="w-[96px]">
                            <div className="rounded-full border-2 flex p-3 absolute bg-grayWalang border-white">
                                <div className="absolute top-1 left-2">
                                    {this.props.index + 1}
                                </div>
                            </div>
                            <div className="w-[78px] h-[118px] max-w-xs overflow-hidden rounded-[6px] shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out ml-4 mt-4">
                                <img className='w-full cursor-pointer' src={this.props.data.cover} alt="" onClick={this.goToDetail} />
                            </div>
                        </div>
                        <div className="w-[160px] ml-2">
                            <div className="mt-1">
                                <div className="text-sm font-semibold mt-4 dark:text-black line-clamp-1">{this.props.data.judul}</div>
                                <div className="text-[9px] font-semibold mt-1 text-[#999999] line-clamp-1">{this.props.data.isbn}</div>
                                <div className="text-[9px] mt-1 text-[#999999] line-clamp-1">{this.props.data.penulis}</div>
                                <div className="text-[9px] mt-1 text-[#999999] line-clamp-1">{this.props.data.penerbit}</div>
                                {(() => {
                                if (this.state.data.formatted_harga_dasar == this.state.data.formatted_harga_akhir) {
                                    return (
                                        <div>
                                            <div className="ml-2 text-sm font-semibold text-greenWalang">Rp {this.state.data.formatted_harga_akhir}</div>
                                            <div className="ml-2 line-through text-[9px] font-semibold text-[#999999]">&nbsp;</div>
                                        </div>
                                    )
                                } else {
                                    return (
                                    <div>
                                        <div className="ml-2 text-sm font-semibold text-greenWalang">Rp {this.state.data.formatted_harga_akhir}</div>
                                        <div className="ml-2 line-through text-[9px] font-semibold text-[#999999]">Rp {this.state.data.formatted_harga_dasar}</div>

                                    </div>
                                    )
                                }
                                })()}
                                {this.state.data.rating>0 ? 
                                <>
                                <div className='flex flex-row'>
                                    <div>
                                    <ReactStars
                                    count={1}
                                    size={11}
                                    value={parseInt(this.state.data.rating)}
                                    edit={false}
                                    activeColor="#ffd700"
                                    />
                                    </div>
                                    <div className='text-[11px]'>
                                    &nbsp;({this.state.data.rating})
                                    </div>
                                </div>
                                </>:<div></div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

var styles = StyleSheet.create({
    caption: {
        fontFamily: "Montserrat-Regular",
        fontSize: 16,
        marginBottom: 12,
        color: "#212A3B"
    }

})

function WithNavigate(props) {
    let navigate = useNavigate();
    return <SectionBookVertical {...props} navigate={navigate} />
}

export default WithNavigate;
