import React, { Component } from 'react';
import {
    Platform, View, StyleSheet, TextInput,
    Text, Image, StatusBar, BackHandler, Button
} from 'react-native';
// import { Button  } from 'react-native-elements';
// import Icon from 'react-native-vector-icons/Feather';

class CardCheckboxKategori extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: this.props.data
        }
    }

    render() {
        return (
            <div className="border rounded-2xl p-[30px] mb-[30px]">
                <div className="text=[20px] font-semibold pb-[15px] border-b border-grayWalang dark:text-black">{this.state.data.judul}</div>
                <fieldset className="pt-[15px]">
                    {this.state.data.listKategori.map(item => {
                        return (
                            <div className="flex items-center mb-4">
                                <input id="checkbox-1" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-greenWalang dark:border-gray-600" />
                                <label for="checkbox-1" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900">{item.kategori}</label>
                            </div>
                        );
                    })}

                </fieldset>
            </div>
        )
    }
}

var styles = StyleSheet.create({
    caption: {
        fontFamily: "Montserrat-Regular",
        fontSize: 16,
        marginBottom: 12,
        color: "#212A3B"
    },
    placeholder: {
        fontFamily: "Montserrat-Regular",
        fontSize: 14
    },
    input: {
        flex: 1,
        backgroundColor: "#F6F6F6",
        borderRadius: 10,
        fontFamily: "Montserrat-Regular",
        fontSize: 14,
        padding: 12,
        color: '#34495e'
    },
    error: {
        fontFamily: "Montserrat-Regular",
        fontSize: 14,
        color: "#e74c3c",
        alignSelf: "flex-end"
    }


})

export default CardCheckboxKategori;
