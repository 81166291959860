import { Component } from 'react';
import ListTransaction from '../components/ListTransaction';

import { executeGet, executePost } from '../functions/Kubuku';
import { DAFTAR_TRANSAKSI } from '../api';

class Review extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            flagData: false,
            data: '',
        }
    }

    componentDidMount() {
        this.getTransaksi();
    }

    getTransaksi = async () => {
        try {
            let formData = new FormData(); 
            formData.append("page", "1");
            formData.append("row", "10");
      
            const json = await executePost(DAFTAR_TRANSAKSI, formData);
            console.log("transaksi = " + JSON.stringify(json));
            if (json.code == 200) {
                // this.getProfile();
                // open modal
                // this.props.navigate('/cart');
                this.setState({flagData: true, data: json.data});
            }
          } catch (error) {
              //console.log(error);
              this.setState({ isError: true, errorMessage: "Network Error!" });
          }
      }

    render() {
        return (
            <div>
                <div className="dark:bg-white border rounded-2xl p-[30px]">
                <div className="text-4xl font-semibold dark:text-black pb-6">Ulasan</div>
                {this.state.flagData && this.state.data.map((row) => {
                    return (
                        <ListTransaction
                            key={row.order_id}
                            data={row}
                        />
                    );
                })}
                </div>
            </div>
        );

    }
}

export default Review;
