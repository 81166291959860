import React, {Component } from 'react';

import { createNewHeaders, updateHeaders,executePost, executeGet } from '../../functions/Kubuku';
import { ADD_BANNER, LIST_CONTENT, UPDATE_POSISI, CUD_ETALASE, DELETE_CONTENT } from '../../api';

import QRCode from "react-qr-code";
import CardQRBuku from '../../components/CardQRBuku';
import ModalLoading from "../../components/ModalLoading";
import ModalPopup from "../../components/ModalPopup";
import ModalQR from "../../components/ModalQR"

import {
    useParams,
    useNavigate
} from "react-router-dom";

class QRBuku extends Component {

    constructor(props) {
        super(props)
        this.state = {
            param: this.props.params,
            currentPage: 1,
            showMain: true,
            showModal: false,
            isLoading: false,
            showLoading: false,
            isModal: false,
            isError: false,
            isQR: false,
            judul: '',
            deskripsi: '',
            data: '',
            banner: '',
            msg: '',
            id: '',
            kode_beranda: '',
            kode_section:'',
            status: 0,
            modalEdit: false,
            dataKonten:'',
            posisi:10,
            flagEtalase: true,
            flagKonten: false,
            chgImage: false,
            imgBanner: null
        }
        this.imgRef = React.createRef();
    }
    
    componentDidMount() {
        console.log("setting etalase = " + this.state.param.id);
        //this.getEtalase();
    }

    scriptLoaded() {
        console.log("script load");
        // window.A.sort();
    }
    
    onQR = async (url) => {
        this.setState({ isLoading: false, isQR: true, msg: url });
    }

    onShare = async (url) => {
        const title = document.title;
        const text = "Silahkan buka Link di atas.";
        try {
            await navigator
            .share({
                title,
                url,
                text
            })
            } catch (err) {
                alert(`Couldn't share ${err}`);
            }
    }

    
    render() {
        return (
            <div>
                {
                    this.state.isLoading &&
                    <ModalLoading />
                }
                {
                    this.state.isModal &&
                    <ModalPopup
                        type="success"
                        messages={this.state.msg}
                        press={() => { this.setState({ isModal: false }) }}
                    />
                }
                {
                    this.state.isQR &&
                    <ModalQR
                        type="success"
                        messages={this.state.msg}
                        press={() => { this.setState({ isQR: false }) }}
                    />
                }
                {
                    this.state.isError &&
                    <ModalPopup
                        type="error"
                        messages={this.state.msg}
                        press={() => { this.setState({ isError: false }) }}
                    />
                }
                
                
                {
                    this.state.showMain &&
                    <div className="dark:bg-white border rounded-2xl lg:p-[30px] p-[8px] mb-20">
                        
                        {this.state.flagEtalase && 
                            <div className="relative p-1 flex-auto">
                                <CardQRBuku
                                    kodeEtalase={'IBF00051'}
                                    close={() => {
                                        this.setState({ showModal: false });
                                        this.getEtalase();
                                    }}
                                />
                                

                            </div>
                        }
                        
                    </div>

                }
            </div>
        );

    }
}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <QRBuku {...props} navigate={navigate} params={useParams()} />
}

export default WithNavigate;
