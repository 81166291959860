import { Component } from 'react';
import DataTable from 'react-data-table-component';

import { createNewHeaders, updateHeaders,clientSettings, executeGet, executePost } from '../../functions/Kubuku';
import { LIST_VOUCHER, CUD_OUTLET, LAPORAN_TRANSAKSI, LIST_OUTLET } from '../../api';

import QRCode from "react-qr-code";
import ModalSettings from '../../components/ModalSettings';
import ModalLoading from "../../components/ModalLoading";
import ModalPopup from "../../components/ModalPopup";
import ExportExcel from "../../components/ExportExcel"

import {
    useParams,
    useNavigate
} from "react-router-dom";

import Pagination from '../../components/Pagination';
import { connect } from 'react-redux';
import { bukuPromosiAction } from '../../redux/actions';



let PageSize = 12;

class LaporanTransaksi extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            showMain: false,
            showModal: false,
            isLoading: false,
            showLoading: false,
            data: [],
            fileName: 'Report Transaksi ' + Date.now(),
            msg: '',
            flagAktif: true,
            flagTidakAktif: false,
            flagKadaluarsa: false,
            flagGagal: false,
            dataAktif: '',
            dataTidakAktif: '',
            dataKadaluarsa: '',
            tgl_mulai: '',
            tgl_akhir: '',
            beranda: [{ id: "-", nama: "Pilih Outlet" }],
            kode_beranda: '-',
            kodePromo: ''
        }
    }

    componentDidMount() {

        var today = new Date(),
            date = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2);
        var satu = new Date(),
            awal = satu.getFullYear() + '-' + ('0' + (satu.getMonth() + 1)).slice(-2) + '-' + '01';
        this.setState({ tgl_mulai: awal, tgl_akhir: date, showMain: true });
        this.listBeranda();
    }

    scriptLoaded() {
        console.log("script load");
        // window.A.sort();
    }

    listBeranda = async () => {
        try {
            let beranda;
            const flagOpen = localStorage.getItem('flagOpen');
            console.log("flagOpen = " + flagOpen);
            if(flagOpen != "1") {
                createNewHeaders('', ''); 
            } else {
                updateHeaders('', ''); 
            }
            const json = await executeGet(LIST_OUTLET);
            console.log("list Outlet = " + JSON.stringify(json.data));
            if (json.code == 200) {
                beranda = json.data;
                let aberanda = [];
                aberanda.push({ id: "-", nama: "Semua Outlet" });
                beranda.map((item, i) => {
                    aberanda.push({ id: item.kode, nama: item.judul });
                }, this);
                this.setState({ beranda: aberanda });
            } else if (json.code == 500 || json.code == 505) {
                return "";
            }
        } catch (error) {
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
    }

    getLaporanTransaksi = async () => {
        //this.setState({ isLoading: true });
        console.log(this.state.fileName);
        try {
            let pr = this.state.kodePromo;
            if (pr=="") pr="-";
            const json = await executeGet(LAPORAN_TRANSAKSI + '/' + this.state.tgl_mulai + '/' + this.state.tgl_akhir + '/' + this.state.kode_beranda + '/' + pr);
            console.log("Laporan = " + JSON.stringify(json));
            if (json.code == 200) {
                this.setState({ data: json.data, fileName: 'Report Transaksi ' + Date.now() });
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
    }

    render() {
        //this.listBeranda();
        const columns = [

            {
                name: 'TANGGAL',
                selector: row => row.tgl_input,
            },
            {
                name: 'BUKU',
                selector: row => row.judul,
                maxWidth: '120px'
            },
            {
                name: 'HARGA',
                selector: row => row.sub_total,
                right: true
            },
            {
                name: 'DISCOUNT',
                selector: row => row.diskon,
                right: true
            },
            {
                name: 'TOTAL',
                selector: row => row.total,
                right: true
            },
            {
                name: 'TGL BAYAR',
                selector: row => row.pay_date,
            },
            {
                name: 'REF BANK',
                selector: row => row.pay_ref,
            },
            {
                name: 'PENULIS',
                selector: row => row.penulis,
            },
            {
                name: 'ISBN',
                selector: row => row.isbn,
            },
            {
                name: 'PENERBIT',
                selector: row => row.penerbit,
            },
            {
                name: 'KODE VOUCHER',
                selector: row => row.kode_voucher,
            },
        ];
        //this.getLaporanTransaksi();
        return (
            <div>

                {
                    this.state.showMain &&
                    <div className="dark:bg-white border rounded-2xl md:p-[30px] p-[15px] mb-20">
                        <div className="text-[16px] md:text-[24px] font-semibold dark:text-black">LAPORAN TRANSAKSI</div>
                        <div className="pt-8">
                            <div className="flex w-full space-x-3 hidden md:block">
                                <div className="mb-6 w-6/6">
                                    <label for="tgl_mulai" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Tanggal Mulai</label>
                                    <input type="date" id="idTglMulai" value={this.state.tgl_mulai} onChange={event => this.setState({ tgl_mulai: event.target.value })} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Select date" required />
                                </div>
                                <div className="mb-6 w-6/6">
                                    <label for="tgl_berakhir" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Tanggal Berakhir</label>
                                    <input type="date" id="idTglAkhir" value={this.state.tgl_akhir} onChange={event => this.setState({ tgl_akhir: event.target.value })} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Select date" required />

                                </div>
                                <div className="mb-6 w-6/12">
                                    <label for="beranda" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Outlet</label>

                                    <select value={this.state.kode_beranda} onChange={event => this.setState({ kode_beranda: event.target.value })} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light pr-10" placeholder="Single" required>
                                        {
                                            this.state.beranda.map((item, i) => {
                                                return (
                                                    <option value={item.id}>{item.nama}</option>
                                                )
                                            }, this)
                                        }
                                    </select>
                                </div>
                                <div className="mb-6 w-6/12">
                                    <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Kode Promo</label>
                                    <input type="text" id="kodepromo" value={this.state.kodePromo} onChange={event => this.setState({ kodePromo: event.target.value })}  className="mt-1 block w-7/12 p-3 bg-gray-50 border border-gray-300 text-gray-900 rounded-full text-sm shadow-sm focus:outline-none focus:border-greenWalang focus:ring-1 focus:ring-greenWalang" placeholder="Masukan Kode Promo" />
                                </div>
                                <div className="mb-6 w-6/12">
                                    <button onClick={() => { this.getLaporanTransaksi() }} type="button" className="bg-greenWalang font-medium rounded-full text-sm px-5 py-3 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 mb-4 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                        <div className="text-[#ffffff] pr-4">
                                            Tampilkan
                                        </div>
                                    </button>
                                    <ExportExcel csvData={this.state.data} fileName="Report Transaksi" />
                                    {/* <button onClick={() => { this.exportExcel() }} type="button" className="bg-greenWalang font-medium rounded-full text-sm px-5 py-3 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 mb-4 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                        <div className="text-[#ffffff] pr-4 text-center">
                                            Export
                                        </div>
                                    </button> */}
                                </div>
                            </div>

                            <div className="w-full block md:hidden">
                                <div className="w-full">
                                    <label for="tgl_mulai" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Tanggal Mulai</label>
                                    <input type="date" id="idTglMulai" value={this.state.tgl_mulai} onChange={event => this.setState({ tgl_mulai: event.target.value })} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Select date" required />
                                </div>
                                <div className="w-full mt-2">
                                    <label for="tgl_berakhir" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Tanggal Berakhir</label>
                                    <input type="date" id="idTglAkhir" value={this.state.tgl_akhir} onChange={event => this.setState({ tgl_akhir: event.target.value })} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Select date" required />

                                </div>
                                <div className="w-full mt-2">
                                    <label for="beranda" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Outlet</label>

                                    <select value={this.state.kode_beranda} onChange={event => this.setState({ kode_beranda: event.target.value })} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light pr-10" placeholder="Single" required>
                                        {
                                            this.state.beranda.map((item, i) => {
                                                return (
                                                    <option value={item.id}>{item.nama}</option>
                                                )
                                            }, this)
                                        }
                                    </select>
                                </div>
                                <div className="w-full mt-2 mb-6">
                                    <label for="kodepromo" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Kode Promo</label>
                                    <input type="text" id="kodepromo" value={this.state.kodePromo} onChange={event => this.setState({ kodePromo: event.target.value })} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Select date" required />
                                    
                                </div>
                                <div className="w-full mt-2">
                                    <div className="mb-6">
                                        <button onClick={() => { this.getLaporanTransaksi() }} type="button" className="bg-greenWalang font-medium rounded-full text-sm px-5 py-3 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 mb-4 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                            <div className="text-[#ffffff] pr-4">
                                                Tampilkan
                                            </div>
                                        </button>
                                        <ExportExcel csvData={this.state.data} fileName={this.state.fileName} />
                                        {/* <button onClick={() => { this.exportExcel() }} type="button" className="bg-greenWalang font-medium rounded-full text-sm px-5 py-3 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 mb-4 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                            <div className="text-[#ffffff] pr-4 text-center">
                                                Export
                                            </div>
                                        </button> */}
                                    </div>
                                </div>
                            </div>

                            <DataTable
                                columns={columns}
                                data={this.state.data}
                                pagination
                                highlightOnHover={true}
                                pointerOnHover={true}

                            />
                        </div>


                    </div>

                }
            </div>
        );

    }
}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <LaporanTransaksi {...props} navigate={navigate} params={useParams()} />
}

const mapStateToProps = (state) => ({
    bukuPromosi: state.bukuPromosi
});

export default connect(mapStateToProps, { bukuPromosiAction })(WithNavigate);
