import { Component } from 'react';
import ModalPopup from "../components/ModalPopup";

import { clientSettings, executeGet, executePost } from '../functions/Kubuku';
import { GET_WISHLIST, ADD_CART } from '../api';

import {
    useNavigate
} from "react-router-dom";

import Pagination from '../components/Pagination';
let PageSize = 10;
let page = 1;
let domain = localStorage.getItem("domain")
class PrivacyPolicy extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            data: '',
            isModal: false,
            msg: '',
            showMain: true
        }

    }

    render() {
        return (
            <div>
                {
                    this.state.isModal &&
                    <ModalPopup
                        type="success"
                        messages={this.state.msg}
                        press={() => { this.setState({ isModal: false }) }}
                    />
                }
                { this.state.showMain &&
                    <div>
                        <div className="hidden lg:block">
                            <div className="pt-24 px-36 dark:bg-white">
                                <div className="py-7">
                                    {/* Home / Wishlist */}
                                    <nav className="flex" aria-label="Breadcrumb">
                                        <ol className="inline-flex items-center space-x-1 lg:space-x-3">
                                            <li className="inline-flex items-center">
                                                <a href="/" className="inline-flex items-center text-sm font-medium text-greenWalang hover:text-greenWalang hover:opacity-30 dark:text-greenWalang dark:hover:text-greenWalang">
                                                    Home
                                            </a>
                                            </li>
                                            <li aria-current="page">
                                                <div className="flex items-center">
                                                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                                    <span className="ml-1 text-sm font-medium text-gray-400 lg:ml-2 dark:text-gray-500">Kebijakan Privasi</span>
                                                </div>
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="py-4 px-28 bg-greenWalang bg-opacity-30 text-2xl text-greenWalang">
                                Kebijakan Privasi
                            </div>
                            <div className="py-10 px-28 dark:bg-white">
                                <div className="relative overflow-x-auto shadow-md sm:rounded-lg p-10">
                                    <div className="font-semibold text-xl mb-5">
                                        Kebijakan Privasi {domain}
                                    </div>
                                    <div>
                                        <br/><br/>
                                        <b>PENGUMPULAN DAN PENGGUNAAN INFORMASI PRIBADI</b>
                                        <br/><br/>
                                        <b>{domain}</b>, adalah platform toko buku daring yang menjual buku, majalah, koran dalam bentuk fisik maupun digital dan konten literasi lainnya.
                                        Informasi pribadi Anda adalah perhatian Kami, karena kami ingin memastikan bahwa setiap informasi yang kami kumpulkan bertujuan untuk meningkatkan pelayanan Kami kepada anda, dan untuk memberikan informasi, konten, dan pengalaman pribadi yang paling bermanfaat bagi Anda. 
                                        Kami berkomitmen untuk melindungi informasi pribadi semua Pelanggan, termasuk Anda, pengunjung, baik melalui aplikasi maupun media WEB, mitra spplier, dan banyak pemilik kepentingan terkait lainnya.
                                        <br/><br/>
                                        Informasi Pribadi berarti data atau informasi mengenai diri Anda yang dapat diidentifikasi secara pribadi dan diperoleh melalui platform Kami (Aplikasi dan media WEB), seperti nama, tanggal lahir, alamat email, dan informasi lainnya yang dapat mengidentifikasi Anda sebagai pengguna Kami.
                                        <br/><br/>
                                        Anda mungkin akan diminta memberikan informasi pribadi kapanpun ketika anda menghubungi Kami sebagai bagian dari proses validasi anda benar-benar sebagai pengguna Kami.
                                        Apabila anda sudah memberikan sebagian informasi pribadi anda kepada Kami, Kami juga dapat menggabungkannya dengan informasi lain untuk tujuan penyediaan konten dan penyempurnaan produk, layanan, konten, serta iklan. 
                                        Apabila anda tidak memberikan informasi pribadi anda kepada Kami, adalah syah, namun kami tidak memberikan informasi dan batasan konten, tidak menyajikan preview buku sehingga anda yakin dengan buku yang akan anda beli.
                                        <br/><br/>
                                        <br/><br/>
                                        <b>RUANG LINGKUP</b>
                                        <br/><br/>
                                        Kebijakan Privasi ini berlaku untuk semua informasi pribadi di seluruh produk/layanan dan berlaku untuk setiap transaksi dan kegiatan Pelanggan dalam layanan Kami.
                                        <br/><br/>
                                        <br/><br/>
                                        <b>INFORMASI PRIBADI YANG KAMI KUMPULKAN</b>
                                        <br/><br/>
                                        Berikut adalah beberapa informasi yang akan kami kumpulkan saat ini:.
                                        <li>Saat Anda anda sebagai pengunjung yang berarti anda tidak melakukan login, maka ketika anda ingin melihat preview buku, kami akan meminta anda memasukan tanggal lahir anda sebagai upaya kami memasitkan anda memiliki umur yang memiliki kesesuaian dengan peruntukan buku tersebut, ini adalah upaya kami agar pengunjung Kami yang di bawah umur tidak mengakses atau bahkan membeli buku-buku untuk peruntukan umur diatas mereka. Pengkategorian peruntukan umur dari buku yang diakses merupakan sepenuhnya tanggung jawab penerbit, kami hanya menyajikan sesuai dengan data yang diberikan penerbit dan tanggal lahir yang anda berikan.</li>
                                        <li>Apabila anda merasa perlu untuk mendaftarkan sebagai penguna kami, saat ini kami hanya memerlukan data Nama Lengkap, Alamat email, Tanggal lahir dan Jenis kelamin anda. Selain itu untuk keamanan, anda diwajibkan untuk membuat password yang bersifat pribadi dan anda tidak dibenarkan untuk membagikan password anda kepada pihak lain. Dari data anda kami akan berusaha menyajikan konten yang memiliki kesesuaian dengan data anda, sehingga anda akan lebih efektif dalam menggunakan platform toko buku daring kami.</li>
                                        <li>Kami juga menyediakan pencarian buku yang akan mencari berdasarkan kata atau kalimat yang anda input, dan untuk perbaikan produk literasi di toko buku daring ini, Kami akan menyimpannya, dan akan Kami olah sebagai referensi untuk meningkatkan produk literasi yang dijual di toko buku Kami, Kami juga berhak memberikan referensi ini kepada penerbit yang bekerja sama dengan Kami.</li>
                                        <li>Agar Kami dapat memberikan pelayanan yang lebih baik, Kami juga mengumpulkan beberapa informasi ketika Anda menggunakan Aplikasi dan web termasuk informasi tentang perangkat yang Anda gunakan, informasi tentang penggunaan platform, cookies, informasi lokasi.</li>
                                        <li>Anda juga dimungkinkan untuk membelikan konten digital di platform kami dan memberikannya kepada pihak lain, untuk itu kami berhak mencatat proses dan riwayat pemberian konten tersebut.</li>
                                        <li>Kami dapat meminta tanda pengenal yang dikeluarkan pemerintah dalam kondisi tertentu termasuk bila diwajibkan berdasarkan hukum.</li>
                                        <br/><br/>
                                        <br/><br/>
                                        <b>PENGGUNAAN INFORMASI PRIBADI</b>
                                        <br/><br/>
                                        <li>Informasi pribadi yang kami kumpulkan memungkinkan kami terus mengabari Anda tentang pemberitahuan produk terkini, penerbitan produk baru, promosi, penawaran yang dipersonalisasi, pengembangan layanan dan pembaruan perangkat lunak. Kami dapat menggunakan informasi pribadi Anda dan transaksi untuk menganalisis perilaku pelanggan dan menggunakannya untuk memberikan layanan / pengalaman yang lebih baik bagi Anda. Anda dapat keluar kapan pun dari mailing list Kami dengan memperbarui preferensi.</li>
                                        <li>Kami juga berhak menggunakan informasi pribadi untuk membantu membuat dan atau mengembangkan,  meningkatkan produk, layanan, konten, dan iklan kami, dan semaksimal mungkin untuk mencegah kehilangan dan penipuan.</li>
                                        <li>Kami berhak menggunakan informasi tanggal lahir anda untuk melakukan verifikasi identitas, membantu mengidentifikasi pengguna, atau menentukan layanan yang tepat berdasarkan umur.</li>
                                        <li>Kami berhak menggunakan data pribadi email anda untuk mendaftarkan dalam sebuah kegiatan yang berhubungan dengan pelanggan seperti promosi dan pemberian voucher.</li>
                                        <li>Kami berhak menggunakan informasi pribadi Anda untuk memberikan dukungan atau layanan lain yang terkait dengan transaksi yang telah dilakukan pelanggan, seperti keluhan pelanggan, konfirmasi pelanggan terkait transaksi, dan layanan pelanggan lainnya yang terkait dengan transaksi tersebut.</li>
                                        <li>Kami berhak menggunakan informasi non-personally-identifiable attributes and cookies Anda untuk menampilkan iklan yang paling sesuai dengan Anda.</li>
                                        <li>Kami menggunakan informasi pribadi untuk mencegah aktifitas ilegal yang potensial dan menegakkan Syarat dan Ketentuan Kami. Kami juga menggunakan bermacam-macam sistem teknologi untuk mendeteksi aktifitas yang tidak lazim untuk penyalahgunaan, seperti spam.</li>
                                        <li>Dari waktu ke waktu, kami berhak menggunakan informasi pribadi untuk membrikan Anda pemberitahuan penting, seperti komunikasi tentang pembelian serta perubahan persyaratan, ketentuan, dan kebijakan kami.</li>
                                        <li>Kami juga dapat menggunakan informasi pribadi untuk tujuan internal seperti audit, analisis data, dan penelitian guna menyempurnakan produk, layanan, dan komunikasi pelanggan.</li>
                                        <li>Jika Anda mengikuti program berhadiah yang kami adakan, kami berhak menggunakan informasi yang Anda sediakan untuk mengelola program tersebut.</li>
                                        <li>Dari waktu ke waktu, Kami dapat meminta Anda untuk memberikan saran-saran, agar produk/layanan lebih sesuai dengan yang Anda butuhkan dan minati.</li>
                                        <br></br>
                                        <br></br>
                                        <b>PENGUMPULAN DAN PENGGUNAAN INFORMASI NON-PRIBADI</b>
                                        <br/><br/>
                                        Kami juga berhak mengumpulkan data-data non-pribadi pengunjung Kami untuk tujuan pengembangan dan analisis data. Berikut adalah beberapa contoh informasi non-pribadi yang kami kumpulkan beserta cara menggunakannya:
                                        <li>Kami dapat mengumpulkan informasi seperti  pengenal perangkat unik, URL, waktu, zona waktu, saat pengunjung mengakses platform Kami, agar Kami dapat memahami perilaku pelanggan secara lebih baik dan menyempurnakan produk, layanan, dan iklan.</li>
                                        <li>Kami dapat mengumpulkan informasi tentang aktivitas pelanggan di platform Kami. Informasi ini digabungkan dan digunakan untuk membantu kami menyediakan informasi yang lebih bermanfaat kepada pelanggan serta untuk memahami bagian yang paling menarik dari platform Kami, produk, dan layanan kami. Data gabungan akan dianggap sebagai informasi non-pribadi untuk tujuan Kebijakan Privasi ini.</li>
                                        <li>Kami berhak mengumpulkan dan menyimpan rincian tentang cara Anda menggunakan layanan kami, termasuk permintaan pencarian, informasi ini akan kami jadikan referensi untuk mengembangkan konten dan layanan sehingga memiliki kesesuaian dengan Anda, dan kami berhak memberikan referensi ini kepada penerbit yang bekerjasama dengan Kami.</li>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <b>PENYIMPANAN</b>
                                        <br></br>
                                        <br></br>
                                        Kami akan tetap menyimpan informasi pribadi selama data tersebut diperlukan untuk memenuhi tujuan dari pengumpulan data, tujuan hukum atau bisnis, atau dibutuhkan oleh hukum. Kami akan menyimpan informasi pribadi setidaknya 5 tahun semenjak informasi pribadi dikumpulkan.
                                        <br/><br/>
                                        Jika kami menggabungkan informasi non-pribadi dengan informasi pribadi, maka informasi gabungan akan ditangani sebagai informasi pribadi selama tetap dalam gabungan.
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <b>COOKIE DAN TEKNOLOGI LAINNYA</b>
                                        <br></br>
                                        <br></br>
                                        Platform, dan iklan Kami mungkin menggunakan cookie dan teknologi lain. Teknologi ini membantu kami memahami perilaku pengguna secara lebih baik, memberi tahu kami bagian di platform Kami yang dikunjungi orang, serta memfasilitasi dan mengukur efektivitas iklan maupun pencarian. 
                                        <br/><br/>
                                        Kami akan menangani informasi yang dikumpulkan cookie dan teknologi lainnya sebagai informasi non-pribadi. Namun, selama alamat IP (Protokol Internet) atau pengenal serupa dianggap informasi pribadi oleh undang-undang setempat, maka kami juga akan menangani pengenal tersebut sebagai informasi pribadi. Selain itu, selama informasi non-pribadi digabungkan dengan informasi pribadi, kami akan menangani informasi gabungan sebagai informasi pribadi untuk tujuan Kebijakan Privasi ini.
                                        <br/><br/>
                                        Kami akan menggunakan cookie dan teknologi lainnya untuk mengingat informasi pribadi saat Anda menggunakan platform Kami. Tujuan kami adalah membuat pengalaman Anda menggunakan platform kami menjadi lebih nyaman dan pribadi.
                                        <br/><br/>
                                        Jika Anda ingin menon-aktifkan cookie, silahkan pelajari cara membersihkan dan menonaktifkan cookie browser yang anda pakai.
                                        <br/><br/>
                                        Kami menggunakan informasi ini untuk memahami dan menganalisis tren, menjalankan platform, mempelajari perilaku pengguna, menyempurnakan produk dan layanan kami, serta mengumpulkan informasi demografis tentang basis pengguna kami secara keseluruhan, dan dapat menggunakan informasi ini dalam pemasaran dan layanan iklan kami.
                                        <br/><br/>
                                        Di sejumlah pesan email, kami menggunakan “Buka Link”. Saat pelanggan mengklik salah satu URL tersebut, kami melacak untuk membantu menentukan minat tentang topik tertentu dan mengukur efektivitas komunikasi kami dengan pelanggan. Jika Anda tidak ingin dilacak dengan cara ini, jangan klik link teks atau grafis yang tercantum dalam pesan e-mail.
                                        <br/><br/>
                                        Kami berhak menggunakan watermark berisi Informasi Pribadi dan Informasi Non-Pribadi Anda untuk mencegah penggunaan produk, layanan, dan konten ilegal yang melanggar hak-hak pihak lain.
                                        <br/><br/><br/>
                                        <b>PENGUNGKAPAN KEPADA PIHAK KETIGA</b>
                                        <br/><br/>
                                        Kami berhak memberikan informasi pribadi tertentu kepada mitra strategis yang bekerja sama dengan Kami untuk menyediakan produk dan layanan, atau membantu untuk memasarkan kepada pelanggan. Informasi pribadi hanya akan diberikan untuk menyediakan atau menyempurnakan produk, layanan, maupun iklan kami, dan tidak akan diberikan kepada pihak ketiga untuk tujuan pemasaran mereka.
                                        <br/><br/><br/>
                                        <b>LAINNYA</b>
                                        <br/><br/>
                                        Undang-undang, proses hukum, litigasi, dan/atau permintaan dari lembaga publik maupun pemerintah di dalam atau luar negara domisili Anda mungkin mengharuskan Kami mengungkapkan informasi pribadi Anda. Kami juga dapat mengungkapkan informasi tentang Anda jika kami menilai bahwa pengungkapan untuk tujuan keamanan nasional, penegakan hukum, atau masalah lain demi kepentingan publik harus atau perlu dilakukan.
                                        <br/><br/>
                                        Kami juga dapat mengungkapkan informasi tentang Anda jika kami menilai bahwa pengungkapan tersebut dianggap perlu untuk menegakkan persyaratan dan ketentuan kami atau melindungi operasional kami maupun pengguna. Selain itu, jika terjadi reorganisasi, merger, atau penjualan, kami dapat mengalihkan setiap dan semua informasi pribadi yang kami kumpulkan ke pihak ketiga terkait.
                                        <br/><br/><br/>
                                        <b>PERLINDUNGAN INFORMASI PRIBADI</b>
                                        <br/><br/>
                                        Kami menangani keamanan informasi pribadi Anda dengan sangat serius. Kami melindungi informasi pribadi Anda selama pengalihan menggunakan enkripsi, misalnya TLS (Transport Layer Security).
                                        <br/><br/>
                                        Ketika Anda menggunakan sejumlah produk, layanan, atau aplikasi Kami, dan Anda membagikan informasi pribadi dan konten ke pengguna lain sehingga dapat dibaca, dikumpulkan, atau digunakan oleh mereka. Dalam hal ini, Anda sepenuhnya bertanggung jawab atas informasi pribadi yang Anda bagi atau kirim..
                                        <br/><br/>
                                        Ketika Anda menggunakan fitur pembaca, pemutar audio, pemutar video, dan layanan lainnya yang berisi konten berlisensi, watermark berisi informasi pribadi dan informasi non-pribadi pada konten Anda sehingga dapat dilihat oleh pihak lain dan dikumpulkan, atau digunakan oleh mereka. Maka Anda sepenuhnya bertanggung jawab atas informasi pribadi dan informasi non-pribadi dalam konten tersebut. Hati-hati saat menggunakan fitur tersebut pada perangkat publik atau perangkat yang dapat digunakan bersama-sama dengan pihak lain.
                                        <br/><br/><br/>
                                        <b>INTEGRITAS DAN PENYIMPANAN INFORMASI PRIBADI</b>
                                        <br/><br/>
                                        Kami akan memudahkan Anda menjaga keakuratan, kelengkapan, dan kebaruan informasi pribadi Anda. Kami akan menyimpan informasi pribadi Anda selama diperlukan untuk memenuhi tujuan yang dijelaskan dalam Kebijakan Privasi ini, kecuali jika masa penyimpanan yang lebih lama diperlukan atau diizinkan oleh undang-undang.
                                        <br/><br/><br/>
                                        <b>AKSES KE INFORMASI PRIBADI</b>
                                        <br/><br/>
                                        Anda dapat membantu memastikan bahwa informasi kontak dan preferensi Anda sudah akurat, lengkap, dan terbaru dengan masuk ke akun Anda atau menghubungi kami. Untuk informasi pribadi lain yang kami simpan, kami akan memberi Anda akses untuk tujuan apapun, termasuk guna meminta kami mengoreksi data yang tidak akurat. Apabila tidak wajib menyimpannya berdasarkan hukum atau untuk keperluan bisnis yang sah, Kami dapat menolak untuk memproses permintaan yang tidak serius/mengganggu, membahayakan privasi orang lain, sangat tidak praktis, atau yang aksesnya tidak diwajibkan oleh hukum setempat. Permohonan akses, koreksi dapat diajukan dengan menghubungi kami.
                                        <br/><br/><br/>
                                        <b>INFORMASI PRIBADI ANAK DI BAWAH UMUR</b>
                                        <br/><br/>
                                        Kami memahami pentingnya mengambil langkah pencegahan tambahan untuk melindungi privasi dan keselamatan anak yang menggunakan produk dan layanan Kami. Karenanya, kami tidak akan dengan sengaja mengumpulkan, menggunakan, atau mengungkapkan informasi pribadi dari anak berusia di bawah 17 tahun, atau usia minimum yang setara di yurisdiksi terkait, tanpa persetujuan orang tua yang dapat diverifikasi.
                                        <br/><br/>
                                        Anak berusia di bawah 17 tahun, atau yang setara di yurisdiksi terkait, tidak diizinkan membuat akun mereka. Orang tua harus membaca Kebijakan Provasi dan Syarat dan Ketentuan, agar dapat memulai proses pembuatan akun untuk anak mereka.
                                        <br/><br/><br/>
                                        <b>LAYANAN BERBASIS LOKASI</b>
                                        <br/><br/>
                                        Untuk menyediakan layanan berbasis lokasi di produk dan layanan Kami, Kami berhak mengumpulkan, menggunakan, dan berbagi data lokasi yang sebenarnya, termasuk lokasi geografis real-time komputer maupun perangkat Anda. Bila tersedia, layanan berbasis lokasi dapat menggunakan GPS, Bluetooth, dan Alamat IP Anda, beserta lokasi hotspot Wi-Fi publik dan menara seluler, serta teknologi lainnya untuk menentukan lokasi pasti perangkat Anda. Kecuali jika Anda memberikan persetujuan, data lokasi ini akan dikumpulkan secara anonim dalam bentuk yang tidak mengenali Anda secara pribadi dan digunakan oleh Kami dan mitra serta pemegang lisensi kami untuk menyediakan dan menyempurnakan produk dan layanan berbasis lokasi.
                                        <br/><br/><br/>
                                        <b>PENGGUNA INTERNASIONAL</b>
                                        <br/><br/>
                                        Semua informasi yang Anda berikan dapat ditransfer atau diakses oleh entitas di seluruh dunia sebagaimana dijelaskan dalam Kebijakan Privasi ini.
                                        <br/><br/><br/>
                                        <b>NOTIFIKASI TENTANG PERUBAHAN</b>
                                        <br/><br/>
                                        Kami dapat memperbarui Kebijakan Privasi ini dari waktu ke waktu. Bila kami melakukan perubahan penting pada kebijakan, pemberitahuan akan diposting di situs web kami bersama Kebijakan Privasi yang telah diperbarui.
                                        <br/><br/><br/>
                                        <b>PERNYATAAN DAN PERSETUJUAN</b>
                                        <br/><br/>
                                        Dengan menggunakan platform/Aplikasi/layanan Kami, berarti Anda menyatakan bahwa Anda telah membaca dan mengerti Kebijakan Privasi ini dan Ketentuan Penggunaan dan setuju serta memperkenakan Kami untuk menggunakan, menerapkan, memproses, dan mengalihkan informasi pribadi Anda sebagaiamana dinyatakan dalam Kebijakan Privasi ini.
                                        <br/><br/>
                                        Anda juga menyatakan bahwa Anda berhak untuk membagikan atas seluruh informasi yang telah diberikan kepada Kami dan memberikan kepada Kami kewenangan untuk menggunakan dan membagikan informasi pribadi Anda untuk mendukung dan melaksanakan produk/layanan Kami.
                                        <br/><br/><br/>
                                        <b>HUBUNGI KAMI</b>
                                        <br/><br/>
                                        Jika anda mempunyai pertanyaan atau kepentingan atas Kebijakan Privasi ini atau mengenai penggunaan informasi pribadi, hubungi Kami melalui:
                                        <br/><br/>
                                        PT Enam Kubuku Indonesia
                                        <br/>
                                        PT. Enam Kubuku Indonesia Ruko Jambusari 7A Yogyakarta
                                        <br/>
                                        Telepon: 0274 889398
                                        <br/>
                                        Email: info@kubuku.co.id
                                        <br/><br/>
                                        Dengan menggunakan Situs-web/Aplikasi/layanan Kami, Anda dianggap setuju atas Kebijakan Privasi ini termasuk namun tidak terbatas pada seluruh perubahannya.
                                        <br/><br/>
                                        <br/><br/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="block lg:hidden">
                            <div className="pt-32 pb-10 px-4 dark:bg-white">
                                <div className="relative overflow-x-auto shadow-md sm:rounded-lg p-10">
                                    <div className="font-semibold text-xl mb-5">
                                        Tentang Kami
                                    </div>
                                    <div>
                                        <b>{domain}</b> merupakan unit penjualan retail dari PT. Enam Kubuku Indonesia yang toko buku daring yang menjual baik dalam bentuk transaksi penjualan maupun berlanggan buku-buku digital, majalah digital, koran digital, jurnal digital dan konten digital lainnya dalam format ebook, audiobook, videobook, dan buku fisik, majalah fisik, koran fisik dan jurnal fisik.
                                        <br/><br/>
                                        Sebagai toko buku, kami menjalin kerjasama resmi dengan penerbit dan atau pemilik lisensi konten-konten yang dapat di beli/langgan oleh pelanggan kami.
                                        <br/><br/>
                                        Konten yang sudah dibeli/langgan dapat dibaca melalui aplikasi Bookubuku yang merupakan alat baca konten digital dalam format ebook/audiobook/videobook.
                                        <br/><br/>
                                        
                                    </div>
                                </div>
                                
                            </div>

                        </div>
                    </div>

                }

            </div>
        );

    }
}

export default PrivacyPolicy;
