import { Component } from 'react';
import { clientSettings, executeGet, executePost } from '../functions/Kubuku';
import { PILIH_BUKU, CU_CONTENT,DELETE_CONTENT_KODE } from '../api';
import ModalLoading from "./ModalLoading";
import ModalPopup from "../components/ModalPopup";
import '../styles/animation-success.scss';

import Pagination from './Pagination';

import { connect } from 'react-redux';
import { bukuPromosiAction } from '../redux/actions';
import { SEARCH_BUKU } from '../api';
import { searchAction } from '../redux/actions';

let PageSize = 15;

class ModalPilihBuku extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            data: "",
            judul: "",
            deskripsi: "",
            isLoading: false,
            showMain: false,
            isModal: false,
            showModalPilihBuku: false,
            placeholder: 'Cari di ' + localStorage.getItem('namaToko')
        }
    }

    componentDidMount = async () => {
        this.getDataBuku();
    };

    currencyFormat(num) {
        return 'Rp. ' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    getDataBuku = async () => {
        try {
            const json = await executeGet(PILIH_BUKU + "/" + this.props.kodeEtalase);
            console.log("PILIH_BUKU" + this.props.kodeEtalase)
            console.log( "PILIH_BUKU = " + JSON.stringify(json) );
            if (json.code == 200) {
                this.setState({ isLoading: false, data: json.data, showMain: true });
            } else if (json.code == 404) {
                //masukkan tgl lahir
                // this.setState({ flagData: false, msg: json.msg });
            } else if (json.code == 500 || json.code == 505) {
                // window.location.href = window.location.origin + "/login";
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
    }

    currentTableData = () => {
        const firstPageIndex = (this.state.currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return this.state.data.slice(firstPageIndex, lastPageIndex);
    };

    addContent = async (param,e) => {
        if(this.props.kodeEtalase != 0) {
            if (e.target.checked==true) {
                console.log("addContent = " + JSON.stringify(param));
                //this.setState({ isLoading: true });
                try {
                    let formData = new FormData();
                    formData.append("kode_section", this.props.kodeEtalase);
                    formData.append("kode_konten", param.kode_konten);
                    formData.append("aktif", 1);
        
                    const json = await executePost(CU_CONTENT, formData);
                    console.log("tambah konten = " + JSON.stringify(json));
                    if (json.code == 200) {
                        //this.setState({isModal: true, msg: 'Konten berhasil ditambahkan ke etalase ini' });
                        //window.location.href = window.location.origin + "/profile/setting-etalase/" + this.props.kodeEtalase;
                    }
                } catch (error) {
                    //console.log(error);
                    this.setState({ isError: true, errorMessage: "Network Error!" });
                }
            } else {
                //hapus
                console.log(param);
                console.log("param del = " + JSON.stringify(param));
                //this.setState({ isLoading: true });
                try {
                    let formData = new FormData();
                    formData.append("id", param.kode_konten);
                    formData.append("kode_section", this.props.kodeEtalase);

                    const json = await executePost(DELETE_CONTENT_KODE, formData);
                    console.log("delete_konten = " + JSON.stringify(json));
                    if (json.code == 200) {
                        //this.setState({isModal: true, msg: 'Konten berhasil dikeluarkan dari etalase ini' });
                        
                    }
                } catch (error) {
                    //console.log(error);
                    this.setState({ isError: true, errorMessage: "Network Error!" });
                }
            }
        } else {
            //promosi
            this.props.bukuPromosiAction(param);
            this.props.close();
        }
        
    }
    
    _handleKeyDown = async (e) => {
        if (e.key === 'Enter') {
          console.log('do validate ' + this.state.keyword);
          try {
            let formData = new FormData();
            formData.append("page", 1);
            formData.append("row", 30);
            formData.append("kode_etalase",this.props.kodeEtalase);
            formData.append("keyword", this.state.keyword);
    
            const json = await executePost(SEARCH_BUKU, formData);
            console.log("SEARCH = " + JSON.stringify(json));
            if (json.code == 200) {
                this.setState({ isLoading: false, data: json.data, showMain: true });
    
            }
          } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
          }
        }
      }

    render() {
        return (
            <div>
                {
                    this.state.isLoading &&
                    <ModalLoading />
                }
                {
                    this.state.isModal &&
                    <ModalPopup
                        messages={this.state.msg}
                        press={() => { this.setState({ isModal: false }) }}
                        type="success"
                    />
                }
                {
                    this.state.showMain &&
                    <div>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-[80%] h-[90%] my-6 mx-auto">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-[100%]">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                        <div className="flex w-full">
                                            <h3 className="text-xl font-semibold dark:text-black w-[50%]">
                                                Pilih Buku
                                            </h3>
                                            <div className="relative text-gray-600 focus-within:text-gray-400 lg:w-8/12 xs:w-12/12">
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                                            <button type="submit" className="p-1 focus:outline-none focus:shadow-outline">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-[20px] w-[20px]" fill="none" viewBox="0 0 24 24" stroke="#E3E3E3">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                                </svg>
                                            </button>
                                            </span>

                                            <input type="search" name="search" onChange={event => this.setState({ keyword: event.target.value })} onKeyDown={this._handleKeyDown} className="py-3 text-sm text-gray-900 pr-4 bg-transparent border-solid border-grayWalang border rounded-full pl-10 focus:outline-none focus:bg-white focus:text-gray-900 w-full" placeholder={this.state.placeholder} autoComplete="on" />

                                        </div>
                                            <div className="w-[50%] flex justify-end items-end" onClick={this.props.close}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" onClick={() => this.setState({ showModal: false })}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="relative p-6 flex-auto overflow-y-auto">
                                        
                                        <div className='grid grid-cols-2 gap-x-1 gap-y-1  lg:gap-x-2 lg:gap-y-2 lg:grid-cols-5 lg:mx-2 px-4 lg:px-0 mt-1 space-x-2'>
                                        {
                                            this.currentTableData().map(item => {
                                                return (
                                                    <div className="bg-white relative">
                                                        <img className='w-full product-img' src={item.cover} alt="" />
                                                        <div className="flex items-center  pt-2">
                                                            <input onClick={(e) => { 
                                                                this.addContent(item,e); 
                                                            }} type="checkbox" value="" name="bordered-checkbox" className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"></input>
                                                            <label className="py-2 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300">Tambahkan</label>
                                                        </div>
                                                        
                                                        <div className="line-clamp-2 font-semibold">{item.judul}</div>
                                                        <div className="line-clamp-1 text-grayWalangDark text-sm pt-2">{item.penulis}</div>
                                                        <div className="line-clamp-1 text-grayWalangDark text-sm pt-2">{item.penerbit}</div>
                                                        <div className="text-lg font-semibold text-greenWalang">{this.currencyFormat(item.harga)}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                        </div>
                                        <div className="flex justify-center pt-8">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={this.state.currentPage}
                                                totalCount={this.state.data.length}
                                                pageSize={PageSize}
                                                onPageChange={
                                                    page => this.setState({ currentPage: page })
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </div>
                    
                }
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    bukuPromosi: state.bukuPromosi
});
  
export default connect(mapStateToProps, { bukuPromosiAction })(ModalPilihBuku);