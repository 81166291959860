import { Component } from 'react';

import { clientSettings, executeGet, executePost } from '../functions/Kubuku';
import { LIST_OUTLET, CUD_OUTLET } from '../api';

import QRCode from "react-qr-code";
import ModalSettings from '../components/ModalSettings';
import ModalLoading from "../components/ModalLoading";
import ModalPopup from "../components/ModalPopup";

import {
  useParams,
  useNavigate
} from "react-router-dom";

class ListOutlet extends Component {

  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      showMain: false,
      showModal: false,
      isLoading: false,
      showLoading: false,
      data: '',
      msg: ''
    }
  }

  componentDidMount() {
    // const script = document.createElement("script");
    // script.src = "https://unpkg.com/flowbite@1.4.5/dist/datepicker.js";
    // script.async = true;
    // script.onload = () => this.scriptLoaded();

    // document.body.appendChild(script);
    this.getOutlet();

    // const datepickerEl = document.getElementById('datepickerId');
    // new Datepicker(datepickerEl, {
    //     // options
    // }); 
  }

  scriptLoaded() {
    console.log("script load");
    // window.A.sort();
  }

  getOutlet = async () => {
    try {
      this.setState({
        isLoading:true
      })
      const json = await executeGet(LIST_OUTLET);
      console.log("outlet = " + JSON.stringify(json));
      if (json.code == 200) {
        this.setState({data: json.data, showMain: true });
      } else if (json.code == 404) {
        //masukkan tgl lahir
        // this.setState({ flagData: false, msg: json.msg });
        this.setState({isLoading:false})
      } else if (json.code == 500 || json.code == 505) {
        this.setState({isLoading:false})
        window.location.href = window.location.origin + "/login";
      }
    } catch (error) {
      //console.log(error);
      this.setState({ isError: true, errorMessage: "Network Error!" });
    }
  }

  openBeranda = async (kode) => {
    window.location.href = kode;
  }
  onShare = async (url) => {
    const title = document.title;

    let text = "Silahkan buka link di atas.";

    try {
      await navigator
        .share({
          title,
          url,
          text
        })

      /*
        Show a message if the user shares something
      */
      //alert(`Thanks for Sharing!`);
    } catch (err) {
      /*
         This error will appear if the user cancels the action of sharing.
       */
      alert(`Couldn't share ${err}`);
    }
  }

  render() {
    return (
      <div>
        {
          this.state.isLoading &&
          <ModalLoading />
        }
        {
          this.state.isModal &&
          <ModalPopup
            type="success"
            messages={this.state.msg}
            press={() => { this.setState({ isModal: false }) }}
          />
        }
        {
          this.state.showModal &&
          <ModalSettings
            tipe="add_outlet"
            close={() => { this.setState({ showModal: false }) }}
          />
        }
        {
          this.state.showMain &&
          <div className="dark:bg-white border rounded-2xl xl:p-[30px]">

            <div className="text-4xl font-semibold dark:text-black ml-4 mt-4">List Outlet</div>
            <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-1 gap-y-1  lg:gap-x-2 lg:gap-y-2 lg:grid-cols-4 lg:mx-2 px-4 lg:px-0 mt-1'>
              {this.state.data.map(item => {
                return (
                  <div className="rounded-[12px] shadow bg-white lg:p-6 p-2 relative">
                    <a href={window.location.origin + '/' + item.kode}>
                    <center>
                    <QRCode value={window.location.origin + '/' + item.kode} size="141" />
                    </center>
                    </a>
                    <div className="text-center py-3 cursor-pointer" onClick={() => this.openBeranda(window.location.origin + '/' + item.kode)}>{item.judul}</div>

                    <div className="grid grid-cols-1">
                      <div className='cursor-pointer' onClick={() => this.onShare(window.location.origin + '/' + item.kode)}>
                        <div className="justify-center items-center flex">
                          <svg className='h-6 w-6' viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M18.25 9.5C18.25 7.70507 19.7051 6.25 21.5 6.25C23.2949 6.25 24.75 7.70507 24.75 9.5C24.75 11.2949 23.2949 12.75 21.5 12.75C20.5404 12.75 19.6779 12.3341 19.083 11.6727L16.3657 13.1549L13.3251 14.8923C13.5955 15.3664 13.75 15.9152 13.75 16.5C13.75 16.7963 13.7103 17.0834 13.636 17.3562L19.083 20.3273C19.6779 19.6659 20.5404 19.25 21.5 19.25C23.2949 19.25 24.75 20.7051 24.75 22.5C24.75 24.2949 23.2949 25.75 21.5 25.75C19.7051 25.75 18.25 24.2949 18.25 22.5C18.25 22.2036 18.2897 21.9166 18.364 21.6438L12.917 18.6727C12.3221 19.3341 11.4596 19.75 10.5 19.75C8.70507 19.75 7.25 18.2949 7.25 16.5C7.25 14.7051 8.70507 13.25 10.5 13.25C11.1507 13.25 11.7567 13.4412 12.2649 13.7705L15.6343 11.8451L18.364 10.3562C18.2897 10.0834 18.25 9.79635 18.25 9.5ZM21.5 7.75C20.5335 7.75 19.75 8.5335 19.75 9.5C19.75 10.4665 20.5335 11.25 21.5 11.25C22.4665 11.25 23.25 10.4665 23.25 9.5C23.25 8.5335 22.4665 7.75 21.5 7.75ZM10.5 14.75C9.5335 14.75 8.75 15.5335 8.75 16.5C8.75 17.4665 9.5335 18.25 10.5 18.25C11.4665 18.25 12.25 17.4665 12.25 16.5C12.25 15.5335 11.4665 14.75 10.5 14.75ZM19.75 22.5C19.75 21.5335 20.5335 20.75 21.5 20.75C22.4665 20.75 23.25 21.5335 23.25 22.5C23.25 23.4665 22.4665 24.25 21.5 24.25C20.5335 24.25 19.75 23.4665 19.75 22.5Z" fill="#2BC239" />
                          </svg>
                        </div>


                        <div className="justify-center items-center flex">
                          <a className="text-black text-sm text-center text-[10px]">Share</a>
                        </div>

                      </div>

                    </div>
                  </div>
                )
              })}
              {this.setState({isLoading:false})}
            </div>

            {/* <form>
            <div className="mb-6">
              <label for="fullname" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Nama Lengkap</label>
              <input type="text" id="fullname" value={this.state.nama} onChange={event => this.setState({ nama : event.target.value})} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Masukkan Nama Lengkap" required />
            </div>
            <div className="mb-6">
              <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Email Akun</label>
              <input type="email" id="email" value={this.state.data.email} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Masukkan Email" required />
            </div>
            <div className="mb-6">
              <label for="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Kata Sandi</label>
              <input type="password" id="password" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" required />
            </div>
            <div className="mb-6">
              <label for="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">No. Handphone</label>
              <input type="text" id="phone" value={this.state.phone} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Masukkan Phone Number" required onChange={event => this.setState({ phone : event.target.value})} />
            </div>
            <div className="mb-6">
              <label for="birthdate" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Tanggal Lahir</label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path></svg>
                </div>
                <input type="date" value={this.state.tglLahir} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Select date" />
              </div>
            </div>
            <div className="flex justify-end">
              <button onClick={this.simpanProfile} type="button" className="w-4/12 bg-greenWalang font-medium rounded-full text-sm px-5 py-3 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2">
                <div className="text-[#ffffff]">
                  Simpan
                </div>
              </button>
            </div>
            
          </form>
         */}
          </div>

        }
      </div>
    );

  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <ListOutlet {...props} navigate={navigate} params={useParams()} />
}

export default WithNavigate;
