import React from 'react';
import { Routes, Route } from 'react-router-dom'
import Loader from '../components/Loader';
import Home from './Home';
import Header from '../components/Header';
import Details from './Details';
import Wishlist from './Wishlist';
import PublicVoucher from './PublicVoucher';
import Login from './Login';
import ResetPassword from './ResetPassword';
import Register from './Register';
import Categories from './Categories';
import CategorieDetail from './CategorieDetail';
import Search from './Search';
import Cart from './Cart';
import Payment from './Payment';
import Top1 from './Top1';
import Top2 from './Top2';
import Sidebar from '../components/Sidebar';
import DetailSection from './DetailSection';
import SidebarSetting from '../components/SidebarSetting';
import About from './About';
import PrivacyPolicy from './PrivacyPolicy';
import TermsCondition from './TermsCondition';
import ResetPasswordConfirmation from './ResetPasswordConfirmation';
import Faq from './Faq';
import Help from './Help';
import ProfileSidebar from '../components/ProfileSidebar';
import Promosi from './Promosi';
let brn = localStorage.getItem("beranda");
export default function index() {
  return(
      <Routes>
          
          <Route exact path="/profile/*" element={<Sidebar/>} ></Route>
          <Route exact path="/settings/*" element={<SidebarSetting/>} ></Route>
          
          <Route exact path="/:id/:section/:konten" element={<Details/>} />
          <Route exact path="/" element={<Home/>} />
          <Route exact path="/:id" element={<Home/>} />
          <Route exact path="/:id/:section" element={<DetailSection/>} />
          
          <Route exact path="/:id/flash/:konten" element={<Details/>} />
          <Route exact path="/etalaseDetails/:konten" element={<Details/>} />

          <Route exact path="/etalase/:section" element={<DetailSection/>} />
          <Route exact path=":id/promosi" element={<Top1/>} />
          <Route exact path=":id/highlight" element={<Top2/>} />
          
          <Route exact path="/:beranda/promosi/:id" element={<Promosi/>} />
          <Route exact path="/login" element={<Login/>} />
          <Route exact path="/register" element={<Register/>} />
          <Route exact path="/resetPassword" element={<ResetPassword/>} />
          <Route exact path="/resetPasswordConfirmation/:id" element={<ResetPasswordConfirmation/>} />
          <Route exact path="/details/:kode" element={<Details/>} />
          <Route exact path="/wishlist" element={<Wishlist/>} />
          <Route exact path="/publicVoucher" element={<PublicVoucher/>} />
          <Route exact path="/categories" forceRefresh={true} element={<Categories/>} />
          <Route exact path="/categorieDetail/:id" element={<CategorieDetail/>} />
          <Route exact path="/search" forceRefresh={true} element={<Search/>} />
          <Route exact path="/cart" element={<Cart/>} />
          <Route exact path="/payment" element={<Payment/>} />
          <Route exact path="/about" element={<About/>} />
          <Route exact path="/privacypolicy" element={<PrivacyPolicy/>} />
          <Route exact path="/termscondition" element={<TermsCondition/>} />
          <Route exact path="/faq" element={<Faq/>} />
          <Route exact path="/help" element={<Help/>} />
          <Route exact path="/profile-menu" element={<ProfileSidebar/>} />
      </Routes>
  )
}
