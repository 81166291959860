import { Component } from 'react';
import { createNewHeaders, updateHeaders, executeGet, executePost } from '../functions/Kubuku';
import { CUD_OUTLET, CUD_ETALASE, SHARE_KODE_DOWNLOAD,CU_VOUCHER, ADD_PROMOTION, CU_PROMOTION , LIST_SUPPLIER, LIST_OUTLET, LIST_ETALASE} from '../api';
import { DELETE_PROMOTION, DELETE_VOUCHER } from '../api';

import '../styles/animation-success.scss';
import ModalPilihBuku from './ModalPilihBuku';

import ModalLoading from "../components/ModalLoading";
import ModalPopup from "../components/ModalPopup";

import { connect } from 'react-redux';
import { bukuPromosiAction } from '../redux/actions';

class ModalSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: "",
            judul: "",
            deskripsi: "",
            email: "",
            showModalPilihBuku: false,
            isLoading: false,
            modalPopup: false,
            modalPopupPromosi: false,
            modalPopupVoucher: false,
            msg: '',
            typeModal: 'success',
            promosiOutlet: '',
            promosiPersen: 0,
            promosiSupplier: 0,
            promosiToko: 0,
            promosiPlatform: 0,
            promosiMaxRupiah: 0,
            promosiHargaAkhir: 0,
            promosiKuota: 0,
            promosiTglBerakhir: '',
            promosiTglMulai: '',
            promosiJenis: 0,
            kode_supplier: 0,
            kode_beranda: '',
            kode_section: '',
            diskon_maksimal: 0,
            kode_voucher:'',
            supplier: [],
            beranda:[{id:"",nama:"Pilih Outlet"}],
            section:[]
        }
    }

    componentDidMount = async () => {
        document.getElementById('idTglMulai').min = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0];
        document.getElementById('idTglAkhir').min = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0];
        console.log("pilihBuku redux = " + JSON.stringify(this.props.bukuPromosi));
        
       this.listBeranda();
        
        this.listSupplier();
        
    };

    addOutlet = async () => {
        try {
            console.log("jdudul = " + this.state.judul);
            console.log("deskripsi = " + this.state.deskripsi);
            let formData = new FormData();
            formData.append("judul", this.state.judul);
            formData.append("deskripsi", this.state.deskripsi);

            const json = await executePost(CUD_OUTLET, formData);
            console.log("add outlet = " + JSON.stringify(json));
            if (json.code == 200) {
                // return this.props.close;
                window.location.href = window.location.origin +"/settings";
            } else if (json.code == 500 || json.code == 505) {
                // window.location.href = window.location.origin +"/login";
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
    }

    listSupplier = async() => {
		try {
            let supplier;
            
            const json = await executeGet(LIST_SUPPLIER);
            console.log("list Supplier = " + JSON.stringify(json.data));
            if (json.code == 200) {
                supplier =  json.data;
                this.state.supplier = [];
                this.state.supplier.push({id:"0", nama:"Pilih Penerbit"});
                supplier.map((item, i) => {
                    this.state.supplier.push({id:item.id, nama:item.nama});
                }, this);
            } else if (json.code == 500 || json.code == 505) {
                return "";
            }
            

        }  catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
	}

    listBeranda = async() => {
		try {
            let beranda;
            
            const json = await executeGet(LIST_OUTLET);
            console.log("list Outlet = " + JSON.stringify(json.data));
            if (json.code == 200) {
                beranda =  json.data;
                let aberanda = [];
                aberanda.push({id:"", nama:"Semua Outlet"});
                beranda.map((item, i) => {
                    aberanda.push({id:item.kode, nama:item.judul});
                }, this);
                this.setState({beranda:aberanda});
            } else if (json.code == 500 || json.code == 505) {
                return "";
            }
        }  catch (error) {
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
	}

    listSection = async function(ev) {
		try {
            let section;
            if (ev!="") {
                const json = await executeGet(LIST_ETALASE+ev);
                console.log("list Outlet = " + JSON.stringify(json.data));
                if (json.code == 200) {
                    section =  json.data;
                    let asection = [];
                    asection.push({id:"", nama:"Semua Section"});
                    section.map((item, i) => {
                        asection.push({id:item.kode, nama:item.judul});
                    }, this);
                    this.setState({section:asection});
                } else if (json.code == 500 || json.code == 505) {
                    return "";
                }
            } else {
                let asection = [];
                asection.push({id:"", nama:"Semua Section"});
                this.setState({section:asection});
            }
        }  catch (error) {
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
	}

    addEtalase = async () => {
        try {
            
            console.log("jdudul = " + this.state.judul);
            console.log("deskripsi = " + this.state.deskripsi);
            let formData = new FormData();
            formData.append("judul", this.state.judul);
            formData.append("deskripsi", this.state.deskripsi);
            formData.append("kode_beranda", this.props.kodeBeranda);

            const json = await executePost(CUD_ETALASE, formData);
            console.log("add etalase = " + JSON.stringify(json));
            if (json.code == 200) {
                window.location.href = window.location.origin +"/profile/setting-outlet/"+this.props.kodeBeranda+"/"+this.props.namaBeranda;
            } else if (json.code == 500 || json.code == 505) {
                // window.location.href = window.location.origin +"/login";
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
    }

    sendEmail = async () => {
        this.setState({isLoading: true});
        try {
            console.log("email = " + this.state.email);
            console.log("kode_konten = " + this.props.kode_konten);
            console.log("order_id = " + this.props.order_id);
            let formData = new FormData();
            formData.append("email", this.state.email);
            formData.append("kode_konten", this.props.kode_konten);
            formData.append("order_id", this.props.order_id);

            const json = await executePost(SHARE_KODE_DOWNLOAD, formData);
            console.log("sendEmail = " + JSON.stringify(json));
            if (json.code == 200) {
                this.setState({isLoading: false, typeModal: 'success', modalPopup: true, msg: json.msg});
                // window.location.href = window.location.origin +"/settings/outelet/"+this.props.kodeBeranda+"/"+this.props.namaBeranda;
            } else if(json.code = 404) {
                this.setState({isLoading: false, typeModal: 'error', modalPopup: true, msg: json.msg});
            } else if (json.code == 500 || json.code == 505) {
                // window.location.href = window.location.origin +"/login";
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
    }

    changeJenisPromo = async (event) => {
        this.setState({ promosiJenis : event})
        console.log("promosiJenis = " + this.state.promosiJenis)
        this.setState({promosiToko: this.state.promosiPersen});
        this.setState({promosiSupplier: 0});
        this.setState({promosiPlatform: 0});
    }

    changeKodeSupplier = async (event) => {
        this.setState({ kode_supplier : event})
    }

    changeDiskonPersen = async (event) => {
        this.setState({ promosiPersen: event });
        //this.setState({promosiToko: event});
    }

    changeDiskonMaksimal = async (event) => {
        this.setState({ diskon_maksimal: event });
        //this.setState({promosiToko: event});
    }

    changeKodeBeranda = async (event) => {
        console.log("EVENT " + event);
        this.setState({ kode_beranda: event });
        console.log("BERANDA GANTI "+this.state.kode_beranda);
        this.listSection(event);
    }

    changeKodeSection = async (event) => {
        this.setState({ kode_section: event });
    }

    changeDiskonToko = async (event) => {
        this.setState({ promosiToko: event });
        let val = parseInt(event) + parseInt(this.state.promosiSupplier) + parseInt(this.state.promosiPlatform);
        this.setState({ promosiPersen: val });
    }

    changeDiskonSupplier = async (event) => {
        this.setState({ promosiSupplier: event });
        let val = parseInt(this.state.promosiToko) + parseInt(event) + parseInt(this.state.promosiPlatform);
        this.setState({ promosiPersen: val });
    }

    changeDiskonPlatform = async (event) => {
        this.setState({ promosiPlatform: event });
        let val = parseInt(this.state.promosiToko) + parseInt(this.state.promosiSupplier) + parseInt(event);
        this.setState({ promosiPersen: val });
    }

    addPromosi = async () => {
        console.log("submit");
        if(this.state.promosiPersen > 100) {
            let msg = "Diskon Persen tidak boleh melewati 100%";
            console.log(msg);
        } else if(this.state.promosiPersen < 0) {
            let msg = "Diskon Persen tidak boleh kurang dari 0%";
            console.log(msg);
        } else {
            this.addPromosiAction();
        }
    }

    addPromosiAction = async () => {
        this.setState({isLoading: true});
        try {
            console.log("jenis_promo = " + this.state.promosiJenis);
            console.log("judul_promo = " + this.state.judul);
            console.log("deskripsi_promo = " + this.state.deskripsi);
            console.log("diskon_persen = " + this.state.promosiPersen);
            console.log("tgl_mulai = " + this.state.promosiTglMulai);
            console.log("tgl_akhir = " + this.state.promosiTglBerakhir);
            console.log("kuota = " + this.state.promosiKuota);

            let formData = new FormData();
            
            formData.append("jenis_promo", this.state.promosiJenis);
            formData.append("judul", this.state.judul);
            formData.append("deskripsi", this.state.deskripsi);
            formData.append("diskon_persen", this.state.promosiPersen);
            formData.append("diskon_maksimal", this.state.diskon_maksimal);
            formData.append("tgl_mulai", this.state.promosiTglMulai);
            formData.append("tgl_akhir", this.state.promosiTglBerakhir);
            formData.append("aktif", 1);
            formData.append("kuota", this.state.promosiKuota);
            formData.append("kode_supplier", this.state.kode_supplier);
            formData.append("diskon_toko", this.state.promosiToko);
            formData.append("diskon_supplier", this.state.promosiSupplier);
            formData.append("diskon_platform", this.state.promosiPlatform);
            formData.append("kode_beranda", this.state.kode_beranda);
            formData.append("kode_section", this.state.kode_section);
            const json = await executePost(CU_PROMOTION, formData);
            console.log("CU_PROMOTION = " + JSON.stringify(json));
            if (json.code == 200) {
                this.setState({isLoading: false, typeModal: 'success', modalPopupPromosi: true, msg: json.msg});
                // window.location.href = window.location.origin +"/settings/outelet/"+this.props.kodeBeranda+"/"+this.props.namaBeranda;
            } else if(json.code = 404) {
                this.setState({isLoading: false, typeModal: 'error', modalPopupPromosi: true, msg: json.msg});
            } else if (json.code == 500 || json.code == 505) {
                // window.location.href = window.location.origin +"/login";
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
    }

    addVoucher = async () => {
        console.log("submit");
        if(this.state.promosiPersen > 100) {
            let msg = "Diskon Persen tidak boleh melewati 100%";
            console.log(msg);
        } else if(this.state.promosiPersen < 0) {
            let msg = "Diskon Persen tidak boleh kurang dari 0%";
            console.log(msg);
        } else {
            this.addVoucherAction();
        }
    }

    addVoucherAction = async () => {
        this.setState({isLoading: true});
        try {
            console.log("kode_voucher = " + this.state.kode_voucher);
            console.log("jenis_promo = " + this.state.promosiJenis);
            console.log("judul_promo = " + this.state.judul);
            console.log("deskripsi_promo = " + this.state.deskripsi);
            console.log("diskon_persen = " + this.state.promosiPersen);
            console.log("tgl_mulai = " + this.state.promosiTglMulai);
            console.log("tgl_akhir = " + this.state.promosiTglBerakhir);
            console.log("kuota = " + this.state.promosiKuota);

            let formData = new FormData();
            
            formData.append("jenis_promo", this.state.promosiJenis);
            formData.append("judul", this.state.judul);
            formData.append("deskripsi", this.state.deskripsi);
            formData.append("diskon_persen", this.state.promosiPersen);
            formData.append("diskon_maksimal", this.state.diskon_maksimal);
            formData.append("tgl_mulai", this.state.promosiTglMulai);
            formData.append("tgl_akhir", this.state.promosiTglBerakhir);
            formData.append("aktif", 1);
            formData.append("kode_voucher", this.state.kode_voucher);
            formData.append("kuota", this.state.promosiKuota);
            formData.append("kode_supplier", this.state.kode_supplier);
            formData.append("diskon_toko", this.state.promosiToko);
            formData.append("diskon_supplier", this.state.promosiSupplier);
            formData.append("diskon_platform", this.state.promosiPlatform);
            formData.append("kode_beranda", this.state.kode_beranda);
            formData.append("kode_section", this.state.kode_section);
            const json = await executePost(CU_VOUCHER, formData);
            console.log("CU_VOUCHER = " + JSON.stringify(json));
            if (json.code == 200) {
                this.setState({isLoading: false, typeModal: 'success', modalPopupVoucher: true, msg: json.msg});
                // window.location.href = window.location.origin +"/settings/outelet/"+this.props.kodeBeranda+"/"+this.props.namaBeranda;
            } else if(json.code = 404) {
                this.setState({isLoading: false, typeModal: 'error', modalPopupVoucher: true, msg: json.msg});
            } else if (json.code == 500 || json.code == 505) {
                // window.location.href = window.location.origin +"/login";
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
    }

    deleteVoucherAction = async () => {
        this.setState({isLoading: true});
        try {
            let formData = new FormData();
            
            formData.append("jenis_promo", this.state.promosiJenis);
            formData.append("judul", this.state.judul);
            formData.append("deskripsi", this.state.deskripsi);
            formData.append("diskon_persen", this.state.promosiPersen);
            formData.append("diskon_maksimal", this.state.diskon_maksimal);
            formData.append("tgl_mulai", this.state.promosiTglMulai);
            formData.append("tgl_akhir", this.state.promosiTglBerakhir);
            formData.append("aktif", 1);
            formData.append("kode_voucher", this.state.kode_voucher);
            formData.append("kuota", this.state.promosiKuota);
            formData.append("kode_supplier", this.state.kode_supplier);
            formData.append("diskon_toko", this.state.promosiToko);
            formData.append("diskon_supplier", this.state.promosiSupplier);
            formData.append("diskon_platform", this.state.promosiPlatform);
            formData.append("kode_beranda", this.state.kode_beranda);
            formData.append("kode_section", this.state.kode_section);
            const json = await executePost(CU_VOUCHER, formData);
            console.log("CU_VOUCHER = " + JSON.stringify(json));
            if (json.code == 200) {
                this.setState({isLoading: false, typeModal: 'success', modalPopupVoucher: true, msg: json.msg});
                // window.location.href = window.location.origin +"/settings/outelet/"+this.props.kodeBeranda+"/"+this.props.namaBeranda;
            } else if(json.code = 404) {
                this.setState({isLoading: false, typeModal: 'error', modalPopupVoucher: true, msg: json.msg});
            } else if (json.code == 500 || json.code == 505) {
                // window.location.href = window.location.origin +"/login";
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
    }

    render() {
        //this.listBeranda();
        let supplier  = this.state.supplier;
        let supplierList;
        try {
            supplierList = supplier.length > 0
                && supplier.map((item, i) => {
                return (
                    <option value={item.id}>{item.nama}</option>
                )
            }, this);
        }  catch (error) {
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
        console.log(this.props.tipe);
        
        return (
            <div>
                {
                    this.props.tipe === "add_outlet" &&
                    <div>
                        {/** */}
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative  w-[95%] xl:w-[40%] md:w-[40%] h-[50%] my-6 mx-auto">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-[100%]">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                        <div className="flex w-full">
                                            <h3 className="text-xl font-semibold dark:text-black w-[70%]">
                                                Tambah List Outlet
                                            </h3>
                                            <div className="w-[30%] flex justify-end items-end" onClick={this.props.close}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" onClick={() => this.setState({ showModal: false })}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="relative p-6 flex-auto">
                                        <form>
                                            <div className="mb-6">
                                                <label for="judul" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Nama Outlet</label>
                                                <input type="text" id="judul" value={this.state.judul} onChange={event => this.setState({ judul : event.target.value})} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Masukkan Nama Outlet" required />
                                            </div>
                                            <div className="mb-6">
                                                <label for="deskripsi" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Deskripsi Outlet</label>
                                                <input type="text" id="deskripsi" value={this.state.deskripsi} onChange={event => this.setState({ deskripsi : event.target.value})} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Masukkan Deskripsi Outlet" required />
                                            </div>
                                            {/* <div className="mb-6">
                                                <label for="deskripsi" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Deskripsi Outlet</label>
                                                <input type="text" id="deskripsi" value={this.state.deskipsi} onChange={event => this.setState({ deskripsi : event.target.value})} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Masukkan Deskripsi Outlet" required />
                                            </div> */}
                                        </form>
                                        <div className="flex justify-end items-end">
                                            <button onClick={this.addOutlet} type="button" className="w-[40%] md:w-[30%] bg-greenWalang font-medium rounded-full text-sm px-3 py-2 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                                <div className="text-[#ffffff] text-[20px] py-2">
                                                    Simpan
                                                </div>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </div>
                }

                {
                    this.props.tipe === "add_etalase" &&
                    <div>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative  w-[95%] xl:w-[40%] h-[50%] my-6 mx-auto">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-[100%]">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                        <div className="flex w-full">
                                            <h3 className="text-xl font-semibold dark:text-black w-[50%]">
                                                Tambah List Etalase
                                            </h3>
                                            <div className="w-[50%] flex justify-end items-end" onClick={this.props.close}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" onClick={() => this.setState({ showModal: false })}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="relative p-6 flex-auto">
                                        <form>
                                            <div className="mb-6">
                                                <label for="judul" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Nama Etalase</label>
                                                <input type="text" id="judul" value={this.state.judul} onChange={event => this.setState({ judul : event.target.value})} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Masukkan Nama Etalase" required />
                                            </div>
                                            <div className="mb-6">
                                                <label for="deskripsi" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Deskripsi Etalase</label>
                                                <input type="text" id="deskripsi" value={this.state.deskripsi} onChange={event => this.setState({ deskripsi : event.target.value})} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Masukkan Deskripsi Etalase" required />
                                            </div>
                                            {/* <div className="mb-6">
                                                <label for="deskripsi" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Deskripsi Outlet</label>
                                                <input type="text" id="deskripsi" value={this.state.deskipsi} onChange={event => this.setState({ deskripsi : event.target.value})} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Masukkan Deskripsi Outlet" required />
                                            </div> */}
                                        </form>
                                        <div className="flex justify-end items-end">
                                            <button onClick={this.addEtalase} type="button" className="w-[30%] bg-greenWalang font-medium rounded-full text-sm px-3 py-2 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                                <div className="text-[#ffffff] text-[20px] py-2">
                                                    Simpan
                                        </div>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </div>
                }

                {
                    this.props.tipe === "share_kode" &&
                    <div>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative md:w-[95%] lg:w-[40%] xl:w-[40%] h-[50%] my-6 mx-auto">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-[100%]">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                        <div className="flex w-full">
                                            <h3 className="text-xl font-semibold dark:text-black w-[50%]">
                                                Bagikan Kode Klaim Buku
                                            </h3>
                                            <div className="w-[50%] flex justify-end items-end" onClick={this.props.close}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" onClick={() => this.setState({ showModal: false })}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="relative p-6 flex-auto">
                                        <form>
                                            <div className="mb-2">
                                                <label for="email" className="block mb-2 text-sm font-medium text-grayWalangDark dark:text-gray-800">Masukkan Email Penerima :</label>
                                                <input type="email" id="email" onChange={event => this.setState({ email: event.target.value })} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@gmail.com" required />
                                            </div>
                                            <div className="text-xs">Kami tidak bertanggung jawab atas kesalahan saat mengisi alamat email penerima buku dari kode klaim yang ingin dibagikan. Pastikan email penerima sudah benar!!</div>
                                        </form>
                                        <div className="flex justify-end items-end mt-3">
                                            <button onClick={this.sendEmail} type="button" className="w-[30%] bg-greenWalang font-medium rounded-full text-sm px-2 py-1.5 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                                <div className="text-white text-[18px] py-1">
                                                    Kirim
                                                </div>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </div>
                }

                {
                    this.props.tipe === "add_promosi" &&
                    <div>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-[95%] xl:w-[40%] h-[90%] my-6 mx-auto">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-[100%]">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                        <div className="flex w-full">
                                            <h3 className="text-xl font-semibold dark:text-black w-[50%]">
                                                Setting Promosi
                                            </h3>
                                            <div className="w-[50%] flex justify-end items-end" onClick={this.props.close}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" onClick={() => this.setState({ showModal: false })}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="relative p-6 flex-auto overflow-y-auto">
                                        <form onSubmit={this.addPromosi}>
                                            
                                            <div className="mb-6">
                                                <label for="jenis" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Berlaku untuk Outlet</label>
                                                
                                                <select value={this.state.kode_beranda} onChange={event => this.changeKodeBeranda(event.target.value)}  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light pr-10" placeholder="Single" required>
                                                    {
                                                        this.state.beranda.map((item, i) => {
                                                            return (
                                                                <option value={item.id}>{item.nama}</option>
                                                            )
                                                        }, this)
                                                    }
                                                </select>
                                            </div>
                                            
                                            <div className="mb-6">
                                                <label for="jenis" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Pilih Section</label>
                                                
                                                <select value={this.state.kode_section} onChange={event => this.changeKodeSection(event.target.value)}  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light pr-10" placeholder="Single" required>
                                                    {
                                                        this.state.section.map((item, i) => {
                                                            return (
                                                                <option value={item.id}>{item.nama}</option>
                                                            )
                                                        }, this)
                                                    }
                                                </select>
                                            </div>

                                            <div className="mb-6">
                                                <label for="jenis" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Jenis Promosi</label>
                                                {/* <input type="text" id="jenis" value={this.state.promosiJenis} onChange={event => this.setState({ promosiJenis : event.target.value})} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Single" required /> */}
                                                {/* this.setState({ promosiJenis : event.target.value}) */}
                                                {/* console.log("promosiJenis = " + this.state.promosiJenis) */}
                                                <select value={this.state.promosiJenis} onChange={event => this.changeJenisPromo(event.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light pr-10" placeholder="Single" required>
                                                    
                                                    <option value="0">Sendiri</option>
                                                    <option value="1">Gabungan</option>
                                                </select>
                                            </div>
                                            {
                                                this.state.promosiJenis == 1 && 
                                                <div className="mb-6">
                                                    <label for="jenis" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Pilih Penerbit</label>
                                                    
                                                    <select value={this.state.kode_supplier} onChange={event => this.changeKodeSupplier(event.target.value)}  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light pr-10" placeholder="Single" required>
                                                        {
                                                            this.state.supplier.map((item, i) => {
                                                                return (
                                                                    <option value={item.id}>{item.nama}</option>
                                                                )
                                                            }, this)
                                                        }
                                                    </select>
                                                </div>
                                            }
                                            <div className="mb-6">
                                                <label for="judul" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Judul Promo</label>
                                                <input type="text" id="judul" value={this.state.judul} onChange={event => this.setState({ judul : event.target.value})} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Contoh : Flash Sale" required />
                                            </div>
                                            <div className="mb-6">
                                                <label for="deskripsi" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Deskripsi Promo</label>
                                                <input type="text" id="deskripsi" value={this.state.deskripsi} onChange={event => this.setState({ deskripsi : event.target.value})} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Contoh : Promo Bulan Juni" required />
                                            </div>
                                            {/* <div className="mb-6">
                                                <label for="outlet" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Pilih Outlet <a className="text-gray-400 text-xs">(opsional)</a></label>
                                                <input type="text" id="outlet" value={this.state.promosiOutlet} onChange={event => this.setState({ promosiOutlet : event.target.value})} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Semua" />
                                            </div>
                                            <div className="mb-6">
                                                <label for="section" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Pilih Section <a className="text-gray-400 text-xs">(opsional)</a></label>
                                                <input type="text" id="section" value={this.state.promosiSection} onChange={event => this.setState({ promosiSection : event.target.value})} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Semua" />
                                            </div>
                                            <div className="mb-6">
                                                <label for="kode_konten" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Kode Konten</label>
                                                <div className="relative">
                                                    <input type="text" value={this.props.bukuPromosi.kode_konten} readonly="readonly" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Kode Konten" />
                                                    <div onClick={() => { this.setState({ showModalPilihBuku: true }) }} className="text-white absolute right-2 bottom-1.5 bg-greenWalang hover:bg-greenWalang hover:bg-opacity-80 rounded-full text-sm px-3 py-1.5 cursor-pointer">Pilih Buku</div>
                                                </div>
                                            </div> */}
                                            { this.props.bukuPromosi != "NULL" &&
                                            <div className="mb-6">
                                                <label for="kode_konten" className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-800">Preview Buku</label>
                                                <div className="px-1 flex">
                                                    <div className="w-[30%]">
                                                    <div className="w-[166px] h-[233px] max-w-xs overflow-hidden rounded-2xl shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                                                        <img className='w-full' src={this.props.bukuPromosi.cover} alt="" />
                                                    </div>
                                                    </div>
                                                    <div className="w-[70%]">
                                                    <div className="ml-6">
                                                        <div className="text-base font-semibold mt-4 dark:text-black line-clamp-2">{this.props.bukuPromosi.judul}</div>
                                                        <div className="text-xs font-semibold mt-1 text-[#999999] line-clamp-1">{this.props.bukuPromosi.penulis}</div>
                                                        <div className="flex text-center items-center">
                                                            <div className="text-lg font-semibold mt-1 text-greenWalang">Rp {this.props.bukuPromosi.harga}</div>
                                                        </div>
                                                    </div>

                                                    </div>

                                                </div>
                                            </div>
                                            }
                                            <div className="mb-6">
                                                <label for="persen" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Diskon Persen</label>
                                                <input type="number" pattern="[0-9.]+" id="persen" min="0" max="100" value={this.state.promosiPersen} onChange={event => this.changeDiskonPersen(event.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Contoh : 10%" required />
                                                {/* let prosesCount = this.state.promosiPersen */}
                                            </div>
                                            <div className="mb-6">
                                                <label for="maksimal" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Diskon Maksimal Rp.</label>
                                                <input type="number" pattern="[0-9.]+" id="persen" min="0" max="1000000" value={this.state.diskon_maksimal} onChange={event => this.changeDiskonMaksimal(event.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Contoh : 10%" required />
                                                <small>Isilah dengan 0 rupiah apabila diskon yang berlaku adalah sesuai dengan hitungan % tanpa ada batas maksimalnya</small>
                                                {/* let prosesCount = this.state.promosiPersen */}
                                            </div>
                                            {
                                                this.state.promosiJenis == 1 && 
                                                <div className="grid grid-cols-3 gap-4 mb-6">
                                                    <div className="">
                                                        <label for="persen" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Diskon Toko</label>
                                                        <input type="number" pattern="[0-9.]+" id="persen" min="0" max="100" value={this.state.promosiToko} onChange={event => this.changeDiskonToko(event.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Contoh : 10%" required />
                                                    </div>
                                                    <div className="">
                                                        <label for="persen" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Diskon Supplier</label>
                                                        <input type="number" pattern="[0-9.]+" id="persen" min="0" max="100" value={this.state.promosiSupplier} onChange={event => this.changeDiskonSupplier(event.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Contoh : 10%" required />
                                                    </div>
                                                    <div className="">
                                                        <label for="persen" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Diskon Platform</label>
                                                        <input type="number" pattern="[0-9.]+" id="persen" min="0" max="100" value={this.state.promosiPlatform} onChange={event => this.changeDiskonPlatform(event.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Contoh : 10%" required />
                                                    </div>
                                                </div>
                                            }
                                            {/* <div className="mb-6">
                                                <label for="harga_akhir" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Harga Akhir</label>
                                                <input type="text" readonly="readonly" id="harga_akhir" value={this.state.promosiHargaAkhir} onChange={event => this.setState({ promosiHargaAkhir : event.target.value})} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Contoh : 10%" required />
                                            </div> */}
                                            <div className="flex w-full space-x-3">
                                                <div className="mb-6 w-6/12">
                                                    <label for="tgl_mulai" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Tanggal Mulai</label>
                                                    <input type="date" id="idTglMulai" onChange={event => this.setState({ promosiTglMulai: event.target.value })} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Select date" required/>
                                                </div>
                                                <div className="mb-6 w-6/12">
                                                    <label for="tgl_berakhir" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Tanggal Berakhir</label>
                                                    <input type="date" id="idTglAkhir" onChange={event => this.setState({ promosiTglBerakhir: event.target.value })}className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Select date" required/>
                                                </div>
                                            </div>
                                            <div className="mb-6">
                                                <label for="kuota" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Kuota</label>
                                                <input type="text" id="kuota" value={this.state.promosiKuota} onChange={event => this.setState({ promosiKuota : event.target.value})} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Contoh : 10" required />
                                            </div>
                                        </form>
                                        <div className="flex justify-end items-end">
                                            <button onClick={this.addPromosi} type="button" className="w-[30%] bg-greenWalang font-medium rounded-full text-sm px-2.5 py-3 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                                <div className="text-[#ffffff] text-[16px]">
                                                    Simpan
                                                </div>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </div>
                }
                {
                    this.props.tipe === "add_voucher" &&
                    <div>
                        
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-[95%] xl:w-[40%] h-[90%] my-6 mx-auto">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-[100%]">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                        <div className="flex w-full">
                                            <h3 className="text-xl font-semibold dark:text-black w-[50%]">
                                                Setting Kode Voucher
                                            </h3>
                                            <div className="w-[50%] flex justify-end items-end" onClick={this.props.close}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" onClick={() => this.setState({ showModal: false })}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="relative p-6 flex-auto overflow-y-auto">
                                        <form onSubmit={this.addVoucher}>
                                            <div className="mb-6">
                                                <label for="kode_voucher" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Kode Voucher</label>
                                                <input type="text" id="kode_voucher" value={this.state.kode_voucher} onChange={event => this.setState({ kode_voucher : event.target.value})} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Isi atau kosongkan utk auto generate" required />
                                            </div>
                                            <div className="mb-6">
                                                <label for="jenis" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Berlaku untuk Outlet</label>
                                                
                                                <select value={this.state.kode_beranda} onChange={event => this.changeKodeBeranda(event.target.value)}  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light pr-10" placeholder="Single" required>
                                                    {
                                                        this.state.beranda.map((item, i) => {
                                                            return (
                                                                <option value={item.id}>{item.nama}</option>
                                                            )
                                                        }, this)
                                                    }
                                                </select>
                                            </div>
                                            
                                            <div className="mb-6">
                                                <label for="jenis" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Pilih Section</label>
                                                
                                                <select value={this.state.kode_section} onChange={event => this.changeKodeSection(event.target.value)}  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light pr-10" placeholder="Single" required>
                                                    {
                                                        this.state.section.map((item, i) => {
                                                            return (
                                                                <option value={item.id}>{item.nama}</option>
                                                            )
                                                        }, this)
                                                    }
                                                </select>
                                            </div>

                                            <div className="mb-6">
                                                <label for="jenis" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Jenis Promosi</label>
                                                {/* <input type="text" id="jenis" value={this.state.promosiJenis} onChange={event => this.setState({ promosiJenis : event.target.value})} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Single" required /> */}
                                                {/* this.setState({ promosiJenis : event.target.value}) */}
                                                {/* console.log("promosiJenis = " + this.state.promosiJenis) */}
                                                <select value={this.state.promosiJenis} onChange={event => this.changeJenisPromo(event.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light pr-10" placeholder="Single" required>
                                                    
                                                    <option value="0">Sendiri</option>
                                                    <option value="1">Gabungan</option>
                                                </select>
                                            </div>
                                            {
                                                this.state.promosiJenis == 1 && 
                                                <div className="mb-6">
                                                    <label for="jenis" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Pilih Penerbit</label>
                                                    
                                                    <select value={this.state.kode_supplier} onChange={event => this.changeKodeSupplier(event.target.value)}  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light pr-10" placeholder="Single" required>
                                                        {
                                                            this.state.supplier.map((item, i) => {
                                                                return (
                                                                    <option value={item.id}>{item.nama}</option>
                                                                )
                                                            }, this)
                                                        }
                                                    </select>
                                                </div>
                                            }
                                            <div className="mb-6">
                                                <label for="judul" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Judul Promo</label>
                                                <input type="text" id="judul" value={this.state.judul} onChange={event => this.setState({ judul : event.target.value})} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Contoh : Flash Sale" required />
                                            </div>
                                            <div className="mb-6">
                                                <label for="deskripsi" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Deskripsi Promo</label>
                                                <input type="text" id="deskripsi" value={this.state.deskripsi} onChange={event => this.setState({ deskripsi : event.target.value})} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Contoh : Promo Bulan Juni" required />
                                            </div>
                                            {/* <div className="mb-6">
                                                <label for="outlet" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Pilih Outlet <a className="text-gray-400 text-xs">(opsional)</a></label>
                                                <input type="text" id="outlet" value={this.state.promosiOutlet} onChange={event => this.setState({ promosiOutlet : event.target.value})} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Semua" />
                                            </div>
                                            <div className="mb-6">
                                                <label for="section" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Pilih Section <a className="text-gray-400 text-xs">(opsional)</a></label>
                                                <input type="text" id="section" value={this.state.promosiSection} onChange={event => this.setState({ promosiSection : event.target.value})} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Semua" />
                                            </div>
                                            <div className="mb-6">
                                                <label for="kode_konten" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Kode Konten</label>
                                                <div className="relative">
                                                    <input type="text" value={this.props.bukuPromosi.kode_konten} readonly="readonly" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Kode Konten" />
                                                    <div onClick={() => { this.setState({ showModalPilihBuku: true }) }} className="text-white absolute right-2 bottom-1.5 bg-greenWalang hover:bg-greenWalang hover:bg-opacity-80 rounded-full text-sm px-3 py-1.5 cursor-pointer">Pilih Buku</div>
                                                </div>
                                            </div> */}
                                            { this.props.bukuPromosi != "NULL" &&
                                            <div className="mb-6">
                                                <label for="kode_konten" className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-800">Preview Buku</label>
                                                <div className="px-1 flex">
                                                    <div className="w-[30%]">
                                                    <div className="w-[166px] h-[233px] max-w-xs overflow-hidden rounded-2xl shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                                                        <img className='w-full' src={this.props.bukuPromosi.cover} alt="" />
                                                    </div>
                                                    </div>
                                                    <div className="w-[70%]">
                                                    <div className="ml-6">
                                                        <div className="text-base font-semibold mt-4 dark:text-black line-clamp-2">{this.props.bukuPromosi.judul}</div>
                                                        <div className="text-xs font-semibold mt-1 text-[#999999] line-clamp-1">{this.props.bukuPromosi.penulis}</div>
                                                        <div className="flex text-center items-center">
                                                            <div className="text-lg font-semibold mt-1 text-greenWalang">Rp {this.props.bukuPromosi.harga}</div>
                                                        </div>
                                                    </div>

                                                    </div>

                                                </div>
                                            </div>
                                            }
                                            <div className="mb-6">
                                                <label for="persen" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Diskon Persen</label>
                                                <input type="number" pattern="[0-9.]+" id="persen" min="0" max="100" value={this.state.promosiPersen} onChange={event => this.changeDiskonPersen(event.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Contoh : 10%" required />
                                                {/* let prosesCount = this.state.promosiPersen */}
                                            </div>
                                            <div className="mb-6">
                                                <label for="maksimal" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Diskon Maksimal Rp.</label>
                                                <input type="number" pattern="[0-9.]+" id="persen" min="0" max="1000000" value={this.state.diskon_maksimal} onChange={event => this.changeDiskonMaksimal(event.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Contoh : 10%" required />
                                                <small>Isilah dengan 0 rupiah apabila diskon yang berlaku adalah sesuai dengan hitungan % tanpa ada batas maksimalnya</small>
                                                {/* let prosesCount = this.state.promosiPersen */}
                                            </div>
                                            {
                                                this.state.promosiJenis == 1 && 
                                                <div className="grid grid-cols-3 gap-4 mb-6">
                                                    <div className="">
                                                        <label for="persen" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Diskon Toko</label>
                                                        <input type="number" pattern="[0-9.]+" id="persen" min="0" max="100" value={this.state.promosiToko} onChange={event => this.changeDiskonToko(event.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Contoh : 10%" required />
                                                    </div>
                                                    <div className="">
                                                        <label for="persen" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Diskon Supplier</label>
                                                        <input type="number" pattern="[0-9.]+" id="persen" min="0" max="100" value={this.state.promosiSupplier} onChange={event => this.changeDiskonSupplier(event.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Contoh : 10%" required />
                                                    </div>
                                                    <div className="">
                                                        <label for="persen" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Diskon Platform</label>
                                                        <input type="number" pattern="[0-9.]+" id="persen" min="0" max="100" value={this.state.promosiPlatform} onChange={event => this.changeDiskonPlatform(event.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Contoh : 10%" required />
                                                    </div>
                                                </div>
                                            }
                                            {/* <div className="mb-6">
                                                <label for="harga_akhir" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Harga Akhir</label>
                                                <input type="text" readonly="readonly" id="harga_akhir" value={this.state.promosiHargaAkhir} onChange={event => this.setState({ promosiHargaAkhir : event.target.value})} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Contoh : 10%" required />
                                            </div> */}
                                            <div className="flex w-full space-x-3">
                                                <div className="mb-6 w-6/12">
                                                    <label for="tgl_mulai" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Tanggal Mulai</label>
                                                    <input type="date" id="idTglMulai" onChange={event => this.setState({ promosiTglMulai: event.target.value })} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Select date" required/>
                                                </div>
                                                <div className="mb-6 w-6/12">
                                                    <label for="tgl_berakhir" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Tanggal Berakhir</label>
                                                    <input type="date" id="idTglAkhir" onChange={event => this.setState({ promosiTglBerakhir: event.target.value })}className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Select date" required/>
                                                </div>
                                            </div>
                                            <div className="mb-6">
                                                <label for="kuota" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Kuota</label>
                                                <input type="text" id="kuota" value={this.state.promosiKuota} onChange={event => this.setState({ promosiKuota : event.target.value})} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Contoh : 10" required />
                                            </div>
                                        </form>
                                        <div className="flex justify-end items-end">
                                            <button onClick={this.addVoucher} type="button" className="w-[30%] bg-greenWalang font-medium rounded-full text-sm px-2.5 py-3 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                                <div className="text-[#ffffff] text-[16px]">
                                                    Simpan
                                                </div>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </div>
                }
                {
                    this.state.showModalPilihBuku &&
                    <ModalPilihBuku
                        kodeEtalase="0"
                        close={() => {
                            this.setState({ showModalPilihBuku: false });
                            // this.getEtalase();
                        }}
                    />
                }
                {
                    this.state.isLoading &&
                    <ModalLoading />
                }
                {
                    this.state.modalPopup &&
                    <ModalPopup
                        type={this.state.typeModal}
                        messages={this.state.msg}
                        press={() => { 
                            this.setState({ modalPopup: false });  
                            window.location.href = window.location.origin + "/profile/transaksi";
                        }}
                    />
                }
                {
                    this.state.modalPopupPromosi &&
                    <ModalPopup
                        type={this.state.typeModal}
                        messages={this.state.msg}
                        press={() => { 
                            this.setState({ modalPopupPromosi: false });  
                            if(this.state.typeModal == "success") {
                                window.location.href = window.location.origin + "/profile/setting-promotion";
                            }
                        }}
                    />
                }
                {
                    this.state.modalPopupVoucher &&
                    <ModalPopup
                        type={this.state.typeModal}
                        messages={this.state.msg}
                        press={() => { 
                            this.setState({ modalPopupVoucher: false });  
                            if(this.state.typeModal == "success") {
                                window.location.href = window.location.origin + "/profile/setting-voucher";
                            }
                        }}
                    />
                }
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    bukuPromosi: state.bukuPromosi
});
  
export default connect(mapStateToProps, { bukuPromosiAction })(ModalSettings);