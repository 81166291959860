//--- TOKO KUBUKU ---//
const BASE_URL = 'https://api.bookubuku.id/bukubuku/';
//const BASE_URL = 'https://kubuku.my.id/api/bukubuku/';
const KONFIGURASI = BASE_URL + 'konfigurasi';
const BERANDA_UTAMA = BASE_URL + 'berandaUtama';
const BERANDA = BASE_URL + 'beranda/';
const DETAIL_KONTEN = BASE_URL + 'detailKonten/';
const DETAIL_PROMOSI = BASE_URL + 'detailPromosi/';
const GET_ETALASE_DETAIL = BASE_URL + 'etalaseDetails/';
const GET_ETALASE = BASE_URL + "etalase/";
const DETAIL_KONTEN_VALID = BASE_URL + 'detailKontenValid';
const GET_CART = BASE_URL + 'totalKeranjang';
const ADD_CART = BASE_URL + 'setKeranjang';
const DELETE_CART = BASE_URL + 'hapusKeranjang';
const PAYMENT_METHOD = BASE_URL + 'getPaymentMethod';
const CHECKOUT = BASE_URL + 'checkout';
const CHECKOUT_ULANG = BASE_URL + 'checkoutUlang';
const PROFILE = BASE_URL + 'profil';
const UPDATE_PROFILE = BASE_URL + 'updateProfil';
const UPDATE_PASSWORD = BASE_URL + 'setPassword';
const DAFTAR_TRANSAKSI = BASE_URL + 'daftarTransaksi';
const ADD_WISHLIST = BASE_URL + 'setWishlist';
const GET_WISHLIST = BASE_URL + 'getWishlist';
const DELETE_WISHLIST = BASE_URL + 'hapusWishlist';
const LOGIN = BASE_URL + 'login';
const REGISTER = BASE_URL + 'register';
const RESET_PASSWORD = BASE_URL + 'resetPassword';
const SEARCH_ALL = BASE_URL + "pencarianUmum";
const SEARCH_BUKU = BASE_URL + "pencarianPilihBuku";
const LOGOUT = BASE_URL + "logout";
const KATEGORI = BASE_URL + "kategori";
const PREVIEW_CONTENT = BASE_URL + "previewKonten/";
const GET_TOP1 = BASE_URL + "top1";
const GET_TOP2 = BASE_URL + "top2";
const SHARE_KODE_DOWNLOAD = BASE_URL + "shareKodeDownload";
const SET_RATING = BASE_URL + "setRating";
const VALIDASI_VOUCHER = BASE_URL + "validasiVoucher";
const GET_MORECONTEN = BASE_URL + "getMoreConten";
const CONFIRM_RESETPASSWORD = BASE_URL + "confirmResetPassword";

// settings
const LIST_OUTLET = BASE_URL + "listOutlet";
const LIST_CONTENT_TOKO = BASE_URL + "listTokoKonten";
const DELETE_CONTENT_TOKO = BASE_URL + "removeTokoKonten";
const ADD_CONTENT_TOKO = BASE_URL + "addTokoKonten";
const CHOOSE_CONTENT = BASE_URL + "chooseTokoKonten";
const CUD_OUTLET = BASE_URL + "CUDOutlet";
const LIST_ETALASE = BASE_URL + "listEtalase/";
const ADD_BANNER = BASE_URL + "setBanner";
const CUD_ETALASE = BASE_URL + "CUDEtalase";
const LIST_PROMOTION = BASE_URL + "listPromotion";
const ADD_PROMOTION = BASE_URL + "addPromotion";
const PILIH_BUKU = BASE_URL + "chooseContent";
const CARI_BUKU = BASE_URL + "findContent";
const LIST_CONTENT = BASE_URL + "listContent/";
const CU_CONTENT = BASE_URL + "CUContent";
const DELETE_CONTENT = BASE_URL + "deleteContent";
const DELETE_CONTENT_KODE = BASE_URL + "deleteContentKode";
const LIST_KATEOGRI = BASE_URL + "listKategori";
const LIST_SUPPLIER = BASE_URL + "listSupplier";
const CU_PROMOTION = BASE_URL + "CUPromotion";
const DELETE_PROMOTION = BASE_URL + "deletePromotion";
const UPDATE_PROMOTION = BASE_URL + "updatePromotion";
const LIST_VOUCHER = BASE_URL + "listVoucher";
const LIST_PUBLICVOUCHER = BASE_URL + "listPublicVoucher";
const CU_VOUCHER = BASE_URL + "CUVoucher";
const UPDATE_VOUCHER = BASE_URL + "updateVoucher";
const DELETE_VOUCHER = BASE_URL + "deleteVoucher";
const LAPORAN_TRANSAKSI = BASE_URL + "lapPenjualan";
const UPDATE_POSISI = BASE_URL + "updatePosisi";
const UPGRADE_PENGGUNA = BASE_URL + "upgradeUser";
const CHECK_EMAIL = BASE_URL + "checkEmail";
const LIST_ADMIN = BASE_URL + "listAdmin";

export {
    LOGIN,
    REGISTER,
    KONFIGURASI, 
    BERANDA_UTAMA,
    BERANDA,
    DETAIL_KONTEN,
    DETAIL_KONTEN_VALID,
    GET_CART,
    ADD_CART,
    DELETE_CART,
    PAYMENT_METHOD,
    CHECKOUT,
    PROFILE,
    UPDATE_PROFILE,
    DAFTAR_TRANSAKSI,
    ADD_WISHLIST,
    GET_WISHLIST,
    DELETE_WISHLIST,
    SEARCH_ALL,
    LIST_OUTLET,
    CUD_OUTLET,
    GET_ETALASE,
    GET_ETALASE_DETAIL,
    LOGOUT,
    PREVIEW_CONTENT,
    KATEGORI,
    GET_TOP1,
    GET_TOP2,
    LIST_ETALASE,
    ADD_BANNER,
    CUD_ETALASE,
    LIST_PROMOTION,
    PILIH_BUKU,
    LIST_CONTENT,
    CU_CONTENT,
    DELETE_CONTENT,
    DELETE_CONTENT_KODE,
    SHARE_KODE_DOWNLOAD,
    UPDATE_PASSWORD,
    SET_RATING,
    RESET_PASSWORD,
    VALIDASI_VOUCHER,
    ADD_PROMOTION,
    LIST_KATEOGRI,
    CU_PROMOTION,
    DELETE_PROMOTION,
    UPDATE_PROMOTION,
    SEARCH_BUKU,
    LIST_SUPPLIER,
    LIST_VOUCHER,
    CU_VOUCHER,
    UPDATE_VOUCHER,
    DELETE_VOUCHER,
    LAPORAN_TRANSAKSI,
    LIST_PUBLICVOUCHER,
    CHECKOUT_ULANG,
    UPDATE_POSISI,
    GET_MORECONTEN,
    DETAIL_PROMOSI,
    CONFIRM_RESETPASSWORD,
    CARI_BUKU,
    LIST_CONTENT_TOKO,
    CHOOSE_CONTENT,
    DELETE_CONTENT_TOKO,
    ADD_CONTENT_TOKO,
    UPGRADE_PENGGUNA,
    CHECK_EMAIL,
    LIST_ADMIN
}