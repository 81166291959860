/*
import LogoHeader from '../assets/logo_header_walang.svg'; //tokobukupintar.com
import logoWalang from '../assets/logo_toko_putih.svg'; //tokobukupintar.com

var AppName = "Toko Buku Pintar";
var clientID = "STR0107052022";


/*
import LogoHeader from '../assets/LOGO.png'; //tokobukupintar.com
import logoWalang from '../assets/LOGO_PUTIH.png'; //tokobukupintar.com

var AppName = "Graha Ilmu Digital";
var clientID = "grahailmudigital";
*/

var clientSettings = {

    appName: localStorage.getItem("namaToko"),
    clientId: localStorage.getItem("kodeApp"),
    logo: localStorage.getItem("logo"),
    logoputih: localStorage.getItem("logo_putih"),
    os: 'web',
    ostype: 'desktop',
    version: '1'
}

const createNewHeaders = (token, data) => {
    //localStorage.setItem("namaToko",this.clientSettings.appName);
    const uuid = localStorage.getItem('uuid');
    const beranda = localStorage.getItem("beranda");
    //console.log("create token = " + token);
    window.customHeaders = {
        "CLIENTID": localStorage.getItem("kodeApp"),
        "VERSION": clientSettings.version,
        "UUID": uuid,
        "KODEBERANDA": beranda,
        "uid": localStorage.getItem("kode"),
        // "UID": 'USR275BE985C',
        "OS": clientSettings.os,
        "OSTYPE": clientSettings.ostype,
        // "TOKEN": 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NTI1ODc5NTcsImlzcyI6Imt1YnVrdS5jby5pZCIsImF1ZCI6Imt1YnVrdS5jby5pZCIsImRhdGEiOnsiaWRkIjoiVlZOU01qYzFRa1U1T0RWRCIsInV1aWQiOiJNVEl6TkMweE1qTTBMVEV5TXpRdE1USXpOQT09IiwidXNlciI6eyJrb2RlIjoiVVNSMjc1QkU5ODVDIiwiZW1haWwiOiJkYXdhbXN1cnVyaTIxQGdtYWlsLmNvbSIsInBhc3N3b3JkIjoiIiwibmFtYSI6ImRhd2FtIiwiYXZhdGFyIjoiaHR0cHM6XC9cL2t1YnVrdS5teS5pZFwvYXBpXC9pbWFnZXNcL2F2YXRhclwvdXNlci5wbmciLCJha3RpZiI6IjEiLCJ1dWlkIjoiMTIzNC0xMjM0LTEyMzQtMTIzNCIsImNsaWVudElkIjoiU1RSMDEwNzA1MjAyMiIsIm9zIjoid2ViIn19fQ.2XdAxXsg4Waim2YQnH7UES81ibShLTnRKaF_bFtliV4'
    }

}

const updateHeaders = (key, data) => {

    const uuid = localStorage.getItem('uuid');
    const token = localStorage.getItem('token');
    const kode = localStorage.getItem('kode');
    const beranda = localStorage.getItem("beranda");
    //console.log("update_token = " + token);
    //console.log("update_kode = " + kode);

    window.customHeaders = {
        "CLIENTID": localStorage.getItem("kodeApp"),
        "VERSION": clientSettings.version,
        "KODEBERANDA": beranda,
        "UUID": uuid,
        "UID": kode,
        "OS": clientSettings.os,
        "OSTYPE": clientSettings.ostype,
        "TOKEN": token
    }

}

const executePost = async (url, formData) => {
    const flagOpen = localStorage.getItem('flagOpen');
    //console.log("flagOpen = " + flagOpen);
    if(flagOpen != "1") {
        createNewHeaders('', ''); 
    } else {
        updateHeaders('', ''); 
    }
    try {
        let postData = {
            method: 'post',
            headers: window.customHeaders,
            body: formData
        }
        const response = await fetch(url, postData);
        const json = await response.text();
        ////console.log(json);
        return JSON.parse(json);

    } catch (error) {

        let errorObj = {
            code: 500,
            msg: 'Network Error'
        }
        ////console.log(error);
        return errorObj;
    }
}

const executeGet = async (url) => {
    const flagOpen = localStorage.getItem('flagOpen');
    //console.log("flagOpen = " + flagOpen);
    if(flagOpen != "1") {
        createNewHeaders('', ''); 
    } else {
        updateHeaders('', ''); 
    }
    //console.log("url = " + url);
    //console.log("header = " + JSON.stringify(window.customHeaders));
    try {
        let postData = {
            method: 'get',
            headers: window.customHeaders,
        }
        const response = await fetch(url, postData);
        const json = await response.json();
        return json;

    } catch (error) {
        ////console.log(error);
        let errorObj = {
            code: 500,
            msg: 'Network Error'
        }
        return errorObj;
    }
}



export { clientSettings, createNewHeaders, executeGet,  executePost, updateHeaders };
