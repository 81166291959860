import React from "react";
import BookCover from '../assets/book_cover_full.svg';

export const CheckboxCart = props => {
  return (
    <div>
      <div className="hidden lg:block">
      <li className="flex py-10 border-b">
        {/* <div className="w-1/12 items-center flex">
        <input
          key={props.id}
          onChange={props.handleCheckChieldElement}
          type="checkbox"
          checked={props.isChecked}
          value={props.value}
        />{" "}
      </div> */}

        <div className="w-7/12 ">
          <div className="px-1 flex">
            <div className="w-[30%]">
              <div className="w-32 h-48 max-w-xs overflow-hidden rounded-[6px] shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <img className='w-full' src={props.cover} alt="" />
              </div>
            </div>
            <div className="w-[70%]">
              <div className="ml-6">
                <div className="text-sm font-semibold mt-4 dark:text-black line-clamp-2">{props.judul}</div>
                <div className="text-[10px] font-semibold mt-1 text-[#999999] line-clamp-1">{props.isbn}</div>
                <div className="text-[10px] font-semibold mt-1 text-[#999999] line-clamp-1">{props.penulis}</div>
                <div className="text-[10px] font-semibold mt-1 text-[#999999] line-clamp-1">{props.penerbit}</div>
                <div className="flex text-center items-center">
                  {(() => {
                    if (props.harga_dasar == props.harga_akhir) {
                      return (
                        <div className="text-base font-semibold mt-1 text-greenWalang">Rp {props.formatted_harga_akhir}</div>
                      )
                    } else {
                      return (
                        <div>
                          <div className="text-base font-semibold mt-1 text-greenWalang">Rp {props.formatted_harga_akhir}</div>
                          <div className="line-through text-[10px] font-semibold mt-1 text-left text-[#999999]">Rp {props.formatted_harga_dasar}</div>
                        </div>
                      )
                    }
                  })()}
                </div>
              </div>

            </div>

          </div>
        </div>
        <div className="w-5/12 flex justify-end items-end pr-6">
          <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={props.addWishlist}>
            <path d="M4.0005 6.77673C4.20729 6.10613 4.57771 5.47603 5.14575 4.91284C5.593 4.46941 6.05717 4.17698 6.5229 4M12.6012 2.14593C14.2748 1.08425 16.9778 0.0803204 19.312 2.46071C24.853 8.11136 15.3507 19 11.0005 19C6.65036 19 -2.852 8.11136 2.68902 2.46072C5.0232 0.0803466 7.72613 1.08427 9.39978 2.14593C10.3455 2.74582 11.6555 2.74582 12.6012 2.14593Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
          </svg>
          <div className="px-3 text-[12px] text-grayWalangDark divide-x " onClick={props.addWishlist}>Pindahkan ke Wishlist</div>
          <div onClick={props.deleteCart}>
            <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M9 0.25C6.37665 0.25 4.25 2.37665 4.25 5V5.25H1C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75H17C17.4142 6.75 17.75 6.41421 17.75 6C17.75 5.58579 17.4142 5.25 17 5.25H13.75V5C13.75 2.37665 11.6234 0.25 9 0.25ZM9 1.75C10.7949 1.75 12.25 3.20507 12.25 5V5.25H5.75V5C5.75 3.20507 7.20507 1.75 9 1.75Z" fill="#2D264B" />
              <path d="M2.74664 7.92906C2.70746 7.5167 2.34142 7.21418 1.92906 7.25336C1.5167 7.29254 1.21418 7.65858 1.25336 8.07094C1.34783 9.06522 1.51833 10.2903 1.73748 11.8651L2.01903 13.8881C2.28819 15.8229 2.44085 16.9201 2.77109 17.819C3.3857 19.4918 4.48205 20.8328 5.89206 21.4278C6.65773 21.7509 7.53352 21.7506 8.83733 21.75H9.16267C10.4665 21.7506 11.3423 21.7509 12.1079 21.4278C13.5179 20.8328 14.6143 19.4918 15.2289 17.819C15.5592 16.9201 15.7118 15.8229 15.981 13.8881L16.2625 11.8651C16.4817 10.2903 16.6522 9.06522 16.7466 8.07094C16.7858 7.65858 16.4833 7.29254 16.0709 7.25336C15.6586 7.21418 15.2925 7.5167 15.2534 7.92906C15.1623 8.88702 14.9965 10.08 14.7744 11.6761L14.512 13.5611C14.2215 15.6488 14.0884 16.5736 13.8209 17.3017C13.305 18.7058 12.4472 19.6566 11.5248 20.0458C11.0746 20.2358 10.5292 20.25 9 20.25C7.47083 20.25 6.92544 20.2358 6.47524 20.0458C5.55279 19.6566 4.69496 18.7058 4.17907 17.3017C3.91156 16.5736 3.77851 15.6488 3.48798 13.5611L3.22564 11.6761C3.00352 10.08 2.83766 8.88703 2.74664 7.92906Z" fill="#2D264B" />
            </svg>
          </div>

        </div>
      </li>

      </div>
      
      <li className="py-3 border-b block lg:hidden">
        <div className="w-full">
          <div className="px-1 flex">
            <div className="w-[30%]">
              <div className="w-[99px] h-[135px] overflow-hidden rounded-2xl shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <img className='w-full' src={props.cover} alt="" />
              </div>
            </div>
            <div className="w-[70%]">
              <div className="ml-6">
                <div className="text-[14px] font-semibold mt-1 dark:text-black line-clamp-2">{props.judul}</div>
                <div className="text-[12px] font-semibold mt-1 text-[#999999] line-clamp-1">{props.penulis}</div>
                <div className="flex text-center items-center">
                  {(() => {
                    if (props.harga_dasar == props.harga_akhir) {
                      return (
                        <div className="text-[14px] font-semibold mt-1 text-greenWalang">Rp {props.formatted_harga_akhir}</div>
                      )
                    } else {
                      return (
                        <div>
                          <div className="text-[14px] font-semibold mt-1 text-greenWalang">Rp {props.formatted_harga_akhir}</div>
                          <div className="line-through text-xs font-semibold text-[#999999]">Rp {props.formatted_harga_dasar}</div>
                        </div>
                      )
                    }
                  })()}
                </div>
                <div className="w-full flex pt-2">
                  <svg width="16" height="14" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.0005 6.77673C4.20729 6.10613 4.57771 5.47603 5.14575 4.91284C5.593 4.46941 6.05717 4.17698 6.5229 4M12.6012 2.14593C14.2748 1.08425 16.9778 0.0803204 19.312 2.46071C24.853 8.11136 15.3507 19 11.0005 19C6.65036 19 -2.852 8.11136 2.68902 2.46072C5.0232 0.0803466 7.72613 1.08427 9.39978 2.14593C10.3455 2.74582 11.6555 2.74582 12.6012 2.14593Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
                  </svg>
                  <div className="px-2 text-[10px] text-grayWalangDark divide-x ">Pindahkan ke Wishlist</div>
                  <div onClick={props.deleteCart}>
                    <svg width="12" height="16" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M9 0.25C6.37665 0.25 4.25 2.37665 4.25 5V5.25H1C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75H17C17.4142 6.75 17.75 6.41421 17.75 6C17.75 5.58579 17.4142 5.25 17 5.25H13.75V5C13.75 2.37665 11.6234 0.25 9 0.25ZM9 1.75C10.7949 1.75 12.25 3.20507 12.25 5V5.25H5.75V5C5.75 3.20507 7.20507 1.75 9 1.75Z" fill="#2D264B" />
                      <path d="M2.74664 7.92906C2.70746 7.5167 2.34142 7.21418 1.92906 7.25336C1.5167 7.29254 1.21418 7.65858 1.25336 8.07094C1.34783 9.06522 1.51833 10.2903 1.73748 11.8651L2.01903 13.8881C2.28819 15.8229 2.44085 16.9201 2.77109 17.819C3.3857 19.4918 4.48205 20.8328 5.89206 21.4278C6.65773 21.7509 7.53352 21.7506 8.83733 21.75H9.16267C10.4665 21.7506 11.3423 21.7509 12.1079 21.4278C13.5179 20.8328 14.6143 19.4918 15.2289 17.819C15.5592 16.9201 15.7118 15.8229 15.981 13.8881L16.2625 11.8651C16.4817 10.2903 16.6522 9.06522 16.7466 8.07094C16.7858 7.65858 16.4833 7.29254 16.0709 7.25336C15.6586 7.21418 15.2925 7.5167 15.2534 7.92906C15.1623 8.88702 14.9965 10.08 14.7744 11.6761L14.512 13.5611C14.2215 15.6488 14.0884 16.5736 13.8209 17.3017C13.305 18.7058 12.4472 19.6566 11.5248 20.0458C11.0746 20.2358 10.5292 20.25 9 20.25C7.47083 20.25 6.92544 20.2358 6.47524 20.0458C5.55279 19.6566 4.69496 18.7058 4.17907 17.3017C3.91156 16.5736 3.77851 15.6488 3.48798 13.5611L3.22564 11.6761C3.00352 10.08 2.83766 8.88703 2.74664 7.92906Z" fill="#2D264B" />
                    </svg>
                  </div>

                </div>
              </div>

            </div>

          </div>
        </div>
        
      </li>

    </div>

  );
};

export default CheckboxCart;
