import { Component } from 'react';
import BookCover from '../assets/book_cover_full.svg';
import IconCart from '../assets/ic_cart.svg';

import CardCheckboxKategori from '../components/CardCheckboxKategori';
import CardKategori from '../components/CardKategori';

import { connect } from 'react-redux';
import { searchAction } from '../redux/actions';
import SectionBook from '../components/SectionBook';
import Pagination from '../components/Pagination';
import ModalPopup from "../components/ModalPopup";

let PageSize = 18;

class Search extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            data: [],
            contentData: [],
            title: "Kategori"
        }
    }

    componentDidMount = () => {
        // const localStr = localStorage.getItem('dataKategori');
        // console.log("localStr data = " + localStr);
        // const localStrParse = JSON.parse(localStr);
        console.log("from redux = " + JSON.stringify(this.props.search));
        console.log("from redux = " + JSON.stringify(this.props.search.data_section));
        // this.setState({ contentData: this.props.search.data_section });
        // console.log("localStr dataKategori = " + JSON.stringify(this.state.contentData));
    }

    // componentDidUpdate = () => {
    //     const localStr = localStorage.getItem('dataKategori');
    //     console.log("localStr data = " + localStr);
    //     const localStrParse = JSON.parse(localStr);
    //     this.setState({ contentData : localStrParse.data})
    //     console.log("localStr dataKategori = " + JSON.stringify(this.state.contentData));
    // }

    // currentTableData = () => {
    //     console.log("//////");
    //     console.log(this.props.search);
    //     const firstPageIndex = (this.state.currentPage - 1) * PageSize;
    //     const lastPageIndex = firstPageIndex + PageSize;
    //     return this.props.search.data.slice(firstPageIndex, lastPageIndex);
    // };

    currentTableData = (contentData) => {
        console.log("currentTableData = " + JSON.stringify(contentData));
        const firstPageIndex = (this.state.currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return contentData.slice(firstPageIndex, lastPageIndex);
    };


    render() {
        return (
            <div>
                {
                    this.state.isModal &&
                    <ModalPopup
                        messages={this.state.msg}
                        press={() => { this.setState({ isModal: false }) }}
                        type="success"
                    />
                }
                {
                    this.state.isError &&
                    <ModalPopup
                        messages={this.state.msg}
                        press={() => { this.setState({ isError: false }) }}
                        type="error"
                    />
                }
                {this.state.showModal ? (
                    <>
                        <div
                            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                        >
                            <div className="relative w-[90%] h-[96%] my-2 mx-auto">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-[98%]">
                                    {/*header*/}
                                    <div className="flex items-start justify-between p-2 border-b border-solid border-slate-200 rounded-t">
                                        <div className="flex w-full">
                                            <h3 className="text-xl font-semibold dark:text-black w-[50%]">
                                                Preview
                      </h3>
                                            <div className="w-[50%] flex justify-end items-end">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" onClick={() => this.setState({ showModal: false })}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                </svg>
                                            </div>
                                        </div>

                                    </div>
                                    {/*body*/}
                                    <div className="relative pb-6 pr-6 pl-6 flex-auto">
                                        {
                                            this.state.showAge ?
                                                <div className="lg:py-32 py-4 pt-10">
                                                    <div className="text-4xl font-semibold dark:text-black pb-14 text-center">Masukkan Tanggal Lahir Anda</div>
                                                    <div className="flex items-center justify-center">
                                                        <input type="date" onChange={event => this.setState({ tanggal_lahir: event.target.value })} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 lg:w-6/12 w-full" placeholder="Select date" />
                                                    </div>
                                                    <div className="flex items-center justify-center pt-6">
                                                        <button type="button" onClick={this.nextDetail} className="w-full bg-greenWalang font-medium rounded-full text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 lg:w-3/12 w-6/12">
                                                            <div className="text-[#ffffff]">Lanjutkan</div>
                                                        </button>
                                                    </div>
                                                </div>
                                                :
                                                <iframe src={this.state.urlPreview} title="Preview" height="100%" width="100%" className="w-full h-full"></iframe>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}
                <div className="hidden lg:block">
                    <div className="pt-24 px-8 xl:px-36 dark:bg-white">
                        <div className="py-7">
                            {/* Home / Kategori */}
                            <nav className="flex" aria-label="Breadcrumb">
                                <ol className="inline-flex items-center space-x-1 lg:space-x-3">
                                    <li className="inline-flex items-center">
                                        <a href="/" className="inline-flex items-center text-sm font-medium text-greenWalang hover:text-greenWalang hover:opacity-30 dark:text-greenWalang dark:hover:text-greenWalang">
                                            Home
                                    </a>
                                    </li>
                                    <li aria-current="page">
                                        <div className="flex items-center">
                                            <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                            <span className="ml-1 text-sm font-medium text-gray-400 lg:ml-2 dark:text-gray-500">Kategori</span>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="py-4 p-8 xl:px-36 bg-greenWalang bg-opacity-30 text-2xl text-greenWalang">
                        Hasil Pencarian
                    </div>
                    <div className="pt-8 p-8 xl:px-36 dark:bg-white">
                        <div className="flex pb-[50px] space-x-7">
                            {/* <div className="w-3/12">
                                {this.state.data.map(item => {
                                    return (
                                        <CardCheckboxKategori
                                            data={item}
                                        />
                                    )
                                })}
                                <div className="border rounded-2xl p-[30px] mb-[30px]">
                                    <div className="text=[20px] font-semibold pb-[15px] border-b border-grayWalang">Filter by Price</div>

                                </div>
                            </div> */}
                            <div className="w-full">
                                {
                                    this.props.search.data_section.map(item => {
                                        return (
                                            <div className="pt-4 dark:bg-white">
                                                <div>
                                                    <div className="w-full border-b py-1 font-semibold text-xl dark:text-black">
                                                        {item.judul}
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 mb-1 py-[30px]">
                                                    {this.currentTableData(item.data).map(items => {
                                                        return (
                                                            <CardKategori
                                                                data={items}
                                                            />
                                                        )
                                                    })}
                                                </div>
                                                <div className="flex justify-center">
                                                    <Pagination
                                                        className="pagination-bar"
                                                        currentPage={this.state.currentPage}
                                                        totalCount={item.data.length}
                                                        pageSize={PageSize}
                                                        onPageChange={
                                                            page => this.setState({ currentPage: page })
                                                        }
                                                    />
                                                </div>

                                            </div>

                                        )
                                    })
                                }


                            </div>
                        </div>


                    </div>

                    <div className="py-5 px-36 text-2xl">
                        Rekomendasi
                    </div>
                    <div className="px-36 dark:bg-white">
                        <div className="flex pb-[50px] space-x-7">
                            <div className="flex overflow-x-scroll pb-10 hide-scroll-bar">
                                <div className="grid grid-cols-6 mb-1 py-[30px]">
                                    {
                                        this.props.search.data_rekomendasi.data.map(item => {
                                            return (
                                                <CardKategori
                                                    data={item}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
                <div className="block lg:hidden">
                    
                    <div className="pt-16 px-2 dark:bg-white">
                        <div className="flex pb-[50px] space-x-7">
                            {/* <div className="w-3/12">
                                {this.state.data.map(item => {
                                    return (
                                        <CardCheckboxKategori
                                            data={item}
                                        />
                                    )
                                })}
                                <div className="border rounded-2xl p-[30px] mb-[30px]">
                                    <div className="text=[20px] font-semibold pb-[15px] border-b border-grayWalang">Filter by Price</div>

                                </div>
                            </div> */}
                            <div className="w-full">
                                {
                                    this.props.search.data_section.map(item => {
                                        return (
                                            <div className="pt-4 dark:bg-white">
                                                <div>
                                                    <div className="w-full border-b py-1 font-semibold text-xl dark:text-black">
                                                        {item.judul}
                                                    </div>
                                                </div>
                                                <div className="grid grid-cols-2 mb-1 py-[30px]">
                                                    {this.currentTableData(item.data).map(items => {
                                                        return (
                                                            <CardKategori
                                                                data={items}
                                                            />
                                                        )
                                                    })}
                                                </div>
                                                <div className="flex justify-center">
                                                    <Pagination
                                                        className="pagination-bar"
                                                        currentPage={this.state.currentPage}
                                                        totalCount={item.data.length}
                                                        pageSize={PageSize}
                                                        onPageChange={
                                                            page => this.setState({ currentPage: page })
                                                        }
                                                    />
                                                </div>

                                            </div>

                                        )
                                    })
                                }


                            </div>
                        </div>


                    </div>

                    <div className="py-5 px-2 text-2xl">
                        Rekomendasi
                    </div>
                    <div className="px-2 dark:bg-white">
                        <div className="flex pb-[50px] space-x-7">
                            <div className="flex overflow-x-scroll pb-10 hide-scroll-bar">
                                <div className="grid grid-cols-2 mb-1 py-[30px]">
                                    {
                                        this.props.search.data_rekomendasi.data.map(item => {
                                            return (
                                                <CardKategori
                                                    data={item}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
        );

    }
}

const mapStateToProps = (state) => ({
    search: state.search
});

export default connect(mapStateToProps, { searchAction })(Search);
