import { Component } from 'react';
import BookCover from '../assets/book_cover_full.svg';
import IconCart from '../assets/ic_cart.svg';

import CheckboxCart from "../components/CheckboxCart";

import { createNewHeaders, updateHeaders, executeGet, executePost } from '../functions/Kubuku';
import { GET_CART, DELETE_CART, PAYMENT_METHOD, CHECKOUT } from '../api';
import ModalLoading from "../components/ModalLoading";
import Pagination from '../components/Pagination';
import RadioPaymentMethod from '../components/RadioPaymentMethod';
let PageSize = 9;

class TransactionRedeem extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            currentPage: 1,
            showModal: false,
            msg: '',
            flagData: false,
            detail: '',
            data: [
                {
                    "groupId": 1,
                    "id": 1,
                    "title": "Piranesi",
                    "cover": <BookCover />,
                    "penulis": "Susana Clarke",
                    "formatted_harga_awal": "120,000",
                    "formatted_harga_akhir": "90,000"
                },
                {
                    "groupId": 1,
                    "id": 2,
                    "title": "Heartland Stars",
                    "cover": <BookCover />,
                    "penulis": "Susana Clarke",
                    "formatted_harga_awal": "120,000",
                    "formatted_harga_akhir": "90,000"
                }
            ],
            paymentMethod: '',
            selectedOption: '',
            selectedFeeOption: ''
        }
    }

    componentDidMount() {
        this.getCart();
    }

    getCart = async () => {
        try {
            const flagOpen = localStorage.getItem('flagOpen');
            if (flagOpen != "1") {
                createNewHeaders('', '');
            } else {
                updateHeaders('', '');
            }

            const json = await executeGet(GET_CART);
            console.log("cart = " + JSON.stringify(json));
            if (json.code == 200) {
                this.setState({ showMain: true, flagData: true, data: json.data, detail: json.detail });
            } else if (json.code == 404) {
                //masukkan tgl lahir
                this.setState({ flagData: false, msg: json.msg });
            } else if (json.code == 500 || json.code == 505) {
                window.location.href = window.location.origin +"/login";
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
    }

    deleteCart = async (kode_konten) => {
        console.log("deleteCart = " + kode_konten);
        try {
            let formData = new FormData();
            formData.append("kode_konten", kode_konten);

            const json = await executePost(DELETE_CART, formData);
            console.log("deleteCart = " + JSON.stringify(json));
            if (json.code == 200) {
                this.getCart();
                // open modal
                // this.props.navigate('/cart');
                // this.setState({showMain: true, data: json.data});
            } else if (json.code == 500 || json.code == 505) {
                window.location.href = window.location.origin +"/login";
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
    }
    getPaymentMethod = async () => {
        // console.log("getPaymentMethod");
        try {
            let formData = new FormData();
            formData.append("amount", this.state.detail.grand_total);

            const json = await executePost(PAYMENT_METHOD, formData);
            console.log("getPaymentMethod = " + JSON.stringify(json));
            if (json.code == 200) {
                this.setState({ showModal: true, paymentMethod: json.data });
                // open modal
                // this.props.navigate('/cart');
                // this.setState({showMain: true, data: json.data});
            } else if (json.code == 500 || json.code == 505) {
                window.location.href = window.location.origin +"/login";
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }

    }

    checkout = async () => {
        // console.log("diskon checkout" + JSON.stringify(this.state.detail.diskon));
        // console.log("sub_total checkout" + JSON.stringify(this.state.detail.sub_total));
        // console.log("grand_total checkout" + JSON.stringify(this.state.detail.grand_total));

        // console.log("checkout = " + JSON.stringify(dataPay.paymentMethod));
        // console.log("checkout fee = " + JSON.stringify(dataPay.totalFee));
        // console.log("data checkout" + JSON.stringify(this.state.data));
        this.setState({ isLoading: true });
        try {
            let dataPay = this.state.paymentMethod.paymentFee[this.state.selectedOption];
            let jsonBody = {
                "diskon": this.state.detail.diskon,
                "sub_total": this.state.detail.sub_total,
                "grand_total": this.state.detail.grand_total,
                "payment_code": dataPay.paymentMethod,
                "total_fee": dataPay.totalFee,
                "data": this.state.data
            };
            console.log("checkout = " + JSON.stringify(jsonBody));

            const json = await executePost(CHECKOUT, JSON.stringify(jsonBody));
            console.log("CHECKOUT = " + JSON.stringify(json));
            if (json.code == 200) {
                this.setState({ isLoading: false });
                this.setState({ showModal: false });
                window.location.href = json.data.paymentUrl;
                // open modal
                // this.props.navigate('/cart');
                // this.setState({showMain: true, data: json.data});
            } else if (json.code == 500 || json.code == 505) {
                window.location.href = window.location.origin +"/login";
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
    }

    currentTableData = () => {
        const firstPageIndex = (this.state.currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return this.state.data.slice(firstPageIndex, lastPageIndex);
    };

    handleAllChecked = id => event => {
        let rows = this.state.data;
        console.log(event.target.checked);
        rows
            .filter(d => d.groupId === id)
            .forEach(row => {
                row.isChecked = event.target.checked;
            });
        this.setState({ data: rows });
    };

    handleCheckChieldElement = event => {
        let rows = this.state.data;
        rows.forEach(row => {
            if (`${row.groupId}-${row.id}` === event.target.value)
                row.isChecked = event.target.checked;
        });
        this.setState({ data: rows });
    };

    handleOnChange(e) {
        let val = e.target.value;
        console.log('selected option', val);
        this.setState({ selectedOption: e.target.value });
    }

    render() {
        return (
            <div>
                {
                    this.state.isLoading &&
                    <ModalLoading />
                }
                {
                    this.state.flagData && this.state.showMain &&
                    <div>
                        <div className="hidden lg:block">
                            <div className="pt-8 px-4 dark:bg-white">
                                <div className="flex pb-[50px] space-x-7">
                                    <div className="w-full">
                                        {this.currentTableData()
                                            .map((row) => {
                                                return (
                                                    <CheckboxCart
                                                        key={row.kode_konten}
                                                        handleCheckChieldElement={this.handleCheckChieldElement}
                                                        {...row}
                                                        value={row}
                                                        label={row.title}
                                                        deleteCart={() => { this.deleteCart(row.kode_konten) }}
                                                    />
                                                );
                                            })}
                                        
                                        <div className="flex justify-center">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={this.state.currentPage}
                                                totalCount={this.state.data.length}
                                                pageSize={PageSize}
                                                onPageChange={
                                                    page => this.setState({ currentPage: page })
                                                }
                                            />
                                        </div>

                                    </div>
                                    

                                </div>


                            </div>

                        </div>

                        <div className="block lg:hidden">
                            <div className="pt-28 px-4 dark:bg-white">
                                <div className="pb-[50px]">
                                    <div className="w-full h-[75%]">
                                        {this.currentTableData()
                                            .map((row) => {
                                                return (
                                                    <CheckboxCart
                                                        key={row.kode_konten}
                                                        handleCheckChieldElement={this.handleCheckChieldElement}
                                                        {...row}
                                                        value={row}
                                                        label={row.title}
                                                        deleteCart={() => { this.deleteCart(row.kode_konten) }}
                                                    />
                                                );
                                            })}
                                        {/* {[{ id: 1, name: "Pilih Semua" }].map(item => (
                                        <div>
                                            <div className="flex">
                                                <div className="w-1/12 items-center flex">
                                                    <input
                                                        type="checkbox"
                                                        onChange={this.handleAllChecked(item.id)}
                                                        value="checkedall"
                                                    />{" "}
                                                </div>
                                                <div>
                                                    {item.name}
                                                </div>
                                            </div>
                                            <ul>
                                                {this.state.data
                                                    .map((row, index) => {
                                                        return (
                                                            <CheckboxCart
                                                                key={`${item.id}-${row.id}`}
                                                                handleCheckChieldElement={this.handleCheckChieldElement}
                                                                {...row}
                                                                value={`${item.id}-${row.id}`}
                                                                label={row.title}
                                                            />
                                                        );
                                                    })}
                                            </ul>
                                        </div>
                                    ))} */}
                                        <div className="flex justify-center">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={this.state.currentPage}
                                                totalCount={this.state.data.length}
                                                pageSize={PageSize}
                                                onPageChange={
                                                    page => this.setState({ currentPage: page })
                                                }
                                            />
                                        </div>

                                    </div>
                                    <div className="w-full pt-4">
                                        <div className="border rounded-2xl p-[30px] mb-[30px]">
                                            <form>
                                                <div className="mb-6">
                                                    <label for="voucher" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Masukkan Voucher Anda</label>
                                                    <input type="text" id="voucherr" value={this.state.voucher} onChange={event => this.setState({ voucher: event.target.value })} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Makin hemat pake kode voucher" required />
                                                </div>
                                            </form>
                                            <div className="text-[16px] dark:text-black font-semibold pb-[23px] ">Ringkasan Belanja</div>
                                            <div className="flex text-[14px] text-grayWalangDark dark:text-grayWalangDark pb-[5px]">
                                                <div className="w-6/12 flex">Total Harga ({this.state.data.length} barang)</div>
                                                <div className="w-6/12 justify-end text-right">Rp {this.state.detail.formatted_sub_total}</div>
                                            </div>
                                            <div className="flex text-[14px] text-grayWalangDark dark:text-grayWalangDark pb-[18px] border-b border-grayWalang">
                                                <div className="w-6/12 flex">Total Diskon</div>
                                                <div className="w-6/12 justify-end text-right">Rp {this.state.detail.formatted_diskon}</div>
                                            </div>
                                            <div className="flex pb-[25px] pt-[19px] font-semibold">
                                                <div className="w-6/12 text-[16px] flex">Total Harga</div>
                                                <div className="w-6/12 text-[16px] text-greenWalang justify-end text-right">Rp {this.state.detail.formatted_grand_total}</div>
                                            </div>
                                            <form>
                                                <div className="mb-6">
                                                    <label for="voucher" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Masukkan Email Penerima</label>
                                                    <input type="text" id="voucherr" value={this.state.voucher} onChange={event => this.setState({ voucher: event.target.value })} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Email Penerima Buku Digital" required />
                                                </div>
                                            </form>
                                            <button onClick={this.getPaymentMethod} type="button" className="w-full bg-greenWalang font-medium rounded-full text-sm px-5 py-3 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2">
                                                <div className="text-[#ffffff]">
                                                    Pilih Pembayaran
                                            </div>

                                            </button>

                                            {/* <label for="my-modal-3">open modal</label>

                                        <input type="checkbox" id="my-modal-3" className="modal-toggle" value="true" /> */}
                                            {/* <div className="modal">
                                            <div className="modal-box relative">
                                                <label for="my-modal-3" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                                                <h3 className="text-lg font-bold">Congratulations random Interner user!</h3>
                                                <p className="py-4">You've been selected for a chance to get one year of subscription to use Wikipedia for free!</p>
                                            </div>
                                        </div> */}
                                        </div>
                                    </div>

                                </div>


                            </div>

                        </div>

                    </div>

                }
            </div>
        );

    }
}

export default TransactionRedeem;
