import React, { Component } from 'react';
import {
    Platform, View, StyleSheet, TextInput,
    Text, Image, StatusBar, BackHandler, Button
} from 'react-native';
import BookCover from '../assets/book_cover_full.svg';
import { useNavigate } from 'react-router-dom';
import { PAYMENT_METHOD, CHECKOUT_ULANG } from '../api';
import { executeGet, executePost } from '../functions/Kubuku';

// import { Button  } from 'react-native-elements';
// import Icon from 'react-native-vector-icons/Feather';

class ListTransaction extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: this.props.data,
            monthNames : [
                "January", "February", "March", "April", "May", "June", "July",
                "August", "September", "October", "November", "December"
            ],
            dayOfWeekNames : [
                "Sunday", "Monday", "Tuesday",
                "Wednesday", "Thursday", "Friday", "Saturday"
            ],
            grand_total:0,
            paymentMethod:'',
            id_transaksi:0,
            showModal: false
        }
        // console.log("Section = " + this.props.data);
    }

    goToDetail = async () => {
        // console.log("goToDetail" + this.props.data.kode_konten);
        // this.props.navigate('/details/'+this.props.data.kode_konten);
    }

    formatDate(date, formatStr){
        if (!formatStr) {
          formatStr = 'dd/mm/yyyy';
        }
        var day = date.getDate(),
            month = date.getMonth(),
            year = date.getFullYear(),
            hour = date.getHours(),
            minute = date.getMinutes(),
            second = date.getSeconds(),
            miliseconds = date.getMilliseconds(),
            hh = this.twoDigitPad(hour),
            mm = this.twoDigitPad(minute),
            ss = this.twoDigitPad(second),
            EEEE = this.state.dayOfWeekNames[date.getDay()],
            EEE = EEEE.substr(0, 3),
            dd = this.twoDigitPad(day),
            M = month + 1,
            MM = this.twoDigitPad(M),
            MMMM = this.state.monthNames[month],
            MMM = MMMM.substr(0, 3),
            yyyy = year + "",
            yy = yyyy.substr(2, 2)
        ;
        return formatStr
          .replace('hh', hh).replace('h', hour)
          .replace('mm', mm).replace('m', minute)
          .replace('ss', ss).replace('s', second)
          .replace('S', miliseconds)
          .replace('dd', dd).replace('d', day)
          .replace('MMMM', MMMM).replace('MMM', MMM).replace('MM', MM).replace('M', M)
          .replace('EEEE', EEEE).replace('EEE', EEE)
          .replace('yyyy', yyyy)
          .replace('yy', yy)
        ;
    }
    getPaymentMethod = async () => {
        // console.log("getPaymentMethod");
        try {

            let formData = new FormData();
            formData.append("amount", this.state.data.total);

            const json = await executePost(PAYMENT_METHOD, formData);
            console.log("getPaymentMethod = " + JSON.stringify(json));
            if (json.code == 200) {
                // this.setState({ showModal: true, paymentMethod: json.data });
                this.setState({
                    showModal: true,
                    paymentMethod: json.data
                }, () => {
                    console.log("paymentMethod = " + this.state.paymentMethod);
                });
                // open modal
                // this.props.navigate('/cart');
                // this.setState({showMain: true, data: json.data});
            } else if (json.code == 500 || json.code == 505) {
                // window.location.href = window.location.origin +"/login";
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }

    }
    handleOnChange(e) {
        let val = e.target.value;
        console.log('selected option', val);
        this.setState({ selectedOption: e.target.value });
    }

    lanjutBayar = async () => {
        console.log(this.state.data);
        window.location.href = this.state.data.paymentUrl;
    }

    checkout = async () => {
        
        try {
            let dataPay = this.state.paymentMethod.paymentFee[this.state.selectedOption];
            
            let jsonBody = {
                "orderId": this.state.data.order_id,
                "sub_total": this.state.data.sub_total,
                "grand_total": this.state.data.total,
                "payment_code": dataPay.paymentMethod,
                "total_fee": dataPay.totalFee,
                "data": this.state.data.data
            };
            const json = await executePost(CHECKOUT_ULANG, JSON.stringify(jsonBody));
            if (json.code == 200) {
                this.setState({ isLoading: false });
                this.setState({ showModal: false });
                
                window.location.href = json.data.paymentUrl;
                // open modal
                // this.props.navigate('/cart');
                // this.setState({showMain: true, data: json.data});
            } else if (json.code == 500 || json.code == 505) {
                window.location.href = window.location.origin + "/login";
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
    }
    twoDigitPad(num) {
        return num < 10 ? "0" + num : num;
    }

    render() {
        return (
            <div>
                <div className="py-4 border-b hidden md:block">
                    <div className="flex">
                        <div className="w-[50%]">
                            <div className="text-[14px] text-grayWalangDark">Nomor pesanan:</div>
                            <div className="text-[14px] text-black">{this.state.data.order_id}</div>
                        </div>
                        <div className="w-[25%]">
                            <div className="text-[14px] text-grayWalangDark">Tanggal transaksi:</div>
                            <div>{this.formatDate(new Date(this.state.data.tgl_input), 'dd-MM-yyyy hh:mm')}</div>
                        </div>
                        <div className="w-[25%]">
                            <div className="text-[14px] text-grayWalangDark">Metode:</div>
                            <div>{this.state.data.metode}</div>
                        </div>
                    </div>
                    {this.state.showModal ? (
                            <>
                                <div
                                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                                >
                                    <div className="relative w-[95%] lg:w-[40%] my-6 mx-auto">
                                        {/*content*/}
                                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                            {/*header*/}
                                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                                <div className="flex w-full">
                                                    <h3 className="text-xl font-semibold dark:text-black w-[50%]">
                                                        Pilih Metode Pembayaran
                                                    </h3>
                                                    <div className="w-[50%] flex justify-end items-end">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" onClick={() => this.setState({ showModal: false })}>
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                        </svg>
                                                    </div>
                                                </div>

                                            </div>
                                            {/*body*/}
                                            <div className="relative p-6 flex-auto">
                                                {/* payment gateway */}
                                                {this.state.paymentMethod.paymentFee.map((item, index) => {
                                                    return (
                                                        <div className="py-1">
                                                            { item.paymentMethod=="OV" || item.paymentMethod=="NQ" || item.paymentMethod=="BC" || item.paymentMethod=="BK" ?
                                                            (<div className="form-control">
                                                                <label className="label cursor-pointer">
                                                                    <div className="flex">
                                                                        <div className="w-[85px] h-[40px]">
                                                                            <img className='w-full' src={item.paymentImage} alt="" />
                                                                        </div>
                                                                        <div className="pl-4 flex items-center">
                                                                            <span className="label-text dark:text-black font-semibold">{item.paymentName}</span>
                                                                        </div>
                                                                    </div>
                                                                    <input
                                                                        type="radio"
                                                                        name="radio-payment"
                                                                        key={index}
                                                                        className="radio radio-accent checked:bg-greenWalang"
                                                                        value={index}
                                                                        onChange={(e) => this.handleOnChange(e)} />
                                                                </label>
                                                            </div>
                                                            ):null
                                                        }
                                                        </div>
                                                    )
                                                })}

                                                <div className="flex items-center p-6 border-t border-solid border-slate-200 rounded-b">
                                                    <div className="w-6/12">
                                                        <div className="text-[14px] font-semibold text-black">Total Harga</div>
                                                        <div className="text-[16px] text-greenWalang font-semibold">Rp {this.state.data.total}</div>
                                                    </div>
                                                    <div className="flex w-6/12 justify-end">
                                                        <button onClick={this.checkout} type="button" className="w-[60%] bg-greenWalang font-medium rounded-full text-sm px-5 py-3 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                                            <div className="text-[#ffffff]">
                                                                Lanjut
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                            </>
                        ) : null}
                    
                    { this.state.data.data[0] ? (
                    <div className="flex pt-4">
                        <div className="w-7/12">
                            <div className="px-1 flex">
                                    <div className="w-20%">
                                        <div className="w-[89px] h-[125px] max-w-xs overflow-hidden rounded-[6px] shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                                            <img className='w-full' src={this.state.data.data[0].cover} alt="" />
                                        </div>
                                    </div>
                                    <div className="w-40%">
                                        <div className="ml-2 pt-1">
                                        {(() => {
                                            if (this.state.data.status == "sukses") {
                                                return (
                                                    <div className="text-greenWalang text-[14px] font-semibold">Pembayaran Berhasil</div>
                                                )
                                            } else if (this.state.data.status == "pending") {
                                                return (
                                                    <>
                                                    <div className="text-[#FA8C17] text-[14px] font-semibold">
                                                        Belum Dibayar
                                                        
                                                    </div>
                                                    </>
                                                )
                                            } else if (this.state.data.status == "expired" && this.state.data.ulang_bayar==1) {
                                                return (
                                                    <>
                                                    <div className="text-[#E80F0F] text-[14px] font-semibold">Waktu bayar habis</div>
                                                    
                                                    </>
                                                    )
                                            } else if (this.state.data.status == "expired" && this.state.data.ulang_bayar==0) {
                                                return (
                                                    <>
                                                    <div className="text-[#E80F0F] text-[14px] font-semibold">Transaksi Kadaluarsa</div>
                                                    
                                                    </>
                                                    )
                                            } else {
                                                return (
                                                    <div className="text-[#E80F0F] text-[14px] font-semibold">{this.state.data.status}</div>
                                                )
                                            }
                                        })()}
                                                        <div className="text-[14px] font-semibold mt-1 dark:text-black line-clamp-1">{this.state.data.data[0].judul}</div>
                                                        <div className="text-[12px] font-semibold mt-1 text-[#999999] line-clamp-1">{this.state.data.data[0].penulis}</div>
                                                        <div className="text-[12px] font-semibold mt-1 text-[#999999] line-clamp-1">{this.state.data.data[0].penerbit}</div>
                                                        <div className="text-[12px] font-semibold mt-1 text-greenWalang">Rp {this.state.data.data[0].formatted_total}</div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div className="w-8/12 flex justify-end">
                            
                                {(() => {
                                    if (this.state.data.status == "expired" && this.state.data.ulang_bayar==1) {
                                        return (
                                            
                                            <div className="flex items-end">
                                                <div className="flex justify-end items-end pr-2 text-greenWalang text-[14px] cursor-pointer" onClick={this.props.detailTransaksi}>Lihat Detail Transaksi</div>
                                                <button onClick={this.getPaymentMethod} type="button" className="w-9/18 bg-greenWalang font-medium rounded-full text-sm px-5 py-3 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                                    <div className="text-[#ffffff]">
                                                        Pilih Pembayaran
                                                    </div>
                                                </button>
                                            </div>
                                            
                                            )
                                    }
                                    else if (this.state.data.status == "pending") {
                                        return (
                                            <>
                                            <div className="flex items-end">
                                                <div className="cursor-pointer flex justify-end items-end pr-2 text-greenWalang text-[14px]" onClick={this.props.detailTransaksi}>Lihat Detail Transaksi</div>
                                                <button onClick={this.lanjutBayar} type="button" className="bg-greenWalang font-medium rounded-full text-sm px-5 py-3 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                                    <div className="text-[#ffffff]">
                                                        Lanjut Bayar
                                                    </div>
                                                </button>
                                            </div>
                                            </>
                                        )
                                    } else {
                                        return (
                                            <>
                                            <div className="flex items-end">
                                                <div className="cursor-pointer flex justify-end items-end pr-2 text-greenWalang text-[14px]" onClick={this.props.detailTransaksi}>Lihat Detail Transaksi</div>
                                                
                                            </div>
                                            </>
                                        )
                                    }
                                })()}
                        </div>
                        
                    </div>
                    ) : null}

                    {(() => {
                        if (this.state.data.status == "pending") {
                            return (
                                <>
                                <div className="text-xs mt-3">
                                    Harap segera bayar atau untuk ganti metode Pembayaran, silahkan tunggu sampai batas waktu pembayaran habis.    
                                </div>
                                </>
                            )
                        }
                    })()}
                </div>
                {/** mobile */}
                <div className="py-4 border-b block md:hidden">
                    <div className="flex text-sm font-semibold mb-1">
                        <div className="cursor-pointer w-6/12" onClick={this.props.detailTransaksi}>Lihat Detail</div>
                        <div className="flex w-6/12 justify-end">
                            {this.formatDate(new Date(this.state.data.tgl_input), 'dd-MM-yyyy hh:mm')}
                        </div>
                        
                    </div>
                    {this.state.showModal ? (
                            <>
                                <div
                                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                                >
                                    <div className="relative w-[95%] lg:w-[40%] my-6 mx-auto">
                                        {/*content*/}
                                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                            {/*header*/}
                                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                                <div className="flex w-full">
                                                    <h3 className="text-xl font-semibold dark:text-black w-[50%]">
                                                        Pilih Metode Pembayaran
                                                    </h3>
                                                    <div className="w-[50%] flex justify-end items-end">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" onClick={() => this.setState({ showModal: false })}>
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                        </svg>
                                                    </div>
                                                </div>

                                            </div>
                                            {/*body*/}
                                            <div className="relative p-6 flex-auto">
                                                {/* payment gateway */}
                                                {this.state.paymentMethod.paymentFee.map((item, index) => {
                                                    return (
                                                        <div className="py-1">
                                                            { item.paymentMethod=="OV" || item.paymentMethod=="NQ" || item.paymentMethod=="BC" || item.paymentMethod=="BK" ?
                                                            (<div className="form-control">
                                                                <label className="label cursor-pointer">
                                                                    <div className="flex">
                                                                        <div className="w-[85px] h-[40px]">
                                                                            <img className='w-full' src={item.paymentImage} alt="" />
                                                                        </div>
                                                                        <div className="pl-4 flex items-center">
                                                                            <span className="label-text dark:text-black font-semibold">{item.paymentName}</span>
                                                                        </div>
                                                                    </div>
                                                                    <input
                                                                        type="radio"
                                                                        name="radio-payment"
                                                                        key={index}
                                                                        className="radio radio-accent checked:bg-greenWalang"
                                                                        value={index}
                                                                        onChange={(e) => this.handleOnChange(e)} />
                                                                </label>
                                                            </div>
                                                            ):null
                                                        }
                                                        </div>
                                                    )
                                                })}

                                                <div className="flex items-center p-6 border-t border-solid border-slate-200 rounded-b">
                                                    <div className="w-6/12">
                                                        <div className="text-[14px] font-semibold text-black">Total Harga</div>
                                                        <div className="text-[16px] text-greenWalang font-semibold">Rp {this.state.data.total}</div>
                                                    </div>
                                                    <div className="flex w-6/12 justify-end">
                                                        <button onClick={this.checkout} type="button" className="w-[60%] bg-greenWalang font-medium rounded-full text-sm px-5 py-3 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                                            <div className="text-[#ffffff]">
                                                                Lanjut
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                            </>
                        ) : null}
                    <div>
                        <div className="w-full">
                            <div className="text-[12px] text-grayWalangDark">Nomor pesanan :</div>
                            <div className="text-[14px] text-black font-semibold">{this.state.data.order_id}</div>
                        </div>
                        {/* 
                        <div className="w-full">
                            {(() => {
                                if (this.state.data.status == "sukses") {
                                    return (
                                        <div className="text-greenWalang text-[14px] font-semibold">Pembayaran Berhasil</div>
                                    )
                                } else if (this.state.data.status == "pending") {
                                    return (
                                        <>
                                        <div className="text-[#FA8C17] text-[14px] font-semibold">
                                            Belum Dibayar
                                            
                                        </div>
                                        </>
                                    )
                                } else if (this.state.data.status == "expired" && this.state.data.ulang_bayar==1) {
                                    return (
                                        <>
                                        <div className="text-[#E80F0F] text-[14px] font-semibold">Waktu bayar habis</div>
                                        
                                        </>
                                        )
                                } else if (this.state.data.status == "expired" && this.state.data.ulang_bayar==0) {
                                    return (
                                        <>
                                        <div className="text-[#E80F0F] text-[14px] font-semibold">Transaksi Kadaluarsa</div>
                                        
                                        </>
                                        )
                                } else {
                                    return (
                                        <div className="text-[#E80F0F] text-[14px] font-semibold">{this.state.data.status}</div>
                                    )
                                }
                            })()}
                            <div>Rp {this.state.data.total}</div>
                            
                        </div> */}
                        { this.state.data.data[0] ? (
                            <div className="flex pt-4">
                                <div className="w-12/12">
                                    <div className="px-1 flex">
                                            <div className="w-20%">
                                                <div className="w-[89px] h-[125px] max-w-xs overflow-hidden rounded-[6px] shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                                                    <img className='w-full' src={this.state.data.data[0].cover} alt="" />
                                                </div>
                                            </div>
                                            <div className="w-40%">
                                                <div className="ml-2 pt-1">
                                                {(() => {
                                                    if (this.state.data.status == "sukses") {
                                                        return (
                                                            <div className="text-greenWalang text-[14px] font-semibold">Pembayaran Berhasil</div>
                                                        )
                                                    } else if (this.state.data.status == "pending") {
                                                        return (
                                                            <>
                                                            <div className="text-[#FA8C17] text-[14px] font-semibold">
                                                                Belum Dibayar
                                                                
                                                            </div>
                                                            </>
                                                        )
                                                    } else if (this.state.data.status == "expired" && this.state.data.ulang_bayar==1) {
                                                        return (
                                                            <>
                                                            <div className="text-[#E80F0F] text-[14px] font-semibold">Waktu bayar habis</div>
                                                            
                                                            </>
                                                            )
                                                    } else if (this.state.data.status == "expired" && this.state.data.ulang_bayar==0) {
                                                        return (
                                                            <>
                                                            <div className="text-[#E80F0F] text-[14px] font-semibold">Transaksi Kadaluarsa</div>
                                                            
                                                            </>
                                                            )
                                                    } else {
                                                        return (
                                                            <div className="text-[#E80F0F] text-[14px] font-semibold">{this.state.data.status}</div>
                                                        )
                                                    }
                                                })()}
                                                                <div className="text-[14px] font-semibold mt-1 dark:text-black line-clamp-1">{this.state.data.data[0].judul}</div>
                                                                <div className="text-[12px] font-semibold mt-1 text-[#999999] line-clamp-1">{this.state.data.data[0].penulis}</div>
                                                                <div className="text-[12px] font-semibold mt-1 text-[#999999] line-clamp-1">{this.state.data.data[0].penerbit}</div>
                                                                <div className="text-[12px] font-semibold mt-1 text-greenWalang">Rp {this.state.data.data[0].formatted_total}</div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                
                                
                            </div>
                            ) : null}
                            <div className="w-full flex justify-end">
                                    
                                    {(() => {
                                        return (
                                            <>
                                            <div className="flex items-end">
                                                <div className="cursor-pointer flex justify-end items-end pr-2 text-greenWalang text-[14px]" onClick={this.props.detailTransaksi}>Lihat Detail Transaksi</div>
                                                
                                            </div>
                                            </>
                                        )
                                    })()}
                            </div>
                            {(() => {
                                if (this.state.data.status == "pending") {
                                    return (
                                        <>
                                        <div className="text-xs mt-3">
                                            Harap segera bayar atau untuk ganti metode Pembayaran, silahkan tunggu sampai batas waktu pembayaran habis.    
                                        </div>
                                        </>
                                    )
                                }
                            })()}
                    </div>
                    <div className="flex pt-4">
                    <div className="w-7/12">
                        <div>
                            <div className="text-[12px] text-grayWalangDark">Metode :</div>
                            <div>{this.state.data.metode}</div>
                        </div>
                    </div>
                    <div className="w-5/12">
                        {(() => {
                            if (this.state.data.status == "expired" && this.state.data.ulang_bayar==1) {
                                return (
                                    
                                    <div>
                                    <button onClick={this.getPaymentMethod} type="button" className="w-full bg-greenWalang font-medium rounded-full text-sm px-5 py-3 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                            <div className="text-[#ffffff]">
                                                Pilih Pembayaran
                                            </div>
                                        </button>
                                    </div>
                                    
                                    )
                            }
                        })()}
                        {(() => {
                            if (this.state.data.status == "pending") {
                                return (
                                    <>
                                    <div>
                                        <button onClick={this.lanjutBayar} type="button" className="w-full bg-greenWalang font-medium rounded-full text-sm px-2 py-2 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                            <div className="text-[#ffffff]">
                                                Lanjut Bayar
                                            </div>
                                        </button>
                                    </div>
                                    </>
                                )
                            }
                        })()}
                    </div>
                    
                    </div>
                    
                    {(() => {
                        if (this.state.data.status == "pending") {
                            return (
                                <>
                                <div className="text-xs mt-1">
                                    Harap segera bayar atau untuk ganti metode Pembayaran, silahkan tunggu sampai batas waktu pembayaran habis.    
                                </div>
                                </>
                            )
                        }
                    })()}
                </div>

            </div>

        )
    }
}

var styles = StyleSheet.create({
    caption: {
        fontFamily: "Montserrat-Regular",
        fontSize: 16,
        marginBottom: 12,
        color: "#212A3B"
    }

})

function WithNavigate(props) {
    let navigate = useNavigate();
    return <ListTransaction {...props} navigate={navigate} />
}

export default WithNavigate;
