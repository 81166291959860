import { Component } from 'react';

import { createNewHeaders,updateHeaders, executeGet, executePost } from '../functions/Kubuku';
import { CARI_BUKU} from '../api';
import ModalLoading from "./ModalLoading";
import ModalPopup from "../components/ModalPopup";
import '../styles/animation-success.scss';

import Pagination from './Pagination';

import { connect } from 'react-redux';
import { bukuPromosiAction } from '../redux/actions';
import QRCode from 'react-qr-code';

let PageSize = 25;

class CardQRBuku extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            data: [],
            judul: "",
            deskripsi: "",
            keyword: "",
            isLoading: false,
            showMain: false,
            showModalPilihBuku: false,
            placeholder: 'Cari Judul Buku'
        }
        //this.getDataBuku();
    }

    componentDidMount = async () => {
        //this.getDataBuku();
    };

    currencyFormat(num) {
        return 'Rp. ' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    getDataBuku = async (keyw) => {
        try {
            //alert(keyw);
            if (keyw!="") {
                const flagOpen = localStorage.getItem('flagOpen');
                if(flagOpen != "1") {
                    createNewHeaders('', ''); 
                } else {
                    updateHeaders('', ''); 
                }
                this.setState({isLoading:true})
                let formData = new FormData();
                formData.append("keyword", keyw);

                const json = await executePost(CARI_BUKU, formData);
                
                if (json.code == 200) {
                    this.setState({ isLoading: false, data: json.data, showMain: true });
                } else if (json.code == 404) {
                    //masukkan tgl lahir
                    // this.setState({ flagData: false, msg: json.msg });
                } else if (json.code == 500 || json.code == 505) {
                    window.location.href = window.location.origin + "/login";
                }
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
    }
    currentTableData = () => {
        const firstPageIndex = (this.state.currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return this.state.data.slice(firstPageIndex, lastPageIndex);
    };

    _handleKeyDown = async (e) => {
        if (e.key === 'Enter') {
          this.getDataBuku(e.target.value);
        }
      }

    render() {
        return (
            <div>
                {
                    this.state.isLoading &&
                    <ModalLoading />
                }
                
                    
                <div className="flex items-start justify-between p-0 border-b border-solid border-slate-200 rounded-t">
                    <div className="w-full">
                        
                        <div className="w-full pb-4 relative text-gray-600 focus-within:text-gray-400">
                            <span className="mb-4 absolute inset-y-0 left-0 flex items-center pl-2">
                                <button type="submit" className="p-1 focus:outline-none focus:shadow-outline">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-[20px] w-[20px]" fill="none" viewBox="0 0 24 24" stroke="#E3E3E3">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                                </button>
                            </span>

                            <input type="search" name="search" id="search" onKeyDown={this._handleKeyDown} className="py-3 text-sm text-gray-900 pr-4 bg-transparent border-solid border-grayWalang border rounded-full pl-10 focus:outline-none focus:bg-white focus:text-gray-900 w-full" placeholder={this.state.placeholder} autoComplete="on"/>

                        </div>
                        
                    </div>

                </div>
                { this.state.showMain &&
                    <>
                    <div className="relative p-0 flex-auto overflow-y-auto">
                        
                        
                        <div className='grid grid-cols-1 gap-x-1 gap-y-1  lg:gap-x-2 lg:gap-y-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 px-0 lg:px-0 mt-1'>
                        {

                            this.currentTableData().map(item => {
                                return (
                                    <>
                                    
                                    
                                    <div className="flex flex-col rounded-[8px] bg-[#eeeeee] relative ml-4 pb-2 mb-6">
                                        <div className="w-full relative pt-2">
                                            <center>
                                            <QRCode className="bg-white" value={window.location.origin + '/etalaseDetails/' + item.id} size="220" />
                                            </center>
                                        </div>
                                        <div className='flex flex-row pt-2 pl-2 pr-2'>
                                            <div className='w-[40%]'>
                                                <img className='w-[100px] rounded-[8px]' src={item.cover} alt="" />
                                            </div>
                                            <div className='pl-2 w-[60%]'>
                                                <div className="line-clamp-2">{item.judul}</div>
                                                <div className="line-clamp-1 text-grayWalangDark text-xs pt-2">{item.penulis}</div>
                                                <div className="line-clamp-1 text-grayWalangDark text-xs pt-2">{item.penerbit}</div>
                                                <div className="text-lg font-semibold text-greenWalang">{this.currencyFormat(item.harga)}</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    </>
                                )
                            })
                            
                        }
                        
                        </div>
                        
                        <div className="w-full flex justify-center pt-8">
                            <Pagination
                                className="pagination-bar"
                                currentPage={this.state.currentPage}
                                totalCount={this.state.data.length}
                                pageSize={PageSize}
                                onPageChange={
                                    page => this.setState({ currentPage: page })
                                }
                            />
                        </div>
                    </div>
                    </>       
                    
                }
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    bukuPromosi: state.bukuPromosi
});
  
export default connect(mapStateToProps, { bukuPromosiAction })(CardQRBuku);