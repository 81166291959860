import { Component } from 'react';
import IconProfile from '../assets/ic_profile.svg';
import IconInvoice from '../assets/ic_invoice.svg';
import IconNotification from '../assets/ic_notification.svg';
import IconStar from '../assets/ic_star.svg';
import IconLogout from '../assets/ic_logout.svg';

import { createNewHeaders, updateHeaders, executeGet, executePost } from '../functions/Kubuku';
import { PROFILE, UPDATE_PROFILE, LOGOUT } from '../api';

// import Datepicker from '@themesberg/tailwind-datepicker/Datepicker';

import DatepickerCustom from '../components/DatepickerCustom';
import ModalLoading from "../components/ModalLoading";
import ModalPopup from "../components/ModalPopup";

class Profile extends Component {

  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      nama: '',
      phone: '',
      data: '',
      tglLahir: '',
      alamat: '',
      showModal: false,
      typeModal: '',
      msg: ''

    }
  }

  componentDidMount() {
    // const script = document.createElement("script");
    // script.src = "https://unpkg.com/flowbite@1.4.5/dist/datepicker.js";
    // script.async = true;
    // script.onload = () => this.scriptLoaded();

    // document.body.appendChild(script);
    this.getProfile();

    // const datepickerEl = document.getElementById('datepickerId');
    // new Datepicker(datepickerEl, {
    //     // options
    // }); 
  }

  scriptLoaded() {
    console.log("script load");
    // window.A.sort();
  }

  getProfile = async () => {
    try {
      const flagOpen = localStorage.getItem('flagOpen');
      console.log("flagOpen = " + flagOpen);
      if(flagOpen != "1") {
          createNewHeaders('', ''); 
      } else {
          updateHeaders('', ''); 
      }
      const json = await executeGet(PROFILE);
      console.log("profile = " + JSON.stringify(json));
      if (json.code == 200) {
        this.setState({ data: json.data, nama: json.data.nama, phone: json.data.no_ponsel, tglLahir: json.data.numeric_tanggal_lahir, alamat: json.data.alamat });
        console.log("tglLahir = " + this.state.tglLahir);
      } else if (json.code == 404) {
        //masukkan tgl lahir
        // this.setState({ flagData: false, msg: json.msg });
      } else {
        //alert(json.code);
        // ke login
        console.log("url now = " + window.location.origin)
        window.location.href = window.location.origin + "/login";
      }
    } catch (error) {
      //console.log(error);
      this.setState({ isError: true, errorMessage: "Network Error!" });
    }
  }

  simpanProfile = async () => {
    console.log("save tglLahir = " + this.state.tglLahir);
    console.log("save alamat = " + this.state.alamat);
    if (this.state.nama == "") {
      this.setState({ msg: "Nama tidak boleh kosong", typeModal: 'error', showModal:true });
    } else if(this.state.alamat == "") {
      this.setState({ msg: "Alamat tidak boleh kosong", typeModal: 'error', showModal:true });
    } else if(this.state.phone == "") {
      this.setState({ msg: "Phone tidak boleh kosong", typeModal: 'error', showModal:true });
    } else {
      console.log("simpanProfile = " + this.state.tglLahir);
      try {
        let formData = new FormData();
        formData.append("nama", this.state.nama);
        formData.append("alamat", this.state.alamat);
        formData.append("no_ponsel", this.state.phone);
        // formData.append("tgl_lahir", "1997-12-19");
        formData.append("tgl_lahir", this.state.tglLahir);
  
        const json = await executePost(UPDATE_PROFILE, formData);
        console.log("update_profile = " + JSON.stringify(json));
        if (json.code == 200) {
          this.setState({ msg: json.msg, typeModal: 'success', showModal:true });
          this.getProfile();
          // open modal
          // this.props.navigate('/cart');
          // this.setState({showMain: true, data: json.data});
        }
      } catch (error) {
        //console.log(error);
        this.setState({ isError: true, errorMessage: "Network Error!" });
      }
    }
    
  }

  logout = async () => {
    console.log("logout");
    try {
      const json = await executeGet(LOGOUT);
      console.log("logout = " + JSON.stringify(json));
      if (json.code == 200) {
        //localStorage.clear();
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('flagOpen');
        window.location.href = window.location.origin;// + "/login";
      }
    } catch (error) {
      //console.log(error);
      this.setState({ isError: true, errorMessage: "Network Error!" });
    }
  }

  render() {
    return (
      <div>
        {
          this.state.showModal &&
          <ModalPopup
            messages={this.state.msg}
            press={() => {
              if (this.state.typeModal == "success") {
                // window.location.href = window.location.origin + "/login";
                this.setState({ showModal: false })
                this.getProfile();
              } else {
                this.setState({ showModal: false })
              }

            }}
            type={this.state.typeModal}
          />
        }
        <div className="dark:bg-white border rounded-2xl p-[30px] mb-24">
          <div className="text-4xl font-semibold dark:text-black pb-6">Akun</div>
          <form>
            <div className="mb-6">
              <label for="fullname" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Nama Lengkap</label>
              <input type="text" id="fullname" value={this.state.nama} onChange={event => this.setState({ nama: event.target.value })} className="mt-1 block w-full p-3 bg-gray-50 border border-gray-300 text-gray-900 rounded-full text-sm shadow-sm focus:outline-none focus:border-greenWalang focus:ring-1 focus:ring-greenWalang" placeholder="Masukkan Nama Lengkap" required />
            </div>
            <div className="mb-6">
              <label for="alamat" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Alamat</label>
              <input type="text" id="alamat" value={this.state.alamat} onChange={event => this.setState({ alamat: event.target.value })} className="mt-1 block w-full p-3 bg-gray-50 border border-gray-300 text-gray-900 rounded-full text-sm shadow-sm focus:outline-none focus:border-greenWalang focus:ring-1 focus:ring-greenWalang" placeholder="Masukkan Alamat Anda" required />
            </div>
            <div className="mb-6">
              <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Email Akun</label>
              <input type="email" id="email" value={this.state.data.email} className="mt-1 block w-full p-3 bg-gray-50 border border-gray-300 text-gray-900 rounded-full text-sm shadow-sm focus:outline-none focus:border-greenWalang focus:ring-1 focus:ring-greenWalang" placeholder="Masukkan Email" required />
            </div>
            <div className="mb-6">
              <label for="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">No. Handphone</label>
              <input type="text" id="phone" value={this.state.phone} className="mt-1 block w-full p-3 bg-gray-50 border border-gray-300 text-gray-900 rounded-full text-sm shadow-sm focus:outline-none focus:border-greenWalang focus:ring-1 focus:ring-greenWalang" placeholder="Masukkan Phone Number" required onChange={event => this.setState({ phone: event.target.value })} />
            </div>
            <div className="mb-6">
              {/* <DatepickerCustom /> */}
              <label for="birthdate" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Tanggal Lahir</label>
              <div className="relative">
                {/* <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path></svg>
                </div> */}
                <input type="date" value={this.state.tglLahir} onChange={event => this.setState({ tglLahir: event.target.value })} className="mt-1 block w-full p-3 bg-gray-50 border border-gray-300 text-gray-900 rounded-full text-sm shadow-sm focus:outline-none focus:border-greenWalang focus:ring-1 focus:ring-greenWalang" placeholder="Select date" />
              </div>
            </div>
            {/* <div className="mb-6">
              <label for="gener" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Jenis Kelamin</label>
              <div className="flex">
                <div className="flex items-center mr-4">
                  <input id="inline-radio" type="radio" value="" name="inline-radio-group" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-50 dark:border-gray-600" />
                  <label for="inline-radio" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-800">Laki-laki</label>
                </div>
                <div className="flex items-center mr-4">
                  <input id="inline-2-radio" type="radio" value="" name="inline-radio-group" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-50 dark:border-gray-600" />
                  <label for="inline-2-radio" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-800">Perempuan</label>
                </div>
              </div>
            </div> */}
            <div className="flex justify-end">
              <button onClick={this.simpanProfile} type="button" className="w-4/12 bg-greenWalang font-medium rounded-full text-sm px-5 py-3 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                <div className="text-[#ffffff]">
                  Simpan
                </div>
              </button>
            </div>

          </form>

        </div>
        {/* <div className="dark:bg-white mt-4 mb-24 block lg:hidden">
          <button onClick={this.logout} type="button" className="w-full border bg-white hover:bg-grayWalang font-medium rounded-full text-sm px-5 py-3 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center">
            <div className="text-black">
              Logout
                </div>
          </button>
        </div> */}
      </div>
    );

  }
}

export default Profile;
