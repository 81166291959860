import React from 'react'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const ExportExcel = ({ csvData, fileName }) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        // <Button variant="warning" onClick={(e) => exportToCSV(csvData,fileName)}>Export</Button>
        <button onClick={(e) => exportToCSV(csvData, fileName)} type="button" className="bg-greenWalang font-medium rounded-full text-sm px-5 py-3 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 mb-4 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
            <div className="text-[#ffffff] pr-4 text-center">
                Export
            </div>
        </button>
    )
}

export default ExportExcel;