import { Component } from 'react';
import BookCover from '../assets/book_cover_full.svg';
import IconCart from '../assets/ic_cart.svg';

import CheckboxCart from "../components/CheckboxCart";

import Pagination from '../components/Pagination';
let PageSize = 9;

class Payment extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            data: [
                {
                    "groupId": 1,
                    "id": 1,
                    "title": "Piranesi",
                    "cover": <BookCover />,
                    "penulis": "Susana Clarke",
                    "formatted_harga_awal": "120,000",
                    "formatted_harga_akhir": "90,000",
                    "isChecked": "checked"
                },
                {
                    "groupId": 1,
                    "id": 2,
                    "title": "Heartland Stars",
                    "cover": <BookCover />,
                    "penulis": "Susana Clarke",
                    "formatted_harga_awal": "120,000",
                    "formatted_harga_akhir": "90,000",
                    "isChecked": "checked"
                }
            ]
        }
    }

    currentTableData = () => {
        const firstPageIndex = (this.state.currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return this.state.contentData.slice(firstPageIndex, lastPageIndex);
    };

    handleAllChecked = id => event => {
        let rows = this.state.data;
        console.log(event.target.checked);
        rows
            .filter(d => d.groupId === id)
            .forEach(row => {
                row.isChecked = event.target.checked;
            });
        this.setState({ data: rows });
    };

    handleCheckChieldElement = event => {
        let rows = this.state.data;
        rows.forEach(row => {
            if (`${row.groupId}-${row.id}` === event.target.value)
                row.isChecked = event.target.checked;
        });
        this.setState({ data: rows });
    };


    render() {
        return (
            <div>
                <div className="pt-24 px-36 dark:bg-white">
                    <div className="py-7">
                        {/* Home / Kategori */}
                        <nav className="flex" aria-label="Breadcrumb">
                            <ol className="inline-flex items-center space-x-1 lg:space-x-3">
                                <li className="inline-flex items-center">
                                    <a href="/" className="inline-flex items-center text-sm font-medium text-greenWalang hover:text-greenWalang hover:opacity-30 dark:text-greenWalang dark:hover:text-greenWalang">
                                        Home
                                    </a>
                                </li>
                                <li aria-current="page">
                                    <div className="flex items-center">
                                        <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                        <span className="ml-1 text-sm font-medium text-gray-400 lg:ml-2 dark:text-gray-500">Pembayaran</span>
                                    </div>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="py-10 px-36 bg-greenWalang bg-opacity-30 text-5xl text-greenWalang">
                    Pembayaran
                </div>
                <div className="pt-8 px-36 dark:bg-white">
                    <div className="flex pb-[50px] space-x-7">
                        <div className="w-8/12 h-[75%]">
                        {[{ id: 1, name: "Pilih Semua" }].map(item => (
                            <div>
                                <div className="flex">
                                    <div className="w-1/12 items-center flex">
                                        <input
                                            type="checkbox"
                                            // onChange={this.handleAllChecked(item.id)}
                                            value="checkedall"
                                            checked="checked"
                                            />{" "}
                                    </div>
                                    <div>
                                        {item.name}
                                    </div>
                                </div>
                                <ul>
                                {this.state.data
                                    .filter(rows => rows.groupId === item.id)
                                    .map((row, index) => {
                                        return (
                                            <CheckboxCart
                                                key={`${item.id}-${row.id}`}
                                                // handleCheckChieldElement={this.handleCheckChieldElement}
                                                {...row}
                                                value={`${item.id}-${row.id}`}
                                                label={row.title}
                                            />
                                        );
                                    })}
                                </ul>
                            </div>
                        ))}
                            {/* <div className="flex justify-center">
                                <Pagination
                                    className="pagination-bar"
                                    currentPage={this.state.currentPage}
                                    totalCount={this.state.contentData.length}
                                    pageSize={PageSize}
                                    onPageChange={
                                        page => this.setState({ currentPage: page })
                                    }
                                />
                            </div> */}

                        </div>
                        <div className="w-4/12">
                            <div className="border rounded-2xl p-[30px] mb-[30px]">
                                <div className="text-[16px] dark:text-black font-semibold pb-[23px] ">Ringkasan Belanja</div>
                                <div className="flex text-[14px] text-grayWalangDark dark:text-grayWalangDark pb-[18px] border-b border-grayWalang">
                                    <div className="w-6/12 flex">Total Harga (2 barang)</div>
                                    <div className="w-6/12 justify-end text-right">Rp 180,000</div>
                                </div>
                                <div className="flex pb-[25px] pt-[19px] font-semibold">
                                    <div className="w-6/12 text-[16px] flex">Total Harga</div>
                                    <div className="w-6/12 text-[16px] text-greenWalang justify-end text-right">Rp 180,000</div>
                                </div>
                                <button type="button" className="w-full bg-greenWalang font-medium rounded-full text-sm px-5 py-3 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                    <div className="text-[#ffffff]">
                                        Pilih Pembayaran
                                    </div>

                                </button>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        );

    }
}

export default Payment;
