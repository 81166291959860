import React, { Component } from 'react';
import {
    Platform, View, StyleSheet, TextInput,
    Text, Image, StatusBar, BackHandler, Button
} from 'react-native';
import { useNavigate } from 'react-router-dom';
import ReactStars from "react-rating-stars-component";
import IconLightning from '../assets/ic_lightning.svg';

class CardPromoted extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: this.props.data,
            progress: 0,
            tglAkhir: ''
        }
        //console.log(this.props.data);
    }

    componentDidMount = () => {
        let countProgress = (Number(this.props.data.terpakai) * 100) / Number(this.props.data.jumlah);
        if (Number(this.props.data.jumlah)==0) countProgress=0;
        //console.log("countProgress = " + countProgress);
        let formatTgl = this.formatDate(this.props.data.tgl_akhir);
        // console.log("formatTgl = " + formatTgl);
        this.setState({ progress: countProgress, tglAkhir: formatTgl });
    }

    formatDate(value) {
        let date = new Date(value);
        const day = date.toLocaleString('default', { day: '2-digit' });
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.toLocaleString('default', { year: 'numeric' });
        return day + ' ' + month + ' ' + year;
    }
    
    goToDetail = async () => {
        if (this.props.data.id != undefined) {
            //console.log("goToDetail" + this.props.data.id);
            this.props.navigate('/'+ localStorage.getItem('beranda') +'/promosi/' + this.props.data.id);
        } else {
            //console.log("goToDetail = " + this.props.data.kode_konten);
            this.props.navigate('/details/' + this.props.data.kode_konten);
        }

    }

    render() {
        return (
            <>
            <div className="hidden lg:block">
                <div className="inline-block pr-4 pb-[30px]" onClick={this.goToDetail}>
                    <div className="w-[118px] max-w-xs overflow-hidden rounded-[6px] shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                        <img className='w-[118px] h-40 cursor-pointer' src={this.props.data.cover} alt="" />
                    </div>
                    <div className="text-sm font-semibold mt-4 dark:text-black line-clamp-1">{this.props.data.judul}</div>
                    <div className="text-[9px] mt-1 text-[#999999] line-clamp-1">{this.props.data.isbn}</div>
                    <div className="text-[9px] mt-1 text-[#999999] line-clamp-1">{this.props.data.penulis}</div>
                    <div className="text-[9px] mt-1 text-[#999999] line-clamp-1">{this.props.data.penerbit}</div>
                    {this.props.data.rating>0 && 
                    <div className='flex'>
                        <div>
                    <ReactStars
                        count={1}
                        size={9}
                        value={parseInt( this.props.data.rating)}
                        edit={false}
                        activeColor="#ffd700"
                    />
                    </div>
                    <div className='text-[9px]'> ({this.props.data.rating})</div>
                    </div>
                    }
                    {/* <div className="text-lg font-semibold mt-1 text-greenWalang">Rp. {this.props.data.formatted_harga_akhir}</div> */}
                    <div className="flex flex-col">
                        <div className="text-sm font-semibold text-greenWalang">Rp {this.props.data.formatted_harga_akhir}</div>
                        <div className="line-through text-[9px] font-semibold mt-1 text-[#999999] ml-2 items-top flex">Rp {this.props.data.formatted_harga_dasar}</div>
                    </div>
                    <div className="w-full h-5 bg-greenWalang bg-opacity-30 rounded-full dark:bg-greenWalang dark:bg-opacity-30">
                        <div className="h-5 bg-greenWalang rounded-full dark:bg-greenWalang" style={{ width: this.state.progress + '%' }}>
                            <div className="text-white absolute text-[9px] pl-2 p-[1.5px] flex">
                                <img className='w-[10px] mr-2' src={IconLightning} alt="" />
                                {this.state.progress}% terjual
                                    </div>
                        </div>
                    </div>
                    <div className="flex mt-2">
                        <div className="text-[9px] text-red-500">s/d {this.state.tglAkhir}</div>
                    </div>
                </div>
            </div>
            <div className="block lg:hidden">
                <div className="inline-block pr-4 pb-[30px]" onClick={this.goToDetail}>
                    <div className="w-[118px] max-w-xs overflow-hidden rounded-[6px] shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                        <img className='w-[118px] h-40 cursor-pointer' src={this.props.data.cover} alt="" />
                    </div>
                    <div className="text-sm font-semibold mt-4 dark:text-black line-clamp-1">{this.props.data.judul}</div>
                    <div className="text-[9px] mt-1 text-[#999999] line-clamp-1">{this.props.data.isbn}</div>
                    <div className="text-[9px] mt-1 text-[#999999] line-clamp-1">{this.props.data.penulis}</div>
                    <div className="text-[9px] mt-1 text-[#999999] line-clamp-1">{this.props.data.penerbit}</div>
                    {this.props.data.rating>0 && 
                    <div className='flex'>
                        <div>
                    <ReactStars
                        count={1}
                        size={9}
                        value={parseInt(this.props.data.rating)}
                        edit={false}
                        activeColor="#ffd700"
                    />
                    </div>
                    <div className='text-[9px]'> ({this.props.data.rating})</div>
                    </div>
                    }
                    {/* <div className="text-lg font-semibold mt-1 text-greenWalang">Rp. {this.props.data.formatted_harga_akhir}</div> */}
                    <div className="flex flex-col">
                        <div className="text-sm font-semibold text-greenWalang">Rp {this.props.data.formatted_harga_akhir}</div>
                        <div className="line-through text-[9px] font-semibold mt-1 text-[#999999] ml-2 items-top flex">Rp {this.props.data.formatted_harga_dasar}</div>
                    </div>
                    <div className="w-full h-5 bg-greenWalang bg-opacity-30 rounded-full dark:bg-greenWalang dark:bg-opacity-30">
                        <div className="h-5 bg-greenWalang rounded-full dark:bg-greenWalang" style={{ width: this.state.progress + '%' }}>
                            <div className="text-white absolute text-[9px] pl-2 p-[1.5px] flex">
                                <img className='w-[10px] mr-2' src={IconLightning} alt="" />
                                {this.state.progress}% terjual
                                    </div>
                        </div>
                    </div>
                    <div className="flex mt-2">
                        <div className="text-[9px] text-red-500">s/d {this.state.tglAkhir}</div>
                    </div>
                </div>
            </div>
            </>
            
        )
    }
}

var styles = StyleSheet.create({
    caption: {
        fontFamily: "Montserrat-Regular",
        fontSize: 16,
        marginBottom: 12,
        color: "#212A3B"
    },
    placeholder: {
        fontFamily: "Montserrat-Regular",
        fontSize: 14
    },
    input: {
        flex: 1,
        backgroundColor: "#F6F6F6",
        borderRadius: 10,
        fontFamily: "Montserrat-Regular",
        fontSize: 14,
        padding: 12,
        color: '#34495e'
    },
    error: {
        fontFamily: "Montserrat-Regular",
        fontSize: 14,
        color: "#e74c3c",
        alignSelf: "flex-end"
    }


})

// export default CardKategori;
function WithNavigate(props) {
    let navigate = useNavigate();
    return <CardPromoted {...props} navigate={navigate} />
}

export default WithNavigate;
