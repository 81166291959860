import { Component } from 'react';
import { StyleSheet, Text, View, Image, TextInput, Button } from 'react-native';
//import logoWalang from '../assets/logo_toko_putih.svg';
import line from '../assets/line.png';
import { v4 as uuidv4 } from 'uuid';

import { executePost, clientSettings} from '../functions/Kubuku';
import { RESET_PASSWORD } from '../api';
import { useNavigate } from "react-router-dom";
import ModalPopup from "../components/ModalPopup";

const logoWalang = clientSettings.logoputih;




// import CustomInput from './src/components/CustomInput';

function WithNavigate(props) {
  let navigate = useNavigate();
  return <ResetPassword {...props} navigate={navigate} />
}

class ResetPassword extends Component {

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      isModal: false,
      isError: false,
      msg: '',
      namaToko: localStorage.getItem('namaToko'),
      isPasswordShown: false
    }
  }

  togglePasswordVisiblity = () => {
    const { isPasswordShown } = this.state;
    this.setState({ isPasswordShown: !isPasswordShown });
  };

  login = async () => {
    try {
      let formData = new FormData();
      formData.append("email", this.state.email);

      const json = await executePost(RESET_PASSWORD, formData);
      console.log("RESET_PASSWORD = " + JSON.stringify(json));
      // console.log("LOGIN KODE = " + json.data.kode);
      if (json.code == 200) {
        this.setState({isModal: true, msg: json.msg})

      } else {
        this.setState({isModal: true, msg: json.msg})
      }
    } catch (error) {
      //console.log(error);
      this.setState({ isError: true, errorMessage: "Network Error!" });
    }
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.login();
    }
  }

  render() {
    return (
      <div>
        {
          this.state.isModal &&
          <ModalPopup
            messages={this.state.msg}
            press={() => { this.setState({ isModal: false }) }}
            type="success"
          />
        }
        {
          this.state.isError &&
          <ModalPopup
            messages={this.state.msg}
            press={() => { this.setState({ isError: false }) }}
            type="error"
          />
        }
        {
        <>
        <style>
                  {`
                  .bg-greenWalang {
                    background-color: rgb(${localStorage.getItem('colorPrimary')} / var(--tw-bg-opacity));
                  }
                  .border-greenWalang {
                    --tw-border-opacity: 1;
                    border-color: ${localStorage.getItem('colorPrimary')};
                  }
                  .text-greenWalang {
                    --tw-text-opacity: 1;
                    color: rgb(${localStorage.getItem('colorPrimary')} / var(--tw-text-opacity));
                  }
                `}
              
              </style>
        </>
         }
        <div className="hidden lg:block">
          <div className="flex items-stretch bg-white min-h-screen">
            <div className="w-6/12 bg-greenWalang flex justify-center items-center">
              <img className='w-[35%] absolute left-3' src={line} alt="" />
              <img className='w-[50%]' src={logoWalang} alt="" />
            </div>
            <div className="w-6/12">
              <div className="p-28">
                <div className="border border-gray-200 p-5 rounded-2xl">
                  <div className="font-medium text-[32px] pb-10 dark:text-gray-800">Lupa Password</div>
                  <div className="text-[14px] pb-10 text-[#838383] text-center">Masukkan email login anda di bawah ini. Kami akan mengirimkan pesan email beserta tautan untuk reset kata sandi anda.</div>
                  <form>
                    <div className="mb-6">
                      <label for="email" className="block mb-2 text-sm font-medium text-grayWalangDark dark:text-gray-800">Email</label>
                      <input type="email" id="email" onChange={event => this.setState({ email: event.target.value })} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@gmail.com" required />
                    </div>
                    <button onClick={this.login} type="button" className="w-full bg-greenWalang font-medium rounded-full text-sm px-5 py-3 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                      <div className="text-[#ffffff]">Kirim Permintaan</div>
                    </button>
                    <div className="flex justify-center mb-6 text-[14px] mt-4 text-grayWalangDark">
                      Sudah memiliki akun? <a href="/login" className="text-greenWalang pl-1">Login</a>
                    </div>
                  </form>
                
                </div>
                
              </div>
            </div>
          </div>
        </div>

        <div className="block lg:hidden">
        <div className="p-5 ">
            <div className="border border-gray-200 p-5 rounded-2xl">
              <div className="font-medium text-[32px] pb-10 dark:text-gray-800">Lupa Password</div>
              <div className="text-[14px] pb-10 text-[#838383] text-center">Masukkan email login anda di bawah ini. Kami akan mengirimkan pesan email beserta tautan untuk reset kata sandi anda.</div>
              <form>
                <div className="mb-6">
                  <label for="email" className="block mb-2 text-sm font-medium text-grayWalangDark dark:text-gray-800">Email</label>
                  <input type="email" id="email" onChange={event => this.setState({ email: event.target.value })} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@gmail.com" required />
                </div>
                <button onClick={this.login} type="button" className="w-full bg-greenWalang font-medium rounded-full text-sm px-5 py-3 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 hover:shadow-[0_8px_25px_-8px_#2BC239]">
                  <div className="text-[#ffffff]">Kirim Permintaan</div>
                </button>
                <div className="flex justify-center mb-6 text-[14px] mt-4 text-grayWalangDark">
                  Sudah memiliki akun? <a href="/login" className="text-greenWalang pl-1">Login</a>
                </div>
              </form>

            </div>

          </div>
        </div>
      </div>

    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  item: {
    flex: 1
  },
  logo: {
    width: 125,
    height: 130,
    position: 'absolute',
    top: '40%',
    left: '40%'
  },
  line: {
    width: '50%',
    height: '100%',
  },
  input: {
    height: 40,
    borderWidth: 1,
    padding: 10,
    borderRadius: 5,
  }
});

export default WithNavigate;