import React, { Component } from 'react';
import {
    Platform, View, StyleSheet, TextInput,
    Text, Image, StatusBar, BackHandler, Button
} from 'react-native';
import { useNavigate } from 'react-router-dom';
import ReactStars from "react-rating-stars-component";

class CardKategori extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: this.props.data
        }
        //console.log(this.props.data);
    }

    goToDetail = async () => {
        //console.log(this.props);
        if(this.props.data.id != undefined) {
            //console.log("goToDetail" + this.props.data.id);
            if (this.props.section!="") {
                this.props.navigate('/'+ this.props.beranda + '/' + this.props.section +'/' + this.props.data.id);
            } else {
                this.props.navigate('/'+ this.props.beranda + '/CAT' + this.props.kategori +'/' + this.props.data.id);
            }
        } else {
            //console.log("goToDetail = " + this.props.data.kode_konten);
            this.props.navigate('/details/' + this.props.data.kode_konten);
        }
        /*
        if(this.props.data.id != undefined) {
            console.log("goToDetail" + this.props.data.id);
            this.props.navigate('/etalaseDetails/' + this.props.data.id);
        } else {
            console.log("goToDetail = " + this.props.data.kode_konten);
            this.props.navigate('/details/' + this.props.data.kode_konten);
        }*/
        
    }

    render() {
        return (
            <>
            <div className="hidden lg:block">
            <div className="inline-block px-4 pb-[30px]" onClick={this.goToDetail}>
                <div className="w-32 max-w-xs overflow-hidden rounded-[6px] shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                    <img className='w-32 h-[190px] cursor-pointer' src={this.props.data.cover} alt="" />
                </div>
                <div className="text-sm font-semibold mt-4 dark:text-black line-clamp-2">{this.props.data.judul}</div>
                <div className="text-[10px] mt-1 text-[#999999] line-clamp-1">{this.props.data.isbn}</div>
                <div className="text-[10px] mt-1 text-[#999999] line-clamp-1">{this.props.data.penulis}</div>
                <div className="text-[10px] mt-1 text-[#999999] line-clamp-1">{this.props.data.penerbit}</div>
                
                {(() => {
                if (this.props.data.formatted_harga_dasar == this.props.data.formatted_harga_akhir) {
                    return (
                        <div>
                            <div className="ml-2 text-base font-semibold text-greenWalang">Rp {this.props.data.formatted_harga_akhir}</div>
                            <div className="ml-2 line-through text-[10px] font-semibold text-[#999999]"></div>
                        </div>
                    )
                } else {
                    return (
                    <div>
                        <div className="ml-2 text-sm font-semibold text-greenWalang">Rp {this.props.data.formatted_harga_akhir}</div>
                        <div className="ml-2 line-through text-[10px] font-semibold text-[#999999]">Rp {this.state.data.formatted_harga_dasar}</div>

                    </div>
                    )
                }
                })()}
                {this.state.data.rating>0 ?<>
                <div className="flex">
                <div>
                <ReactStars
                count={1}
                size={14}
                value={parseInt( this.state.data.rating)}
                edit={false}
                activeColor="#ffd700"
                /></div><div className="text-[14px] pl-2">({this.state.data.rating})</div>
                </div>
                </>:<div></div>}
                
            </div>
            </div>

            <div className="block lg:hidden">
            <div className="inline-block w-[118px] pr-4 pb-[30px]" onClick={this.goToDetail}>
                <div className="w-[118px] max-w-xs overflow-hidden rounded-[6px] shadow-md bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out">
                    <img className='w-[118px] h-40 cursor-pointer' src={this.props.data.cover} alt="" />
                </div>
                <div className="text-sm font-semibold mt-4 dark:text-black line-clamp-2">{this.props.data.judul}</div>
                <div className="text-[9px] mt-1 text-[#999999] line-clamp-1">{this.props.data.isbn}</div>
                <div className="text-[9px] mt-1 text-[#999999] line-clamp-1">{this.props.data.penulis}</div>
                <div className="text-[9px] mt-1 text-[#999999] line-clamp-1">{this.props.data.penerbit}</div>
                
                {(() => {
                if (this.props.data.formatted_harga_dasar == this.props.data.formatted_harga_akhir) {
                    return (
                        <div>
                            <div className="ml-2 text-sm font-semibold text-greenWalang">Rp {this.props.data.formatted_harga_akhir}</div>
                            <div className="ml-2 line-through text-[9px] font-semibold text-[#999999]"></div>
                        </div>
                    )
                } else {
                    return (
                    <div>
                        <div className="ml-2 text-sm font-semibold text-greenWalang">Rp {this.props.data.formatted_harga_akhir}</div>
                        <div className="ml-2 line-through text-[9px] font-semibold text-[#999999]">Rp {this.state.data.formatted_harga_dasar}</div>

                    </div>
                    )
                }
                })()}
                {this.state.data.rating>0 ?<>
                <div className="flex">
                <div>
                <ReactStars
                count={1}
                size={14}
                value={parseInt(this.state.data.rating)}
                edit={false}
                activeColor="#ffd700"
                /></div><div className="text-[14px] pl-2">({this.state.data.rating})</div>
                </div>
                </>:<div></div>}
                
            </div>
            </div>
            </>
        )
    }
}

var styles = StyleSheet.create({
    caption: {
        fontFamily: "Montserrat-Regular",
        fontSize: 16,
        marginBottom: 12,
        color: "#212A3B"
    },
    placeholder: {
        fontFamily: "Montserrat-Regular",
        fontSize: 14
    },
    input: {
        flex: 1,
        backgroundColor: "#F6F6F6",
        borderRadius: 10,
        fontFamily: "Montserrat-Regular",
        fontSize: 14,
        padding: 12,
        color: '#34495e'
    },
    error: {
        fontFamily: "Montserrat-Regular",
        fontSize: 14,
        color: "#e74c3c",
        alignSelf: "flex-end"
    }


})

// export default CardKategori;
function WithNavigate(props) {
    let navigate = useNavigate();
    return <CardKategori {...props} navigate={navigate} />
  }
  
  export default WithNavigate;
