import { Component } from 'react';
import BookCover from '../assets/book_cover_full.svg';
import IconCart from '../assets/ic_cart.svg';

import CardCheckboxKategori from '../components/CardCheckboxKategori';
import CardPromoted from '../components/CardPromoted';
import CardKategori from '../components/CardKategori';

import { clientSettings, executeGet, executePost } from '../functions/Kubuku';
import { GET_TOP1 } from '../api';
import ModalLoading from "../components/ModalLoading";

import {
    useParams,
    useNavigate
} from "react-router-dom";

import Pagination from '../components/Pagination';
let PageSize = 24;

function WithNavigate(props) {
    let navigate = useNavigate();
    return <Top1 {...props} navigate={navigate} params={useParams()} />
}

class Top1 extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isModal: false, //popup loading
            showMain: false,
            currentPage: 1,
            param: this.props.params,
            data: [
                {
                    "judul": "Genre",
                    "listKategori": [
                        {
                            "kategori": "Action & Adventure"
                        },
                        {
                            "kategori": "Activity"
                        },
                        {
                            "kategori": "Animal"
                        },
                        {
                            "kategori": "Anthologies"
                        },
                        {
                            "kategori": "Art"
                        },
                        {
                            "kategori": "Classic"
                        },
                        {
                            "kategori": "Cultural"
                        },
                        {
                            "kategori": "Historical"
                        },
                    ]
                },
                {
                    "judul": "Authors",
                    "listKategori": [
                        {
                            "kategori": "Action & Adventure"
                        },
                        {
                            "kategori": "Art"
                        },
                        {
                            "kategori": "Classic"
                        },
                        {
                            "kategori": "Cultural"
                        },
                        {
                            "kategori": "Historical"
                        },
                    ]
                }
            ],
            contentData: "",
            title: ''
        }
    }

    componentDidMount = () => {
        this.getSection();
        // const localStr = localStorage.getItem('dataKategori');
        // console.log("localStr data = " + localStr);
        // const localStrParse = JSON.parse(localStr);
        // this.setState({ contentData : localStrParse.data})
        // this.setState({ title: localStrParse.judul})
        // console.log("localStr dataKategori = " + JSON.stringify(this.state.contentData));
    }

    getSection = async () => {
        this.setState({ isLoading: true });
        try {
            const json = await executeGet(GET_TOP1 + "/"+ this.state.param.id)
            //console.log("etalase = " + JSON.stringify(json));
            if (json.code == 200) {
                this.setState({
                    isLoading: false, 
                    showMain: true,
                    title: json.data.judul, 
                    contentData: json.data.data
                },()=>{ 
                    //console.log("contentData = " + this.state.contentData);
                });
                
                // this.setState({ title: json.judul, contentData: json.data });
                // console.log("contentData = " + this.state.contentData);
                // if (this.state.contentData != "") {
                //     this.setState({ isLoading: false, showMain: true });
                //     // this.setState({ title: json.judul, contentData: json.data });
                // }

            } else if (json.code == 500 || json.code == 505) {
                window.location.href = window.location.origin +"/login";
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
    }

    selectedChange = (event) => {
        //console.log("selectedChange = " + event.target.value);
        
        if(event.target.value == 0) {
            let dataRes = this.state.contentData.reverse(function(a, b){
                return a.harga_akhir - b.harga_akhir;
            });
            this.setState({ contentData: dataRes });
        } else if(event.target.value == 1) {
            let dataRes = this.state.contentData.sort(function(a, b){
                return a.harga_akhir - b.harga_akhir;
            });
            this.setState({ contentData: dataRes });
        }
        // this.setState({ value: event.target.value });
    };

    currentTableData = () => {
        const firstPageIndex = (this.state.currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return this.state.contentData.slice(firstPageIndex, lastPageIndex);
    };

    render() {
        return (
            <div>
                {
                    this.state.isLoading &&
                    <ModalLoading />
                }
                {
                    this.state.showMain &&
                    <div>
                        <div className="hidden lg:block">
                            <div className="pt-[78px] pb-2 px-8 xl:px-28 dark:bg-white">
                                <div>
                                    {/* Home / Kategori */}
                                    <nav className="flex" aria-label="Breadcrumb">
                                        <ol className="inline-flex items-center space-x-1 lg:space-x-3">
                                            <li className="inline-flex items-center">
                                                <a href="/" className="inline-flex items-center text-sm font-medium text-greenWalang hover:text-greenWalang hover:opacity-30 dark:text-greenWalang dark:hover:text-greenWalang">
                                                    Home
                                    </a>
                                            </li>
                                            <li aria-current="page">
                                                <div className="flex items-center">
                                                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                                    <span className="ml-1 text-sm font-medium text-gray-400 lg:ml-2 dark:text-gray-500">{this.state.title}</span>
                                                </div>
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="py-3 px-8 xl:px-28 bg-greenWalang bg-opacity-30 text-2xl text-greenWalang">
                                {this.state.title}
                            </div>
                            <div className="pt-2 px-8 xl:px-28 dark:bg-white">
                                <div className="flex pb-[50px] space-x-7">
                                    {/* <div className="w-3/12">
                                        {this.state.data.map(item => {
                                            return (
                                                <CardCheckboxKategori
                                                    data={item}
                                                />
                                            )
                                        })}
                                        <div className="border rounded-2xl p-[30px] mb-[30px]">
                                            <div className="text=[20px] font-semibold pb-[15px] border-b border-grayWalang">Filter by Price</div>
                                        </div>
                                    </div> */}
                                    <div className="w-full">
                                        <div className="flex justify-end pt-2">
                                            <div className="pr-3 dark:text-black flex items-center">Sort by:</div>
                                            <select className="text-black bg-white hover:bg-white focus:ring-1 focus:outline-none focus:ring-grayWalang font-medium rounded-full border border-grayWalang dark:border-black text-sm px-4 py-1 text-center inline-flex items-center dark:bg-white dark:hover:bg-white dark:focus:ring-grayWalang" onChange={this.selectedChange}>
                                                <option value="0">harga tertinggi - terendah</option>
                                                <option value="1">harga terendah - tertinggi</option>
                                                {/* <option value="2">terpopuler - tidak terpopuler</option>
                                                <option value="3">tidak terpopuler - terpopuler</option> */}
                                            </select>
                                        </div>
                                        <div className="grid grid-cols-5 lg:grid-cols-6 xl:grid-cols-8 mb-1 py-[30px]">
                                            {this.currentTableData().map(item => {
                                                return (
                                                    <CardPromoted
                                                        data={item}
                                                    />
                                                )
                                            })}
                                        </div>
                                        <div className="flex justify-center">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={this.state.currentPage}
                                                totalCount={this.state.contentData.length}
                                                pageSize={PageSize}
                                                onPageChange={
                                                    page => this.setState({ currentPage: page })
                                                }
                                            />
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>

                        <div className="block lg:hidden">
                            <div className="pt-[64px] px-2 dark:bg-white">
                                <div className="flex pb-[50px] space-x-7">
                                    <div className="w-full h-[75%]">
                                        <div className="flex justify-end">
                                            <div className="pr-3 dark:text-black">Sort by:</div>
                                            <button id="dropdownDefault" data-dropdown-toggle="dropdown" className="text-black bg-white hover:bg-white focus:ring-1 focus:outline-none focus:ring-grayWalang font-medium rounded-full border-grayWalang dark:border-black text-sm px-4 py-1 text-center inline-flex items-center dark:bg-white dark:hover:bg-white dark:focus:ring-grayWalang" type="button">harga tertinggi - terendah<svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path></svg></button>
                                            <div id="dropdown" className="z-10 hidden bg-white divide-y divide-gray-100 rounded shadow w-44 dark:bg-gray-50">
                                                <ul className="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefault">
                                                    <li>
                                                        <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dashboard</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Settings</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Earnings</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Sign out</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="grid pl-2 grid-cols-3 md:grid-cols-5 lg:grid-cols-6 py-[30px]">
                                            {this.currentTableData().map(item => {
                                                return (
                                                    <CardPromoted
                                                        data={item}
                                                    />
                                                )
                                            })}
                                        </div>
                                        <div className="flex justify-center pb-16">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={this.state.currentPage}
                                                totalCount={this.state.contentData.length}
                                                pageSize={PageSize}
                                                onPageChange={
                                                    page => this.setState({ currentPage: page })
                                                }
                                            />
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>

                }

            </div>
        );

    }
}

export default WithNavigate;
