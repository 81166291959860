import { Component } from 'react';

import {createNewHeaders, updateHeaders, clientSettings, executeGet, executePost } from '../../functions/Kubuku';
import { LIST_VOUCHER, CUD_OUTLET } from '../../api';

import QRCode from "react-qr-code";
import ModalSettings from '../../components/ModalSettings';
import ModalLoading from "../../components/ModalLoading";
import ModalPopup from "../../components/ModalPopup";

import {
    useParams,
    useNavigate
} from "react-router-dom";

import Pagination from '../../components/Pagination';
import { connect } from 'react-redux';
import { bukuPromosiAction } from '../../redux/actions';

let PageSize = 100;

class Promotion extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            showMain: false,
            showModal: false,
            isLoading: false,
            showLoading: false,
            data: '',
            msg: '',
            flagAktif: true,
            flagTidakAktif: false,
            flagKadaluarsa: false,
            flagGagal: false,
            dataAktif: '',
            dataTidakAktif: '',
            dataKadaluarsa: ''
        }
    }

    componentDidMount() {
        // const script = document.createElement("script");
        // script.src = "https://unpkg.com/flowbite@1.4.5/dist/datepicker.js";
        // script.async = true;
        // script.onload = () => this.scriptLoaded();

        // document.body.appendChild(script);
        this.getPromotion();

        // const datepickerEl = document.getElementById('datepickerId');
        // new Datepicker(datepickerEl, {
        //     // options
        // }); 
    }

    scriptLoaded() {
        console.log("script load");
        // window.A.sort();
    }

    getPromotion = async () => {
        try {
            const flagOpen = localStorage.getItem('flagOpen');
            console.log("flagOpen = " + flagOpen);
            if(flagOpen != "1") {
                createNewHeaders('', ''); 
            } else {
                updateHeaders('', ''); 
            }
            const json = await executeGet(LIST_VOUCHER);
            console.log("Vocher = " + JSON.stringify(json));
            if (json.code == 200) {
                this.setState({ data: json.promosi_aktif, dataAktif: json.promosi_aktif, dataTidakAktif: json.promosi_belum_aktif, dataKadaluarsa: json.promosi_kadaluarsa, showMain: true });
                console.log("jumlah_data = " + this.state.dataAktif.length);
            } else if (json.code == 404) {
                //masukkan tgl lahir
                // this.setState({ flagData: false, msg: json.msg });
            } else if (json.code == 500 || json.code == 505) {
                // window.location.href = window.location.origin + "/login";
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
    }


    deleteOutlet = async (param) => {
        console.log("param del = " + JSON.stringify(param));
        this.setState({ isLoading: true });
        try {
            let formData = new FormData();
            formData.append("id", param.id);
            formData.append("judul", param.judul);
            formData.append("deskripsi", param.deskripsi);
            formData.append("aktif", 9);

            const json = await executePost(CUD_OUTLET, formData);
            console.log("delete_outlet = " + JSON.stringify(json));
            if (json.code == 200) {
                this.setState({ isLoading: false, isModal: true, msg: 'Berhasil Delete Outlet' });
                this.getOutlet();
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
    }

    currentTableData = () => {
        const firstPageIndex = (this.state.currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return this.state.data.slice(firstPageIndex, lastPageIndex);
    };

    currencyFormat(num) {
        return 'Rp. ' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    promosiAktif = () => {
        this.setState({ data: this.state.dataAktif, flagAktif: true, flagTidakAktif: false, flagKadaluarsa: false, flagGagal: false });
        // console.log("belumBayar = " + JSON.stringify(dataRes))
    }

    promosiTidakAktif = () => {
        this.setState({ data: this.state.dataTidakAktif, flagAktif: false, flagTidakAktif: true, flagKadaluarsa: false, flagGagal: false });
        // console.log("belumBayar = " + JSON.stringify(dataRes))
    }

    promosiGagalAktif = () => {
        this.setState({ data: this.state.dataTidakAktif, flagAktif: false, flagTidakAktif: false, flagKadaluarsa: false, flagGagal: true });
        // console.log("belumBayar = " + JSON.stringify(dataRes))
    }

    promosiKadaluarsa = () => {
        this.setState({ data: this.state.dataKadaluarsa, flagAktif: false, flagTidakAktif: false, flagKadaluarsa: true, flagGagal: false });
        // console.log("belumBayar = " + JSON.stringify(dataRes))
    }

    formatDate(value) {
        let date = new Date(value);
        const day = date.toLocaleString('default', { day: '2-digit' });
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.toLocaleString('default', { year: 'numeric' });
        return day + ' ' + month + ' ' + year;
    }

    render() {
        return (
            <div>
                {
                    this.state.isLoading &&
                    <ModalLoading />
                }
                {
                    this.state.isModal &&
                    <ModalPopup
                        type="success"
                        messages={this.state.msg}
                        press={() => { this.setState({ isModal: false }) }}
                    />
                }
                {
                    this.state.showModal &&
                    <ModalSettings
                        tipe="add_voucher"
                        close={() => { this.setState({ showModal: false }) }}
                    />
                }
                {
                    this.state.showMain &&
                    <div className="dark:bg-white md:border md:rounded-2xl md:p-[30px]">
                        <div className="text-[24px] font-semibold dark:text-black">PROMO KODE VOUCHER</div>
                        <div className="pt-8">
                            <button onClick={() => { this.setState({ showModal: true }) }} type="button" className="bg-greenWalang font-medium rounded-full text-sm px-5 py-3 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 mb-4 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                <div className="text-[#ffffff] pr-4">
                                    Buat Kode Voucher
                                </div>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 17V7" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                                    <path d="M7 12L17 12" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
                                </svg>
                            </button>
                            <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400">
                                <li className="mr-2">
                                    <a onClick={() => this.promosiAktif()} className={this.state.flagAktif ? "cursor-pointer inline-block p-4 text-greenWalang bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500" : "cursor-pointer inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"}>Aktif</a>
                                </li>
                                <li className="mr-2">
                                    <a onClick={() => this.promosiTidakAktif()} className={this.state.flagTidakAktif ? "cursor-pointer inline-block p-4 text-greenWalang bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500" : "cursor-pointer inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"}>Belum Aktif</a>
                                </li>
                                <li className="mr-2">
                                    <a onClick={() => this.promosiKadaluarsa()} className={this.state.flagKadaluarsa ? "cursor-pointer inline-block p-4 text-greenWalang bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500" : "cursor-pointer inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"}>Kadaluarsa</a>
                                </li>
                                {/* <li className="mr-2">
                                    <a onClick={() => this.promosiGagalAktif()} className={this.state.flagGagal ? "cursor-pointer inline-block p-4 text-greenWalang bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500" : "cursor-pointer inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"}>Gagal Aktif</a>
                                </li>
                                <li className="mr-2">
                                    <a onClick={() => this.promosiPenggabungan()} className={this.state.flagGagal ? "cursor-pointer inline-block p-4 text-greenWalang bg-gray-100 rounded-t-lg active dark:bg-gray-800 dark:text-blue-500" : "cursor-pointer inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300"}>Pengajuan Promo</a>
                                </li> */}
                            </ul>
                            <div className="mt-3">
                                <div className='grid grid-cols-1 md:gap-x-2 md:gap-y-2 md:grid-cols-3 md:mx-2 px-4 md:px-0 mt-1 md:space-x-2 space-y-2 pb-16'>
                                    {this.currentTableData().map(item => {
                                        return (
                                            <div className="p-0.5" id="profile" role="tabpanel" aria-labelledby="aktif-tab" onClick={() => { this.props.navigate('/profile/setting-voucher-detail/view/' + item.id) }}>
                                                <div className="w-full p-2 bg-white shadow-md rounded-lg cursor-pointer hover:shadow-lg">
                                                    <div className="flex mb-3 p-3 border-2 border-[#2BC239] border-dashed rounded-lg bg-[#2BC239] bg-opacity-20">
                                                        <div className="w-full ml-2 p-2 border-l-2 border-[#2BC239] border-dashed text-[#2BC239] overflow-hidden">
                                                        <h3 className="font-semibold truncate">{item.kode_voucher}</h3>
                                                            <div className="text-sm truncate">{item.judul}</div>
                                                            <div className="text-sm truncate">{item.deskripsi}</div>
                                                        </div>
                                                    </div>
                                                    {/* <h3 className="mb-1 font-semibold">Back to School</h3> */}
                                                    <div className="mb-1 text-gray-400 text-[12px]">Diskon {Number(item.diskon_persen)}% s/d { this.currencyFormat(item.diskon_maksimal) }</div>
                                                    <div className="text-[14px]">{this.formatDate(item.tgl_mulai)} - {this.formatDate(item.tgl_akhir)}</div>
                                                </div>
                                            </div>
                                            
                                        )
                                    })}

                                </div>
                                <div className="flex justify-center">
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={this.state.currentPage}
                                        totalCount={this.state.data.length}
                                        pageSize={PageSize}
                                        onPageChange={
                                            page => this.setState({ currentPage: page })
                                        }
                                    />
                                </div>


                            </div>
                        </div>

                        {/* <form>
            <div className="mb-6">
              <label for="fullname" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Nama Lengkap</label>
              <input type="text" id="fullname" value={this.state.nama} onChange={event => this.setState({ nama : event.target.value})} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Masukkan Nama Lengkap" required />
            </div>
            <div className="mb-6">
              <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Email Akun</label>
              <input type="email" id="email" value={this.state.data.email} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Masukkan Email" required />
            </div>
            <div className="mb-6">
              <label for="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Kata Sandi</label>
              <input type="password" id="password" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" required />
            </div>
            <div className="mb-6">
              <label for="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">No. Handphone</label>
              <input type="text" id="phone" value={this.state.phone} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Masukkan Phone Number" required onChange={event => this.setState({ phone : event.target.value})} />
            </div>
            <div className="mb-6">
              <label for="birthdate" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Tanggal Lahir</label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path></svg>
                </div>
                <input type="date" value={this.state.tglLahir} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Select date" />
              </div>
            </div>
            <div className="flex justify-end">
              <button onClick={this.simpanProfile} type="button" className="w-4/12 bg-greenWalang font-medium rounded-full text-sm px-5 py-3 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2">
                <div className="text-[#ffffff]">
                  Simpan
                </div>
              </button>
            </div>
            
          </form>
         */}
                    </div>

                }
            </div>
        );

    }
}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <Promotion {...props} navigate={navigate} params={useParams()} />
}

const mapStateToProps = (state) => ({
    bukuPromosi: state.bukuPromosi
});

export default connect(mapStateToProps, { bukuPromosiAction })(WithNavigate);
