import './index.css';
import { BrowserRouter } from 'react-router-dom';
import Header from "./components/Header";
import Pages from "./pages";
import Footer from './components/Footer';
import Loader from './components/Loader';
import { Component } from 'react';
import { HelmetProvider } from 'react-helmet-async';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    }
    //this.konfigurasi();
    //alert("apps 1");
  }
  shouldComponentUpdate = async () => {
    //alert("Apps 3");
  }
  componentDidMount = async () => {
    /*setTimeout(() => {
       this.setState({ isLoading: false});
    }, 5000)
    */
    this.setState({ isLoading: false });
  };
  hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  render() {
    return (
      this.state.isLoading ? <Loader /> :
        <HelmetProvider>
          <BrowserRouter>
            <Header />
            <Pages />
            <Footer />
          </BrowserRouter>
        </HelmetProvider>
    );

  }

}

export default App;
