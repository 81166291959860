import { Component } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { createNewHeaders, updateHeaders, executeGet, executePost } from '../functions/Kubuku';
import { KATEGORI, LIST_KATEOGRI } from '../api';

import SectionBook from '../components/SectionBook';
import CardCheckboxKategori from '../components/CardCheckboxKategori';
import CardKategori from '../components/CardKategori';

import Pagination from '../components/Pagination';
let PageSize = 12;

class Categories extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            data: [],
            showMain: false,
            contentData: [],
            title: "Kategori"
        }
    }

    componentDidMount = () => {
        // this.getDataCategories();
        this.getListCategories();
    }

    getDataCategories = async () => {
        try {
            const flagOpen = localStorage.getItem('flagOpen');
            if (flagOpen != "1") {
                createNewHeaders('', '');
            } else {
                updateHeaders('', '');
            }
            const json = await executeGet(KATEGORI);
            console.log("kategori = " + JSON.stringify(json));
            if (json.code == 200) {
                this.setState({
                    showMain: true,
                    contentData: json.data,
                    flagData: true
                }, () => {
                    // console.log(this.state.dataTop2.Judul); 
                });

            } else if (json.code == 500 || json.code == 505) {
                window.location.href = window.location.origin + "/login";
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }

    }

    getListCategories = async () => {
        try {
            const flagOpen = localStorage.getItem('flagOpen');
            console.log("flagOpen = " + flagOpen);
            if(flagOpen != "1") {
                createNewHeaders('', ''); 
            } else {
                updateHeaders('', ''); 
            }
            
            const json = await executeGet(LIST_KATEOGRI);
            // console.log("list_kategori = " + JSON.stringify(json));
            if (json.code == 200) {
                this.setState({
                    data: json.data[0]
                }, () => {
                    console.log("getListKategori = " + JSON.stringify(this.state.data)); 
                });
            }
        } catch (error) {
            //console.log(error);
            // this.setState({ isLoading: false, isError: true, msg: "Network Error!" });
        }
    }

    currentTableData = (contentData) => {
        const firstPageIndex = (this.state.currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return contentData.slice(firstPageIndex, lastPageIndex);
    };

    render() {
        return (
            <div>
                <div className="hidden lg:block">
                    <div className="pt-[78px] pb-2 px-8 xl:px-28 dark:bg-white">
                        <div>
                            {/* Home / Kategori */}
                            <nav className="flex" aria-label="Breadcrumb">
                                <ol className="inline-flex items-center space-x-1 lg:space-x-3">
                                    <li className="inline-flex items-center">
                                        <a href="/" className="inline-flex items-center text-sm font-medium text-greenWalang hover:text-greenWalang hover:opacity-30 dark:text-greenWalang dark:hover:text-greenWalang">
                                            Home
                                    </a>
                                    </li>
                                    <li aria-current="page">
                                        <div className="flex items-center">
                                            <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                            <span className="ml-1 text-sm font-medium text-gray-400 lg:ml-2 dark:text-gray-500">Kategori</span>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="py-3 px-8 xl:px-28 bg-greenWalang bg-opacity-30 text-2xl text-greenWalang">
                        {this.state.title}
                    </div>
                    <div className="py-3 px-8 xl:px-28 dark:bg-white">
                        <div className="flex pb-[50px] space-x-7">
                            {/* <div className="w-3/12">
                                {this.state.data.map(item => {
                                    return (
                                        <CardCheckboxKategori
                                            data={item}
                                        />
                                    )
                                })}
                                <div className="border rounded-2xl p-[30px] mb-[30px]">
                                    <div className="text=[20px] font-semibold pb-[15px] border-b border-grayWalang">Filter by Price</div>

                                </div>
                            </div> */}
                            <div className="w-full">
                                <div className="grid grid-cols-4 mb-1 py-[30px]">
                                    {this.state.data.map(item => {
                                        return (
                                            <div className="flex flex-row cursor-pointer items-left justify-left p-8 border rounded-[10px] border-greenWalang m-4 bg-greenWalang bg-opacity-30" onClick={() => this.props.navigate('/categorieDetail/' + item.id_kategori)}>
                                                <div className='mr-2'>
                                                <img className='w-[48px] h-[48px] rounded-[50%]' src={item.cover}></img>
                                                    
                                                </div>
                                                <div className="h-[40px] text-center text-greenWalang items-center flex font-semibold">
                                                    {item.kategori}
                                                </div>
                                                
                                            </div>
                                        )
                                    })}
                                </div>

                            </div>
                        </div>


                    </div>
                </div>

                
            </div>
        );

    }
}

function WithNavigate(props) {
    let navigate = useNavigate();
    return <Categories {...props} navigate={navigate} params={useParams()} />
  }
  
  export default WithNavigate;
