import React, { Component } from 'react';
import {
    Platform, View, StyleSheet, TextInput,
    Text, Image, StatusBar, BackHandler, Button
} from 'react-native';

class RadioPaymentMethod extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: this.props.data
        }
    }

    render() {
        return (
            <div className="py-1">
                <div className="form-control">
                    <label className="label cursor-pointer">
                        <div className="flex">
                            <div className="w-[85px] h-[40px]">
                                <img className='w-full' src={this.state.data.paymentImage} alt="" />
                            </div>
                            <div className="pl-4 flex items-center">
                                <span className="label-text dark:text-black font-semibold">{this.state.data.paymentName}</span>
                            </div>
                        </div>
                        <input 
                            type="radio" 
                            key={this.props.key}
                            name={this.state.data.paymentMethod} 
                            className="radio radio-accent checked:bg-greenWalang" />
                    </label>
                </div>

            </div>
        )
    }
}

var styles = StyleSheet.create({
    caption: {
        fontFamily: "Montserrat-Regular",
        fontSize: 16,
        marginBottom: 12,
        color: "#212A3B"
    },
    placeholder: {
        fontFamily: "Montserrat-Regular",
        fontSize: 14
    },
    input: {
        flex: 1,
        backgroundColor: "#F6F6F6",
        borderRadius: 10,
        fontFamily: "Montserrat-Regular",
        fontSize: 14,
        padding: 12,
        color: '#34495e'
    },
    error: {
        fontFamily: "Montserrat-Regular",
        fontSize: 14,
        color: "#e74c3c",
        alignSelf: "flex-end"
    }


})

export default RadioPaymentMethod;
