import { Component } from 'react';
import QRCode from "react-qr-code";

import '../styles/animation-success.scss';

class ModalQR extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: "",
        }
    }

    componentDidMount = async () => {

    };

    render() {
        return (
            <div>
                {
                    this.props.type == "success" &&
                    <div>
                        <div className="hidden lg:block">
                            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-80 h-96 my-6 mx-auto">
                                    {/*content*/}
                                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-[100%]">
                                        <div className="relative p-6 flex-auto">
                                            <center className="pb-4">
                                                <QRCode value={this.props.messages} size="260" />
                                            </center>
                                            <span className='text-xs pb-4'>anda bisa mencantumkan QR ini di buku fisik</span>
                                            <div className="flex justify-center items-center">
                                                <button onClick={this.props.press} type="button" className="w-[50%] bg-greenWalang font-medium rounded-full text-sm px-2 py-1 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                                    <div className="text-[#ffffff] text-[15px] py-2">
                                                        OK
                                                    </div>
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>

                        </div>

                        <div className="block lg:hidden">
                            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                                <div className="relative w-80 h-96 my-6 mx-auto">
                                    {/*content*/}
                                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-[100%]">
                                        <div className="relative p-6 flex-auto">
                                            <center className="pb-4">
                                                <QRCode value={this.props.messages} size="260" />
                                            </center>
                                            <span className='text-xs pb-4'>anda bisa mencantumkan QR ini di buku fisik</span>
                                            <div className="flex justify-center items-center">
                                                <button onClick={this.props.press} type="button" className="w-[50%] bg-greenWalang font-medium rounded-full text-sm px-2 py-1 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                                    <div className="text-[#ffffff] text-[15px] py-2">
                                                        OK
                                                    </div>
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>

                        </div>
                    </div>
                }
                
            </div>
        );
    }

}

export default ModalQR;