import { Component } from 'react';
import ModalPopup from "../components/ModalPopup";

import { clientSettings, executeGet, executePost } from '../functions/Kubuku';
import { GET_WISHLIST, ADD_CART } from '../api';

import {
    useNavigate
} from "react-router-dom";

import Pagination from '../components/Pagination';
let PageSize = 10;
let page = 1;
let domain = localStorage.getItem("domain")
class TermsCondition extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            data: '',
            isModal: false,
            msg: '',
            showMain: true
        }

    }

    render() {
        return (
            <div>
                {
                    this.state.isModal &&
                    <ModalPopup
                        type="success"
                        messages={this.state.msg}
                        press={() => { this.setState({ isModal: false }) }}
                    />
                }
                { this.state.showMain &&
                    <div>
                        <div className="hidden lg:block">
                            <div className="pt-24 px-28 dark:bg-white">
                                <div className="py-7">
                                    {/* Home / Wishlist */}
                                    <nav className="flex" aria-label="Breadcrumb">
                                        <ol className="inline-flex items-center space-x-1 lg:space-x-3">
                                            <li className="inline-flex items-center">
                                                <a href="/" className="inline-flex items-center text-sm font-medium text-greenWalang hover:text-greenWalang hover:opacity-30 dark:text-greenWalang dark:hover:text-greenWalang">
                                                    Home
                                            </a>
                                            </li>
                                            <li aria-current="page">
                                                <div className="flex items-center">
                                                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                                    <span className="ml-1 text-sm font-medium text-gray-400 lg:ml-2 dark:text-gray-500">Syarat & Ketentuan</span>
                                                </div>
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="py-4 px-28 bg-greenWalang bg-opacity-30 text-2xl text-greenWalang">
                                Syarat & Ketentuan
                            </div>
                            <div className="py-10 px-28 dark:bg-white">
                                <div className="relative overflow-x-auto shadow-md sm:rounded-lg p-10">
                                    <div className="font-semibold text-xl mb-5">
                                        Syarat & Ketentuan
                                    </div>
                                    <div>
                                        Perjanjian ini mengatur hubungan antara Anda dan {domain} (“Platform”). Dengan menggunakan Platform berarti anda menyatakan setuju atas seluruh ketentuan-ketentuan ini.
                                        <br/><br/><br/>
                                        <b>A. KETENTUAN-KETENTUAN PENJUALAN</b>
                                        <br/><br/>
                                        PEMBAYARAN, PAJAK DAN KEBIJAKAN PENGEMBALIAN PEMBAYARAN
                                        <br/><br/>
                                        Anda hanya dapat melakukan pembayaran melalui metode pembayaran yang disediakan platform. Dengan melakukan pembelian, berarti anda memberikan persetujuan awal kepada Platform untuk konten yang anda beli, jumlah, harga dan biaya lain yang mungkin ada termasuk biaya yang dipungut oleh penyelenggara pembayaran. Anda setuju untuk membayar seluruh produk yang anda pilih (termasuk konten, langganan, sewa dan layanan) yang dilisensikan kepada anda melalui Platform, dan anda setuju bahwa Platform dapat menarik pembayaran melalui metode pembayaran Anda atas setiap produk (termasuk konten, langganan, sewa, dan layanan) yang dilisensikan maupun untuk setiap pembayaran tambahan (termasuk pajak maupun) sebagaimana terakumulasi sehubungan dengan Akun Anda. 
                                        <br/><br/>
                                        Anda bertanggung jawab atas pembayaran dan seluruh biaya yang dikenakan kepada Anda secara tepat waktu. 
                                        <br/><br/>
                                        Anda setuju bahwa pembayaran produk kepada platform (termasuk konten, langganan, sewa dan layanan) belum termasuk pajak pertambahan nilai, pajak barang dan layanan, pajak penjualan, pajak penggunaan, biaya meterai dan pajak lain apa pun yang terkait dan apabila pajak-pajak tersebut dikenakan pada pembelian Anda, Anda setuju untuk menanggung kewajiban untuk pengurusan dan pembayaran pajak-pajak tersebut. Termasuk biaya atas jasa pembayaran yang anda gunakan.
                                        <br/><br/>
                                        Seluruh penjualan, langganan dan penyewaan produk bersifat final.
                                        <br/><br/>
                                        Harga produk-produk yang ditawarkan melalui platform dapat berubah pada setiap saat, dan platform tidak menyediakan perlindungan harga atau pengembalian dana dalam hal suatu pemotongan harga atau penawaran promosi.
                                        <br/><br/>
                                        Apabila suatu produk menjadi tidak tersedia setelah anda melakukan transaksi pembayaran, tetapi anda belum  membaca/mendengar/menontonton, satu-satunya penggantian bagi Anda atas hal tersebut adalah pengembalian dana sesuai dengan harga yang telah dibayar untuk produk tersebut.
                                        <br/><br/>
                                        Dan apabila terdapat permasalahan teknis yang mengakibatkan kegagal pengiriman, maka penggantian yang mungkin adalah konten pengganti atau pengembalian dana sesuai dengan harga yang telah anda bayarkan atas produk tersebut. 
                                        <br/><br/><br/>
                                        VOCHER DISKON
                                        <br/><br/>
                                        Platform dapat mengeluarkan Vocher Diskon dalam jumlah terbatas baik jumlah maupun masa berlaku dalam bentuk kode digital atau kupon fisik yang dapat distribusikan melalui platform, email, media cetak dan media digital lainnya yang dapat Anda gunakan untuk mendapatkan potongan harga untuk konten tertentu.
                                        <br/><br/>
                                        Penggunaan Voucher Diskon tunduk pada ketentuan-ketentuan berikut:
                                        <br/>
                                        <li>Setiap Voucher Diskon memiliki syarat, masa berlaku dan nilai yang berbeda.</li>
                                        <li>Setiap Voucher Diskon hanya dapat digunakan satu kali oleh anda.</li>
                                        <li>Voucher Diskon hanya dapat digunakan di platform ini.</li>
                                        <li>Voucher Diskon tidak bisa digabung dengan diskon atau promosi lainnya.</li>
                                        <li>Voucher Diskon yang belum terpakai, tidak bisa ditukarkan/dikembalikan dengan uang tunai.</li>
                                        <br/><br/>
                                        Platform tidak bertanggung jawab atas kehilangan atau pencurian Voucher Diskon.
                                        <br/><br/><br/>
                                        ALAT BACA KONTEN
                                        <br/><br/>
                                        Untuk setiap konten yang Anda beli, langgan, sewa hanya dapat Anda baca/dengar/tonton dengan menggunakan platform Bookubuku yang merupakan alat baca konten digital ebook, audiobook, videobook baik untuk konten buku digital, majalah digital, koran digital, jurnal digital dan konten digital lainnya.
                                        <br/><br/>
                                        Konten yang Anda beli dapat otomatis ke daftar konten yang dapat anda klaim di platform Bookubuku dengan kesesuaian alamat email yang anda gunakan untuk pembelian, sewa, langgan dengan alamat email yang terdaftar di Bookubuku.
                                        <br/><br/>
                                        Selama Anda belum mengklaim konten yang anda beli, anda masih diijinkan untuk memberikan konten yang anda beli kepada orang lain dengan memasukan alamat email tujuan. Kesalahan pengetikan email merupakan tanggung jawab Anda, Platform tidak bertanggung jawab atas kerugian kehilangan konten akibat kesalahan pengetikan email. Anda juga dapat melakukan klaim konten dengan memasukan kode klaim yang bisa anda dapatkan dari hasil pembelian atau hasil transmisi dari orang lain.
                                        <br/><br/><br/>
                                        KODE KLAIM KONTEN
                                        <br/><br/>
                                        Kode klaim adalah kode yang anda dapatkan dari pembelian konten yang berupa sejumlah kode digital yang unik untuk Anda dan konten tertentu, yang berfungsi untuk mengklaim/mendownload konten yang ada di kode download tersebut ke dalam platform baca Bookubuku. Anda sepenuhnya bertanggung jawab atas kehilangan atau pencurian kode download.
                                        <br/><br/><br/>
                                        KONTRAK ELEKTRONIK
                                        <br/><br/>
                                        Dengan menggunakan platform ini merupakan bukti bahwa Anda memiliki kemampuan dan syarat yang cukup untuk menjadi pihak dalam perjanjian ini, dan anda memiliki kemampuan dan syarat untuk melakukan transaksi elektronik dalam platform ini. Anda juga mengakui bahwa pengajuan elektronik anda merupakan kesepakatan dan kemauan anda untuk terikat oleh dan membayar segala biaya yang muncul berdasarkan perjanjian tersebut. Kesepakatan dan kemauan anda dalam pengajuan elektronik berlaku untuk semua catatan yang terkait dengan transaksi yang anda sepakati dalam platform ini, termasuk pemberitahuan pembatalan, kebijakan, kontrak dan penerapan. Untuk mengakses dan menyimpan konten dan catatan elektronik Anda, Anda diwajibkan untuk memiliki perangkat keras dan perangkat lunak platform Bookubuku, yang merupakan tanggung jawab Anda sendiri.
                                        <br/><br/>
                                        Platform tidak bertanggung jawab terhadap kesalahan pengetikan yang anda lakukan.
                                        <br/><br/><br/>
                                        <b>B. SYARAT DAN KETENTUAN {domain}</b>
                                        <br/><br/>
                                        {domain} merupakan toko buku daring ("platform"), yang mengizinkan Anda untuk membeli, berlangganan, atau menyewa lisensi konten digital ("Konten"), ebook, audiobook, videobook seperti buku digital, majalah digital, koran digital, jurnal digital, dan publikasi lainnya, berdasarkan syarat dan ketentuan yang diatur dalam Perjanjian ini.
                                        <br/><br/><br/>
                                        PERSYARATAN PENGGUNAAN LAYANAN
                                        <br/><br/>
                                        Untuk membuat akun, anda harus telah berusia 17 tahun atau lebih, dan untuk anak-anak dibawah 17 tahun harus dibuat oleh orang tua atau wali hukum yang secara bersama-sama meninjau perjanjian ini dan memastikan anak dan orang tua memahami perjanjian ini.
                                        <br/><br/>
                                        Pengguna platform memerlukan perangkat keras uang memenuhi syarat dan koneksi internet yang bagus. Dan untuk meningkatkan pelayanan, platfrom mungkin akan melakukan update secara berkala.
                                        <br/><br/>
                                        Anda setuju bahwa pemenuhan persyaratan-persyaratan ini, yang bisa berubah dari waktu ke waktu, merupakan tanggung jawab Anda.
                                        <br/><br/><br/>
                                        AKUN ANDA
                                        <br/><br/>
                                        Untuk melakukan transaksi pembelian, sewa, langganan di platform ini, Anda diwajibkan untuk membuat sebuah akun (“Akun”) dan anda tidak dibenarkan untuk menginformasikan akun dan kunci akun anda kepada orang lain. Anda bertanggung jawab atas kerahasiaan dan keamanan akun anda sendiri dan segala aktivitas yang terjadi pada atau melakukan Akun Anda. Anda setuju untuk memberitahu Platform apabila terjadi pelanggaran keamanan apapun atas Akun Anda. Platform tidak bertanggung jawab atas segala kerugian yang muncul sebagai dari akibat penggunaan Akun Anda yang dilakukan tanpa seijin Anda.
                                        <br/><br/>
                                        Untuk melakukan pembelian, berlangganan, sewa anda diwajibkan untuk login dengan memasukan alamat email yang terdaftar dan kode keamanan yang anda miliki.
                                        <br/><br/>
                                        Anda tetap dapat mengakses dan melakukan penjelajahan tanpa login namun terbatas dan tidak dapat melakukan preview konten, tetapi anda dapat melakukan preview konten apabila anda telah memasukan tanggal lahir anda. Kesalahan memasukan tanggal lahir sehingga anda dapat mengakses preview buku yang tidak sesuai dengan umur anda merupakan tanggung jawab anda. Platform hanya melakukan pengecekan kesesuian umur konten dan tanggal lahir yang anda masukan, sedangkan kesesuain umur pengguna konten ditentukan oleh penerbit konten dan bukan merupakan tanggung jawab platform
                                        <br/><br/>
                                        Anda setuju untuk memberikan informasi akurat dan lengkap ketika Anda mendaftar, dan ketika Anda menggunakan Anda setuju untuk memperbarui Data Pendaftaran Anda agar tetap akurat dan lengkap. Anda menyetujuai Platform  untuk menyimpan dan menggunakan Data Pendaftaran yang Anda berikan untuk keperluan autentifikasi, pemeliharaan dan pengembangan.
                                        <br/><br/><br/>
                                        PENGIRIMAN DAN PENGUNDUHAN PEMBELIAN
                                        <br/><br/>
                                        Pada saat anda melakukan pembelian, penyewaan, dan langganan, anda sepakat bahwa alamat email anda juga terdaftar di platform baca Bookubuku sehingga konten yang anda beli, sewa, langgan otomatis dapat diakses di platform Bookubuku atau memberikan alamat email untuk menerima konten atau anda sepakat untuk mendapatkan KODE KLAIM yang dapat anda masukan di aplikasi pembaca Bookubuku.
                                        <br/><br/>
                                        Masa berlaku konten sewa dan berlangganan dihitung pada saat anda berhasil melakukan pembelian.
                                        <br/><br/>
                                        Apabila anda memilih untuk mendapatkan KODE KLAIM, anda wajib mengklaim konten dengan memasukan KODE KLAIM di platform pembaca tertentu untuk dapa menikmati konten yang anda beli, sewa atau langgan.
                                        <br/><br/>
                                        Anda wajib menyediakan perangkat keras dan koneksi internet yang memenuhi syarat untuk menjalankankan platform baca Bookubuku untuk membaca, mendengarkan atau menonton konten yang anda beli, sewa atau langgan dengan baik.
                                        <br/><br/>
                                        Kegagalan membaca, mendengarkan atau menontong konten akibat tidak dipenuhinya persyaratan koneksi internet dan perangkat keras sepenuhnya tanggung jawab anda.
                                        <br/><br/><br/>
                                        KONTEN BERLANGGANAN
                                        <br/><br/>
                                        Melalui Platform anda bisa mendapatkan konten atas dasar langganan. Setelah anda melakukan pembayaran biaya langganan, tidak ada pengembalian uang, pengembalian barang atau pembatalan. Untuk konten berlangganan dalam bentuk fisik seperti majalah atau koran, mungkin kami akan meminta anda untuk melengkapi data alamat pengiriman dan beberapa data yang diperlukan oleh penerbit, dan persetujuan anda untuk memberikan data tersebut kepada penerbit sebatas untuk keperluan berlangganan dan pengiriman konten. Dan anda wajib untuk membaca dan menyetujui syarat dan ketentuan majalah/koran yang akan anda langgan.
                                        <br/><br/><br/>
                                        KETERSEDIAAN KONTEN
                                        <br/><br/>
                                        Platform memiliki hak untuk mengubah pilihan-pilihan konten dan feature-feature tanpa pemberitahuan.
                                        <br/><br/>
                                        Khusus untuk konten berupa majalah digital dan koran digital jadwal ketersediaan konten ini di platform bisa jadi berbeda dengan majalah fisik dan koran fisik.
                                        <br/><br/><br/>
                                        PENGGUNAAN KONTEN
                                        <br/><br/>
                                        Platform menjual (jual, sewa, langgan) lisensi untuk mengakses Konten kepada Anda. Setelah anda beli (beli, sewa, langgan) lisensi tersebut membentuk suatu perjanjian yang mengikat secara langsung antara Anda dengan penerbit pemberilisensi dari Konten tersebut yang mengatur penggunaan Anda atas Konten tersebut. Anda setuju bahwa Layanan dan beberapa Konten mempunyai keamanan teknologi yang membatasi penggunaan Konten dan, baik Konten dibatasi oleh teknologi keamanan atau tidak, Anda harus menggunakan Konten sesuai dengan aturan pemakaian yang berlaku yang dibuat oleh Platform dan Penerbit, dan bahwa setiap penggunaan lain Konten dapat merupakan suatu pelanggaran hak cipta. Setiap teknologi keamanan merupakan bagian tidak terpisahkan dari Konten. Platform berhak untuk mengubah Aturan Pemakaian pada waktu kapan pun.
                                        <br/><br/>
                                        Anda setuju untuk tidak melanggar, menghindar, merekayasa balik, mendekompilasi, membongkar, atau dengan kata lain merusak dengan teknologi keamanan apa pun sehubungan dengan Aturan Pemakaian tersebut dengan alasan apa pun atau mencoba atau membantu orang lain untuk melakukannya.
                                        <br/><br/>
                                        Anda setuju untuk tidak mengakses Layanan dengan cara apa pun selain melalui perangkat lunak yang diperbolehkan oleh Platrom. Anda tidak boleh mengakses atau mencoba mengakses suatu Akun yang tidak diizinkan untuk diakses oleh Anda. Anda setuju untuk tidak mengubah perangkat lunak dengan cara atau bentuk apa pun, atau untuk menggunakan versi perangkat lunak yang telah diubah, untuk tujuan apa pun termasuk untuk mendapatkan akses yang tidak sah ke Layanan. Pelanggaran dari sistem atau jaringan keamanan dapat menyebabkan pertanggungjawaban perdata atau pidana.
                                        <br/><br/><br/>
                                        PENANGGUHAN AKUN
                                        <br/><br/>
                                        Platrom berhak untuk menangguhkan Akun Anda untuk waktu tertentu atau permanen apabila Anda melakukan penyalahgunaan akses dan konten
                                        <br/><br/><br/>
                                        ATURAN PENGGUNAAN
                                        <br/><br/>
                                        i. Anda diizinkan untuk menggunakan Konten hanya untuk penggunaan pribadi bersifat non komersial.
                                        <br/>
                                        ii. Anda diizinkan untuk menggunakan Konten hanya pada satu perangkat pada saat bersamaan.
                                        <br/>
                                        iii. Anda diizinkan untuk menggunakan Konten hanya di dalam perangkat lunak Bookubuku.
                                        <br/><br/>
                                        Beberapa Konten hanya dapat diunduh sekali dan tidak dapat digantikan apabila hilang dengan alasan apa pun. Hal tersebut merupakan tanggung jawab Anda untuk tidak menghilangkan, menghancurkan atau merusak Konten setelah diunduh.
                                        <br/><br/>
                                        Penyerahaan Konten tidak memindahkan kepada Anda hak komersial atau promosi apa pun atas Konten tersebut. Kemampuan untuk menggandakan atau memindah hanya merupakan suatu akomodasi kepada Anda dan tidak dapat dianggap sebagai suatu pemberian, pelepasan, atau pembatasan hak apa pun dari pemilik hak cipta dalam konten apa pun yang terkandung dalam setiap Konten. 
                                        <br/><br/><br/>
                                        MATERI PIHAK KETIGA
                                        <br/><br/>
                                        Konten dan layanan tertentu yang tersedia melalui Layanan dapat memuat materi-materi pihak ketiga. Anda setuju bahwa Platforml tidak bertanggung jawab untuk memeriksa atau mengevaluasi konten atau akurasi dan Platform tidak menjamin serta tidak memiliki kewajiban atau tanggung jawab apa pun atas setiap materi dari pihak ketiga, atau untuk materi, produk atau layanan lain pihak ketiga. Anda setuju bahwa Anda tidak akan menggunakan materi apa pun milik pihak ketiga dengan cara yang melanggar hak pihak ketiga mana pun dan bahwa Platform dalam hal apa pun tidak bertanggung jawab atas setiap penggunaan tersebut oleh Anda.
                                        <br/><br/><br/>
                                        MATERI YANG TIDAK MENYENANGKAN
                                        <br/><br/>
                                        Anda mengerti bahwa dengan menggunakan Layanan, Anda dapat menemukan materi yang menurut Anda ofensif, tidak pantas, atau tidak menyenangkan, dan bahwa konten tersebut mungkin atau mungkin tidak diidentifikasi sebagai mengandung materi eksplisit. Namun demikian, Anda setuju untuk menggunakan Layanan atas risiko Anda sendiri dan Platform tidak bertanggung jawab kepada Anda atas materi yang dapat dianggap ofensif, tidak pantas atau tidak menyenangkan. Tipe dan deskripsi Konten diberikan untuk kemudahan, dan Anda setuju bahwa Platform tidak menjamin akurasinya.
                                        <br/><br/><br/>
                                        KEKAYAAN INTELEKTUAL
                                        <br/><br/>
                                        Anda setuju bahwa Layanan, termasuk namun tidak terbatas pada Konten, grafis, antarmuka pengguna, cuplikan audio, cuplikan video, konten yang diedit, dan naskah dan perangkat lunak yang digunakan untuk mengimplementasikan Layanan, mengandung informasi dan materi hak milik yang dimiliki oleh Platform dan/atau pemberi lisensinya, dan dilindungi oleh undang-undang hak kekayaan intelektual dan undang-undang lain, termasuk namun tidak terbatas pada hak cipta. Anda setuju bahwa Anda tidak akan menggunakan informasi atau materi hak milik tersebut dalam hal apa pun kecuali untuk penggunaan Layanan sesuai dengan Perjanjian ini. Tidak ada bagian dari Layanan yang dapat diproduksi kembali dalam bentuk apa pun atau dengan cara apa pun, kecuali sebagaimana diperbolehkan secara tegas di persyaratan ini. Anda setuju untuk tidak mengubah, menyewakan, meminjamkan, menjual, mendistribusikan, atau menciptakan kerjaan derifatif berdasarkan Layanan dengan cara apa pun, dan Anda tidak akan mengeksploitasi Layanan dengan cara apa pun yang tidak diizinkan, termasuk namun tidak terbatas pada, dengan melanggar atau membebani kapasitas jaringan.
                                        <br/><br/>
                                        Meskipun ada ketentuan lain dalam Perjanjian ini, Platform dan pemberi lisensinya berhak untuk mengubah, menghentikan sementara, menghapus atau menonaktifkan akses ke Konten, konten atau materi lain yang merupakan suatu bagian dari Layanan pada setiap saat tanpa pemberitahuan. Dalam hal apa pun juga, Platform tidak berkewajiban karena melakukan perubahan-perubahan ini.
                                        <br/><br/>
                                        Seluruh hak cipta dalam dan atas Layanan (termasuk kompilasi konten, postingan, tautan ke sumber internet lain, dan deskripsi sumber tersebut) dan perangkat lunak terkait dimiliki oleh Platform dan/atau pemberi lisensinya, yang memiliki seluruh haknya berdasarkan hukum dan ekuitas. 
                                        <br/><br/><br/>
                                        PENGAKHIRAN
                                        <br/><br/>
                                        Jika Anda gagal mematuhi, atau Platform menduga bahwa Anda telah gagal mematuhi, ketentuan mana pun dalam Perjanjian ini, Platrom berdasarkan pertimbangannya sendiri, tanpa pemberitahuan kepada Anda dapat:(i) mengakhiri Perjanjian ini dan/atau Akun Anda, dan Anda akan tetap bertanggung jawab untuk seluruh jumlah tertagih dalam Akun Anda hingga tanggal pengakhiran; dan/atau (ii) mengakhiri lisensi atas perangkat lunak; dan/atau (iii) menghalangi akses ke Layanan (atau bagian apa pun dari Layanan).
                                        <br/><br/>
                                        Platrform berhak untuk memodifikasi, menangguhkan atau menghentikan Layanan (mau pun bagian atau konten apa pun dari Layanan) pada setiap saat dengan maupun tanpa pemberitahuan kepada Anda, dan Platform tidak akan bertanggung jawab kepada Anda atau pihak ketiga mana pun karena melaksanakan hak ini.
                                        <br/><br/><br/>
                                        PEMBATASAN JAMINAN BATASAN TANGGUNG JAWAB
                                        <br/><br/>
                                        Platrom tidak menjamin atau menyatakan bahwa pemakaian layanan oleh Anda akan bebas dari gangguan maupun kesalahan, dan anda setuju bahwa dari waktu ke waktu Platform menghapus layanan untuk waktu tertentu atau permanen. Anda setuju bahwa penggunaan atas atau ketidakmampuan anda untuk menggunakan layanan sepenuhnya resiko anda.
                                        <br/><br/><br/>
                                        PELEPASAN DAN GANTI RUGI
                                        <br/><br/>
                                        Dengan menggunakan layanan, Anda setuju untuk membebaskan Platform dan pemberi lisensi dari segal klaim yang muncul karena pelanggaran Anda terhadap perjanjian ini.
                                        <br/><br/><br/>
                                        PERUBAHAN
                                        <br/><br/>
                                        Platrom berhak untuk, pada setiap waktu, mengubah Perjanjian ini dan untuk menerapkan syarat dan ketentuan baru atau tambahan atas pemakaian Layanan oleh Anda. Perubahan serta syarat dan ketentuan tambahan tersebut akan segera berlaku efektif dan dimasukkan ke dalam Perjanjian ini. Pemakaian Anda yang berkelanjutan terhadap Layanan akan dianggap sebagai persetujuan Anda untuk tunduk pada perubahan serta syarat dan ketentuan baru atau tambahan tersebut.
                                        <br/><br/><br/>
                                        LAIN-LAIN
                                        <br/><br/>
                                        Perjanjian ini merupakan keseluruhan perjanjian antara Anda dengan Platform dan mengatur pemakaian Layanan oleh Anda. Anda mungkin juga tunduk pada syarat dan ketentuan tambahan yang mungkin berlaku saat Anda menggunakan layanan afiliasi, konten pihak ketiga, atau perangkat lunak pihak ketiga. 
                                        <br/><br/>
                                        Platform dapat menyampaikan pemberitahuan sehubungan dengan Layanan kepada Anda dengan cara mengirimkan email ke alamat email yang terdaftar di Akun Anda. Pemberitahuan akan berlaku efektif pada saat itu juga.
                                        <br/><br/>
                                        Platform memiliki hak untuk mengambil langkah-langkah yang diyakinii wajar diperlukan atau pantas untuk menegakkan dan/atau memeriksa kepatuhan terhadap ketentuan apa pun dari Perjanjian ini. Anda setuju bahwa Platform  berhak, tanpa kewajiban kepada Anda, untuk mengungkapkan Data Registrasi dan/atau informasi Akun apa pun kepada aparat penegak hukum, pejabat pemerintahan dan/atau pihak ketiga.
                                        <br/><br/>
                                        <br/><br/>
                                        Update: 6 Juni 2022
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="block lg:hidden">
                            <div className="pt-32 pb-10 px-4 dark:bg-white">
                                <div className="relative overflow-x-auto shadow-md sm:rounded-lg p-10">
                                    <div className="font-semibold text-xl mb-5">
                                        Tentang Kami
                                    </div>
                                    <div>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec hendrerit sed felis vitae vehicula. Pellentesque ut sodales enim, in mollis libero. Aenean nulla risus, scelerisque vel augue sollicitudin, gravida dapibus tortor. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur interdum consequat eros sit amet ultricies. Aenean vestibulum, augue et vulputate finibus, sapien tellus volutpat tellus, sed fermentum tortor dui ac turpis. Praesent maximus finibus lorem, id tristique mi ultrices a.
                                        <br/><br/>
                                        Duis scelerisque consectetur tortor, nec faucibus leo fringilla sit amet. Curabitur felis velit, ultrices ac aliquam a, dictum a risus. Nulla malesuada odio eget egestas tincidunt. Aenean aliquam dui tristique, semper lectus ac, rhoncus elit. Donec ac scelerisque nunc. Nam id congue enim. In hac habitasse platea dictumst. In efficitur vitae risus sed placerat. Praesent vel dui imperdiet, ultrices massa ut, facilisis magna. Aliquam facilisis vehicula varius. Pellentesque vitae auctor nulla. Sed sagittis metus sit amet tristique porta. Mauris aliquam mattis vulputate.
                                        <br/><br/>
                                        Nulla luctus efficitur est. Etiam iaculis augue non libero sollicitudin iaculis. Morbi facilisis iaculis pharetra. Etiam ut dui tincidunt, sagittis ex quis, dignissim risus. Aliquam eget porttitor odio, a sodales leo. Vivamus vitae mauris a urna vestibulum ullamcorper. Nunc finibus sapien tortor, in ullamcorper nulla sagittis finibus. Maecenas feugiat ipsum et accumsan pretium. Vestibulum sit amet egestas metus. Nulla sodales ligula neque, eu auctor nunc consectetur nec.
                                        <br/><br/>
                                        Nulla luctus efficitur est. Etiam iaculis augue non libero sollicitudin iaculis. Morbi facilisis iaculis pharetra. Etiam ut dui tincidunt, sagittis ex quis, dignissim risus. Aliquam eget porttitor odio, a sodales leo. Vivamus vitae mauris a urna vestibulum ullamcorper. Nunc finibus sapien tortor, in ullamcorper nulla sagittis finibus. Maecenas feugiat ipsum et accumsan pretium. Vestibulum sit amet egestas metus. Nulla sodales ligula neque, eu auctor nunc consectetur nec.
                                    </div>
                                </div>
                                
                            </div>

                        </div>
                    </div>

                }

            </div>
        );

    }
}

export default TermsCondition;
