import { Component } from 'react';
import { clientSettings, executeGet, executePost } from '../functions/Kubuku';

import '../styles/animation-success.scss';

class ModalConfirmation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: "",
        }
    }

    componentDidMount = async () => {

    };

    render() {
        return (
            <div>
                <div>
                    <div className="hidden lg:block">
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-[30%] h-[30%] my-6 mx-auto">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-[100%]">
                                    <div className="relative p-6 flex-auto">
                                        <div className="text-center p-4 font-semibold text-[18px]">{this.props.messages}</div>
                                        <div className="flex justify-center items-center">
                                            <button onClick={this.props.cancel} type="button" className="w-[50%] bg-[#E80F0F] font-medium rounded-full text-sm px-2 py-1 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2">
                                                <div className="text-[#ffffff] text-[15px] py-2">
                                                    Batal
                                                </div>
                                            </button>
                                            <button onClick={this.props.press} type="button" className="w-[50%] bg-greenWalang font-medium rounded-full text-sm px-2 py-1 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                                <div className="text-[#ffffff] text-[20px] py-2">
                                                    OK
                                                </div>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>

                    </div>

                    <div className="block lg:hidden">
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-[90%] h-[47%] my-6 mx-auto">
                                {/*content*/}
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none h-[100%]">
                                    <div className="relative p-6 flex-auto">
                                        <div className="text-center p-4 font-semibold text-[15px]">{this.props.messages}</div>
                                        <div className="flex justify-center items-center">
                                            <button onClick={this.props.cancel} type="button" className="w-[50%] bg-greenWalang font-medium rounded-full text-sm px-2 py-1 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2">
                                                <div className="text-[#ffffff] text-[15px] py-2">
                                                    Batal
                                                </div>
                                            </button>
                                            <button onClick={this.props.press} type="button" className="w-[50%] bg-greenWalang font-medium rounded-full text-sm px-2 py-1 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                                <div className="text-[#ffffff] text-[15px] py-2">
                                                    OK
                                                </div>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>

                    </div>
                </div>

            </div>
        );
    }

}

export default ModalConfirmation;