import { Component } from 'react';
import BookCover from '../assets/book_cover_full.svg';
import IconCart from '../assets/ic_cart.svg';

import CheckboxCart from "../components/CheckboxCart";

import { executeGet, executePost } from '../functions/Kubuku';
import { GET_CART, DELETE_CART, PAYMENT_METHOD, CHECKOUT, PROFILE, ADD_WISHLIST, VALIDASI_VOUCHER } from '../api';
import ModalLoading from "../components/ModalLoading";
import ModalPopup from "../components/ModalPopup";
import Pagination from '../components/Pagination';
import RadioPaymentMethod from '../components/RadioPaymentMethod';
import ModalConfirmation from '../components/ModalConfirmation';
let PageSize = 9;

class Cart extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            currentPage: 1,
            showModal: false,
            msg: '',
            flagData: false,
            detail: '',
            data: [
                {
                    "groupId": 1,
                    "id": 1,
                    "title": "Piranesi",
                    "cover": <BookCover />,
                    "penulis": "Susana Clarke",
                    "formatted_harga_awal": "120,000",
                    "formatted_harga_akhir": "90,000"
                },
                {
                    "groupId": 1,
                    "id": 2,
                    "title": "Heartland Stars",
                    "cover": <BookCover />,
                    "penulis": "Susana Clarke",
                    "formatted_harga_awal": "120,000",
                    "formatted_harga_akhir": "90,000"
                }
                // {
                //     "penerbit": "Graha Ilmu",
                //     "details": [
                //         {
                //             "groupId": 1, 
                //             "id": 1,
                //             "title": "Piranesi",
                //             "cover": <BookCover/>,
                //             "penulis": "Susana Clarke",
                //             "formatted_harga_awal": "Rp 90,000",
                //             "formatted_harga_akhir": "Rp 120,000"
                //         },
                //         {
                //             "groupId": 1, 
                //             "id": 2,
                //             "title": "Heartland Stars",
                //             "cover": <BookCover/>,
                //             "penulis": "Susana Clarke",
                //             "formatted_harga_awal": "Rp 90,000",
                //             "formatted_harga_akhir": "Rp 120,000"
                //         },
                //     ]
                // },
                // {
                //     "penerbit": "Sumber Ilmu",
                //     "details": [
                //         {
                //             "groupId": 2, 
                //             "id": 1,
                //             "title": "Buku Pertama",
                //             "cover": <BookCover/>,
                //             "penulis": "Susana Clarke",
                //             "formatted_harga_awal": "Rp 90,000",
                //             "formatted_harga_akhir": "Rp 120,000"
                //         }
                //     ]
                // }
            ],
            paymentMethod: '',
            selectedOption: '',
            selectedFeeOption: '',
            isModalConfirmation: false,
            kode_konten: '',
            isError: ''
        }
    }

    componentDidMount() {
        this.getProfile();
    }

    getProfile = async () => {
        try {
            const json = await executeGet(PROFILE);
            console.log("profile = " + JSON.stringify(json));
            if (json.code == 200) {
                this.setState({ email: json.data.email });
                this.getCart();
            } else if (json.code == 404) {
                //masukkan tgl lahir
                // this.setState({ flagData: false, msg: json.msg });
            } else {
                // ke login
                console.log("url now = " + window.location.origin)
                window.location.href = window.location.origin + "/login";
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
    }

    getCart = async () => {
        try {
            const json = await executeGet(GET_CART);
            console.log("cart = " + JSON.stringify(json));
            if (json.code == 200) {
                this.setState({ showMain: true, flagData: true, data: json.data, detail: json.detail });
            } else if (json.code == 404) {
                //masukkan tgl lahir
                this.setState({ flagData: false, msg: json.msg });
            } else if (json.code == 500 || json.code == 505) {
                window.location.href = window.location.origin + "/login";
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
    }

    deleteCart = async (kode_konten) => {
        console.log("deleteCart = " + kode_konten);
        try {
            let formData = new FormData();
            formData.append("kode_konten", kode_konten);

            const json = await executePost(DELETE_CART, formData);
            console.log("deleteCart = " + JSON.stringify(json));
            if (json.code == 200) {
                this.getCart();
                // open modal
                // this.props.navigate('/cart');
                // this.setState({showMain: true, data: json.data});
            } else if (json.code == 500 || json.code == 505) {
                window.location.href = window.location.origin + "/login";
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
    }

    addWishlist = async (kode_konten) => {
        this.setState({ isLoading: true });
        try {
            let formData = new FormData();
            formData.append("kode_konten", kode_konten);

            const json = await executePost(ADD_WISHLIST, formData);
            console.log("add_wishlist = " + JSON.stringify(json));
            if (json.code == 200) {
                // navigate to cart
                // this.props.navigate('/wishlist');
                this.deleteCart(kode_konten);
                this.setState({ isLoading: false, isModal: true, msg: "Berhasil Menambahkan ke Wishlist" });
            } else if (json.code == 500 || json.code == 505) {
                window.location.href = window.location.origin + "/login";
            } else {
                this.setState({ isLoading: false, isError: true, msg: json.msg });
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }

    }

    validateVoucher = async () => {
        console.log("validate = " + this.state.voucher);
        if (this.state.voucher != undefined) {
            this.setState({ isLoading: true });
            try {
                let formData = new FormData();
                formData.append("kode_voucher", this.state.voucher);

                const json = await executePost(VALIDASI_VOUCHER, formData);
                console.log("VALIDASI_VOUCHER = " + JSON.stringify(json));
                if (json.code == 200) {
                    // navigate to cart
                    // this.props.navigate('/wishlist');
                    this.setState({ data: json.data, detail: json.detail });
                    this.setState({ isLoading: false, isModal: true, msg: "Voucher berhasil di terapkan" });
                } else if (json.code == 404) {
                    this.setState({ isLoading: false, isError: true, msg: json.msg });
                } else if (json.code == 500 || json.code == 505) {
                    window.location.href = window.location.origin + "/login";
                }
            } catch (error) {
                //console.log(error);
                this.setState({ isError: true, errorMessage: "Network Error!" });
            }
        } else {

        }
    }

    getPaymentMethod = async () => {
        // console.log("getPaymentMethod");
        try {
            let formData = new FormData();
            formData.append("amount", this.state.detail.grand_total);

            const json = await executePost(PAYMENT_METHOD, formData);
            console.log("getPaymentMethod = " + JSON.stringify(json));
            if (json.code == 200) {
                // this.setState({ showModal: true, paymentMethod: json.data });
                this.setState({
                    showModal: true,
                    paymentMethod: json.data
                }, () => {
                    console.log("paymentMethod = " + this.state.paymentMethod);
                });
                // open modal
                // this.props.navigate('/cart');
                // this.setState({showMain: true, data: json.data});
            } else if (json.code == 500 || json.code == 505) {
                // window.location.href = window.location.origin +"/login";
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }

    }

    checkout = async () => {
        // console.log("diskon checkout" + JSON.stringify(this.state.detail.diskon));
        // console.log("sub_total checkout" + JSON.stringify(this.state.detail.sub_total));
        // console.log("grand_total checkout" + JSON.stringify(this.state.detail.grand_total));

        // console.log("checkout = " + JSON.stringify(dataPay.paymentMethod));
        // console.log("checkout fee = " + JSON.stringify(dataPay.totalFee));
        // console.log("data checkout" + JSON.stringify(this.state.data));
        this.setState({ isLoading: true });
        try {
            let dataPay = this.state.paymentMethod.paymentFee[this.state.selectedOption];
            let jsonBody = {
                "diskon": this.state.detail.diskon,
                "sub_total": this.state.detail.sub_total,
                "grand_total": this.state.detail.grand_total,
                "payment_code": dataPay.paymentMethod,
                "total_fee": dataPay.totalFee,
                "data": this.state.data
            };
            console.log("checkout = " + JSON.stringify(jsonBody));

            const json = await executePost(CHECKOUT, JSON.stringify(jsonBody));
            console.log("CHECKOUT = " + JSON.stringify(json));
            if (json.code == 200) {
                this.setState({ isLoading: false });
                this.setState({ showModal: false });
                window.location.href = json.data.paymentUrl;
                // open modal
                // this.props.navigate('/cart');
                // this.setState({showMain: true, data: json.data});
            } else if (json.code == 500 || json.code == 505) {
                window.location.href = window.location.origin + "/login";
            }
        } catch (error) {
            //console.log(error);
            this.setState({ isError: true, errorMessage: "Network Error!" });
        }
    }

    currentTableData = () => {
        const firstPageIndex = (this.state.currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return this.state.data.slice(firstPageIndex, lastPageIndex);
    };

    handleAllChecked = id => event => {
        let rows = this.state.data;
        console.log(event.target.checked);
        rows
            .filter(d => d.groupId === id)
            .forEach(row => {
                row.isChecked = event.target.checked;
            });
        this.setState({ data: rows });
    };

    handleCheckChieldElement = event => {
        let rows = this.state.data;
        rows.forEach(row => {
            if (`${row.groupId}-${row.id}` === event.target.value)
                row.isChecked = event.target.checked;
        });
        this.setState({ data: rows });
    };

    handleOnChange(e) {
        let val = e.target.value;
        console.log('selected option', val);
        this.setState({ selectedOption: e.target.value });
    }

    render() {
        return (
            <div>
                {
                    this.state.isLoading &&
                    <ModalLoading />
                }
                {
                    this.state.isModal &&
                    <ModalPopup
                        messages={this.state.msg}
                        press={() => { this.setState({ isModal: false }) }}
                        type="success"
                    />
                }
                {
                    this.state.isError &&
                    <ModalPopup
                        messages={this.state.msg}
                        press={() => { this.setState({ isError: false }) }}
                        type="error"
                    />
                }
                {
                    this.state.isModalConfirmation &&
                    <ModalConfirmation
                        messages={this.state.msg}
                        press={() => {
                            this.setState({ isModalConfirmation: false });
                            this.deleteCart(this.state.kode_konten);
                        }}
                        cancel={() => {
                            this.setState({ isModalConfirmation: false })
                        }}
                    />
                }
                {
                    this.state.flagData && this.state.showMain &&
                    <div>
                        {this.state.showModal ? (
                            <>
                                <div
                                    className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                                >
                                    <div className="relative w-[95%] lg:w-[40%] my-6 mx-auto">
                                        {/*content*/}
                                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                            {/*header*/}
                                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                                <div className="flex w-full">
                                                    <h3 className="text-xl font-semibold dark:text-black w-[50%]">
                                                        Pilih Metode Pembayaran
                                                    </h3>
                                                    <div className="w-[50%] flex justify-end items-end">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" onClick={() => this.setState({ showModal: false })}>
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                        </svg>
                                                    </div>
                                                </div>

                                            </div>
                                            {/*body*/}
                                            <div className="relative p-6 flex-auto">
                                                {/* payment gateway */}
                                                {this.state.paymentMethod.paymentFee.map((item, index) => {
                                                    return (
                                                        <div className="py-1">
                                                            { item.paymentMethod=="OV" || item.paymentMethod=="NQ" || item.paymentMethod=="BC" || item.paymentMethod=="BK" ?
                                                            (<div className="form-control">
                                                                <label className="label cursor-pointer">
                                                                    <div className="flex">
                                                                        <div className="w-[85px] h-[40px]">
                                                                            <img className='w-full' src={item.paymentImage} alt="" />
                                                                        </div>
                                                                        <div className="pl-4 flex items-center">
                                                                            <span className="label-text dark:text-black font-semibold">{item.paymentName}</span>
                                                                        </div>
                                                                    </div>
                                                                    <input
                                                                        type="radio"
                                                                        name="radio-payment"
                                                                        key={index}
                                                                        className="radio radio-accent checked:bg-greenWalang"
                                                                        value={index}
                                                                        onChange={(e) => this.handleOnChange(e)} />
                                                                </label>
                                                            </div>
                                                            ):null}

                                                        </div>
                                                    )

                                                    // return (
                                                    //     <RadioPaymentMethod
                                                    //         data={item}
                                                    //         key={index}
                                                    //     />
                                                    // )
                                                })}

                                                {/* <div className="form-control">
                                        <label className="label cursor-pointer">
                                            <span className="label-text">Red pill</span> 
                                            <input type="radio" name="radio-6" className="radio checked:bg-red-500" checked />
                                        </label>
                                        </div>
                                        <div className="form-control">
                                        <label className="label cursor-pointer">
                                            <span className="label-text">blue pill</span> 
                                            <input type="radio" name="radio-6" className="radio checked:bg-greenWalang" checked />
                                        </label>
                                        </div> */}
                                            </div>
                                            {/*footer*/}
                                            <div className="flex items-center p-6 border-t border-solid border-slate-200 rounded-b">
                                                <div className="w-6/12">
                                                    <div className="text-[14px] font-semibold text-black">Total Harga</div>
                                                    <div className="text-[16px] text-greenWalang font-semibold">Rp {this.state.detail.formatted_grand_total}</div>
                                                </div>
                                                <div className="flex w-6/12 justify-end">
                                                    <button onClick={this.checkout} type="button" className="w-[60%] bg-greenWalang font-medium rounded-full text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                                        <div className="text-[#ffffff]">
                                                            Lanjut
                                                </div>

                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                            </>
                        ) : null}

                        <div className="hidden lg:block">
                            <div className="pt-[78px] pb-2 px-8 xl:px-28  pb-2 px-28 dark:bg-white">
                                <div>
                                    {/* Home / Kategori */}
                                    <nav className="flex" aria-label="Breadcrumb">
                                        <ol className="inline-flex items-center space-x-1 lg:space-x-3">
                                            <li className="inline-flex items-center">
                                                <a href="/" className="inline-flex items-center text-sm font-medium text-greenWalang hover:text-greenWalang hover:opacity-30 dark:text-greenWalang dark:hover:text-greenWalang">
                                                    Home
                                                </a>
                                            </li>
                                            <li aria-current="page">
                                                <div className="flex items-center">
                                                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                                    <span className="ml-1 text-sm font-medium text-gray-400 lg:ml-2 dark:text-gray-500">Keranjang</span>
                                                </div>
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="py-3 px-8 xl:px-28 bg-greenWalang bg-opacity-30 text-2xl text-greenWalang dark:text-gray-900">
                                Keranjang
                        </div>
                            <div className="pt-8 px-28 dark:bg-white">
                                <div className="flex pb-[50px] space-x-7">
                                    <div className="w-8/12 h-[75%]">
                                        {this.currentTableData()
                                            .map((row) => {
                                                return (
                                                    <CheckboxCart
                                                        key={row.kode_konten}
                                                        handleCheckChieldElement={this.handleCheckChieldElement}
                                                        {...row}
                                                        value={row}
                                                        label={row.title}
                                                        deleteCart={() => { this.setState({ kode_konten: row.kode_konten, msg: 'Apakah anda yakin ingin delete buku ini?', isModalConfirmation: true }) }}
                                                        addWishlist={() => { this.addWishlist(row.kode_konten) }}
                                                    />
                                                );
                                            })}
                                        {/* {[{ id: 1, name: "Pilih Semua" }].map(item => (
                                        <div>
                                            <div className="flex">
                                                <div className="w-1/12 items-center flex">
                                                    <input
                                                        type="checkbox"
                                                        onChange={this.handleAllChecked(item.id)}
                                                        value="checkedall"
                                                    />{" "}
                                                </div>
                                                <div>
                                                    {item.name}
                                                </div>
                                            </div>
                                            <ul>
                                                {this.state.data
                                                    .map((row, index) => {
                                                        return (
                                                            <CheckboxCart
                                                                key={`${item.id}-${row.id}`}
                                                                handleCheckChieldElement={this.handleCheckChieldElement}
                                                                {...row}
                                                                value={`${item.id}-${row.id}`}
                                                                label={row.title}
                                                            />
                                                        );
                                                    })}
                                            </ul>
                                        </div>
                                    ))} */}
                                        <div className="flex justify-center">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={this.state.currentPage}
                                                totalCount={this.state.data.length}
                                                pageSize={PageSize}
                                                onPageChange={
                                                    page => this.setState({ currentPage: page })
                                                }
                                            />
                                        </div>

                                    </div>
                                    <div className="w-4/12">
                                        <div className="border rounded-2xl p-[30px] mb-[30px]">
                                            <form className="mb-6">
                                                <label for="voucher" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Masukkan Voucher Anda</label>
                                                <div className="flex space-x-2">
                                                    <div className="w-9/12">
                                                        <input type="text" id="voucherr" value={this.state.voucher} onChange={event => this.setState({ voucher: event.target.value })} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Makin hemat pake kode voucher" required />
                                                    </div>
                                                    <button onClick={this.validateVoucher} type="button" className="w-3/12 bg-greenWalang rounded-lg text-xs text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                                        <div className="text-[#ffffff]">
                                                            Terapkan
                                                        </div>
                                                    </button>
                                                </div>

                                            </form>
                                            <div className="text-[16px] dark:text-black font-semibold pb-[23px] ">Ringkasan Belanja</div>
                                            <div className="flex text-[14px] text-grayWalangDark dark:text-grayWalangDark pb-[5px]">
                                                <div className="w-6/12 flex">Total Harga ({this.state.data.length} barang)</div>
                                                <div className="w-6/12 justify-end text-right">Rp {this.state.detail.formatted_sub_total}</div>
                                            </div>
                                            <div className="flex text-[14px] text-grayWalangDark dark:text-grayWalangDark pb-[18px] border-b border-grayWalang">
                                                <div className="w-6/12 flex">Total Diskon</div>
                                                <div className="w-6/12 justify-end text-right">Rp {this.state.detail.formatted_diskon}</div>
                                            </div>
                                            <div className="flex pb-[25px] pt-[19px] font-semibold">
                                                <div className="w-6/12 text-[16px] flex">Total Harga</div>
                                                <div className="w-6/12 text-[16px] text-greenWalang justify-end text-right">Rp {this.state.detail.formatted_grand_total}</div>
                                            </div>
                                            {/* <form>
                                                <div className="mb-6">
                                                    <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Masukkan Email Penerima</label>
                                                    <input type="text" id="email" value={this.state.email} onChange={event => this.setState({ email: event.target.value })} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Email Penerima Buku Digital" required />
                                                </div>
                                            </form> */}
                                            <button onClick={this.getPaymentMethod} type="button" className="w-full bg-greenWalang font-medium rounded-full text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                                <div className="text-[#ffffff]">
                                                    Pilih Pembayaran
                                                </div>
                                            </button>

                                            {/* <label for="my-modal-3">open modal</label>

                                        <input type="checkbox" id="my-modal-3" className="modal-toggle" value="true" /> */}
                                            {/* <div className="modal">
                                            <div className="modal-box relative">
                                                <label for="my-modal-3" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                                                <h3 className="text-lg font-bold">Congratulations random Interner user!</h3>
                                                <p className="py-4">You've been selected for a chance to get one year of subscription to use Wikipedia for free!</p>
                                            </div>
                                        </div> */}
                                        </div>
                                    </div>

                                </div>


                            </div>

                        </div>

                        <div className="block lg:hidden">
                            <div className="pt-28 px-4 dark:bg-white">
                                <div className="pb-[50px]">
                                    <div className="w-full h-[75%]">
                                        {this.currentTableData()
                                            .map((row) => {
                                                return (
                                                    <CheckboxCart
                                                        key={row.kode_konten}
                                                        handleCheckChieldElement={this.handleCheckChieldElement}
                                                        {...row}
                                                        value={row}
                                                        label={row.title}
                                                        deleteCart={() => { this.deleteCart(row.kode_konten) }}
                                                    />
                                                );
                                            })}
                                        {/* {[{ id: 1, name: "Pilih Semua" }].map(item => (
                                        <div>
                                            <div className="flex">
                                                <div className="w-1/12 items-center flex">
                                                    <input
                                                        type="checkbox"
                                                        onChange={this.handleAllChecked(item.id)}
                                                        value="checkedall"
                                                    />{" "}
                                                </div>
                                                <div>
                                                    {item.name}
                                                </div>
                                            </div>
                                            <ul>
                                                {this.state.data
                                                    .map((row, index) => {
                                                        return (
                                                            <CheckboxCart
                                                                key={`${item.id}-${row.id}`}
                                                                handleCheckChieldElement={this.handleCheckChieldElement}
                                                                {...row}
                                                                value={`${item.id}-${row.id}`}
                                                                label={row.title}
                                                            />
                                                        );
                                                    })}
                                            </ul>
                                        </div>
                                    ))} */}
                                        <div className="flex justify-center">
                                            <Pagination
                                                className="pagination-bar"
                                                currentPage={this.state.currentPage}
                                                totalCount={this.state.data.length}
                                                pageSize={PageSize}
                                                onPageChange={
                                                    page => this.setState({ currentPage: page })
                                                }
                                            />
                                        </div>

                                    </div>
                                    <div className="w-full pt-4">
                                        <div className="border rounded-2xl p-[30px] mb-[30px]">
                                            <form>
                                                <div className="mb-6">
                                                    <label for="voucher" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Masukkan Voucher Anda</label>
                                                    <input type="text" id="voucherr" value={this.state.voucher} onChange={event => this.setState({ voucher: event.target.value })} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Makin hemat pake kode voucher" required />
                                                </div>
                                            </form>
                                            <div className="text-[16px] dark:text-black font-semibold pb-[23px] ">Ringkasan Belanja</div>
                                            <div className="flex text-[14px] text-grayWalangDark dark:text-grayWalangDark pb-[5px]">
                                                <div className="w-6/12 flex">Total Harga ({this.state.data.length} barang)</div>
                                                <div className="w-6/12 justify-end text-right">Rp {this.state.detail.formatted_sub_total}</div>
                                            </div>
                                            <div className="flex text-[14px] text-grayWalangDark dark:text-grayWalangDark pb-[18px] border-b border-grayWalang">
                                                <div className="w-6/12 flex">Total Diskon</div>
                                                <div className="w-6/12 justify-end text-right">Rp {this.state.detail.formatted_diskon}</div>
                                            </div>
                                            <div className="flex pb-[25px] pt-[19px] font-semibold">
                                                <div className="w-6/12 text-[16px] flex">Total Harga</div>
                                                <div className="w-6/12 text-[16px] text-greenWalang justify-end text-right">Rp {this.state.detail.formatted_grand_total}</div>
                                            </div>
                                            {/* <form>
                                                <div className="mb-6">
                                                    <label for="voucher" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-800">Masukkan Email Penerima</label>
                                                    <input type="text" id="voucherr" value={this.state.voucher} onChange={event => this.setState({ voucher: event.target.value })} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" placeholder="Email Penerima Buku Digital" required />
                                                </div>
                                            </form> */}
                                            <button onClick={this.getPaymentMethod} type="button" className="w-full bg-greenWalang font-medium rounded-full text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-[#3b5998]/55 justify-center mr-2 hover:shadow-[0_8px_25px_-8px_#8e8e8e]">
                                                <div className="text-[#ffffff]">
                                                    Pilih Pembayaran
                                            </div>

                                            </button>

                                            {/* <label for="my-modal-3">open modal</label>

                                        <input type="checkbox" id="my-modal-3" className="modal-toggle" value="true" /> */}
                                            {/* <div className="modal">
                                            <div className="modal-box relative">
                                                <label for="my-modal-3" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                                                <h3 className="text-lg font-bold">Congratulations random Interner user!</h3>
                                                <p className="py-4">You've been selected for a chance to get one year of subscription to use Wikipedia for free!</p>
                                            </div>
                                        </div> */}
                                        </div>
                                    </div>

                                </div>


                            </div>

                        </div>

                    </div>

                }

                {
                    !this.state.flagData &&
                    <div>
                        <div className="hidden lg:block">
                            <div className="pt-24 px-36 dark:bg-white">
                                <div className="py-7">
                                    {/* Home / Kategori */}
                                    <nav className="flex" aria-label="Breadcrumb">
                                        <ol className="inline-flex items-center space-x-1 lg:space-x-3">
                                            <li className="inline-flex items-center">
                                                <a href="/" className="inline-flex items-center text-sm font-medium text-greenWalang hover:text-greenWalang hover:opacity-30 dark:text-greenWalang dark:hover:text-greenWalang">
                                                    Home
                                            </a>
                                            </li>
                                            <li aria-current="page">
                                                <div className="flex items-center">
                                                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                                    <span className="ml-1 text-sm font-medium text-gray-400 lg:ml-2 dark:text-gray-500">Keranjang</span>
                                                </div>
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div className="py-4 px-36 bg-greenWalang bg-opacity-30 text-2xl text-greenWalang">
                                Keranjang
                        </div>
                            <div className="pt-8 px-36 dark:bg-white">
                                <div className="flex pb-[50px] space-x-7">
                                    <div className="w-8/12 h-[75%]">
                                        <div className="text-4xl font-semibold dark:text-black pb-6">{this.state.msg}</div>
                                    </div>
                                    <div className="w-4/12">
                                        <div className="border rounded-2xl p-[30px] mb-[30px]">
                                            <div className="text-[16px] dark:text-black font-semibold pb-[23px] ">Ringkasan Belanja</div>
                                            <div className="flex text-[14px] text-grayWalangDark dark:text-grayWalangDark pb-[18px] border-b border-grayWalang">
                                                <div className="w-6/12 flex">Total Harga</div>
                                                <div className="w-6/12 justify-end text-right">Rp 0</div>
                                            </div>
                                            <div className="flex pb-[25px] pt-[19px] font-semibold">
                                                <div className="w-6/12 text-[16px] flex">Total Harga</div>
                                                <div className="w-6/12 text-[16px] text-greenWalang justify-end text-right">Rp 0</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>

                        </div>

                        <div className="block lg:hidden">
                            <div className="pt-32 px-4 dark:bg-white">
                                <div className="pb-[50px] space-x-7">
                                    <div className="w-full h-[75%]">
                                        <div className="text-4xl font-semibold dark:text-black pb-6">{this.state.msg}</div>
                                    </div>
                                    {/* <div className="w-4/12">
                                    <div className="border rounded-2xl p-[30px] mb-[30px]">
                                        <div className="text-[16px] dark:text-black font-semibold pb-[23px] ">Ringkasan Belanja</div>
                                        <div className="flex text-[14px] text-grayWalangDark dark:text-grayWalangDark pb-[18px] border-b border-grayWalang">
                                            <div className="w-6/12 flex">Total Harga</div>
                                            <div className="w-6/12 justify-end text-right">Rp 0</div>
                                        </div>
                                        <div className="flex pb-[25px] pt-[19px] font-semibold">
                                            <div className="w-6/12 text-[16px] flex">Total Harga</div>
                                            <div className="w-6/12 text-[16px] text-greenWalang justify-end text-right">Rp 0</div>
                                        </div>
                                    </div>
                                </div> */}

                                </div>


                            </div>

                        </div>
                    </div>

                }

            </div>
        );

    }
}

export default Cart;
